import { CFormSwitch } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { getPeriodCurrentMonth, isPeriodCurrentMonth } from 'helpers/helpers';
import { useEffect } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useFilterPanelContext } from '../filter-panel-context';

const FieldCurrentMonth: FC = () => {
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE_ORDER);
  const { filters: { isCurrentMonth, dateFrom, dateTo }, setOrderFilters } = useFilterPanelContext();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;

    setOrderFilters({
      isToday: false,
      isCurrentWeek: false,
      isCurrentMonth: checked,
      ...getPeriodCurrentMonth(),
    });
  };

  useEffect(() => {
    if(isCurrentMonth && !isPeriodCurrentMonth({ dateFrom, dateTo })) {
      setOrderFilters({ isCurrentMonth: false });
    }
  }, [isCurrentMonth, dateFrom, dateTo]);

  return (
    <CFormSwitch
      size="lg"
      label={ t('filters.currentMonth') }
      checked={ isCurrentMonth }
      onChange={ onChange }
    />
  );
};

export { FieldCurrentMonth };
