import cn from 'classnames';
import { AppRoute, AppTranslationKey, HelpRoute } from 'common/enums/enums';
import { UserTariff } from 'common/types/types';
import { getFormattedDate } from 'helpers/helpers';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

const getActiveClass = (tariff?: UserTariff): string => cn({
  [styles.activeValue]: tariff && tariff.isActive,
  [styles.noActiveValue]: tariff && !tariff.isActive,
});

const getDate = (date: string | null, defaultValue: string): string => (
  date ? getFormattedDate(date, 'dd.MM.yyyy') : defaultValue
);

type Props = {
  tariff?: UserTariff,
  tariffNext?: UserTariff | null,
};

const UserTariffBlock: FC<Props> = ({ tariff, tariffNext }) => {
  const { t } = useTranslation(AppTranslationKey.PROFILE);

  const currentStatus = tariff ? ( tariff.isActive ? t('activeStatus') : t('endStatus') ) : t('noData');
  const nextStatus = tariffNext ? t('paidStatus') : t('noData');

  return (
    <div className={ styles.profileDataBlock }>
      <div className={ styles.profileDataRow }>
        <span className={ styles.profileDataLabel }>{ t('tariffName') }</span>
        <div className={ cn(styles.profileDataValue, styles.profileDataValueMulti) }>
          { tariff && !!tariff.tariffId && <span>{ tariff.name }</span> }
          { tariffNext && <span>({ tariffNext.name })</span> }
        </div>
      </div>
      <div className={ styles.profileDataRow }>
        <span className={ styles.profileDataLabel }>{ t('tariffStatus') }</span>
        <div className={ cn(styles.profileDataValue, styles.profileDataValueMulti) }>
          { tariff && !!tariff.tariffId && <span className={getActiveClass(tariff)}>{currentStatus}</span> }
          { tariffNext && <span className={ styles.activeValue }>({ nextStatus })</span> }
        </div>
      </div>
      <div className={ styles.profileDataRow }>
        <span className={ styles.profileDataLabel }>{ t('tariffStart') }</span>
        <div className={ cn(styles.profileDataValue, styles.profileDataValueMulti) }>
          { tariff && !!tariff.tariffId && <span>{ getDate(tariff.dateStart, t('noData')) }</span> }
          { tariffNext && <span>({ getDate(tariffNext.dateStart, t('noData')) })</span> }
        </div>
      </div>
      <div className={ styles.profileDataRow }>
        <span className={ styles.profileDataLabel }>{ t('tariffEnd') }</span>
        <div className={ cn(styles.profileDataValue, styles.profileDataValueMulti) }>
          { tariff && !!tariff.tariffId && <span>{ getDate(tariff.dateFinish, t('noData')) }</span> }
          { tariffNext && <span>({ getDate(tariffNext.dateFinish, t('noData')) })</span> }
        </div>
      </div>
      <div className={ styles.profileDataRow }>
        <Link to={ `${AppRoute.HELP}${HelpRoute.ROOT}${HelpRoute.TARIFFS}` }>{ t('selectTariff') }</Link>
      </div>
    </div>
  );
};

export { UserTariffBlock };
