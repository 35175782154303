import { Filters, FiltersPayload } from 'common/types/types';
import { FILTER_DATE_FORMAT, FILTER_DATE_TIME_FORMAT } from 'constants/date-formats';
import addDays from 'date-fns/addDays';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import isEqual from 'lodash/isEqual';

const getPayloadByOrderFilters = (carServiceId: number, filters: Filters): FiltersPayload => {
  let dateFrom = filters.dateFrom ? `${ filters.dateFrom } 00:00:00` : '';
  let dateTo = filters.dateTo ? format(addDays(new Date(filters.dateTo), 1), FILTER_DATE_TIME_FORMAT) : '';

  if (filters.isToday) {
    dateFrom = format(new Date(), FILTER_DATE_TIME_FORMAT);
    dateTo = format(addDays(new Date(), 1), FILTER_DATE_TIME_FORMAT);
  } else if (filters.isCurrentWeek) {
    dateFrom = format(startOfWeek(new Date(), { weekStartsOn: 1 }), FILTER_DATE_TIME_FORMAT);
    dateTo = format(addDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 1), FILTER_DATE_TIME_FORMAT);
  } else if (filters.isCurrentMonth) {
    dateFrom = format(startOfMonth(new Date()), FILTER_DATE_TIME_FORMAT);
    dateTo = format(addDays(endOfMonth(new Date()), 1), FILTER_DATE_TIME_FORMAT);
  }

  const selectedStatuses = filters.statuses.filter((item) => item.selected);
  const statuses = selectedStatuses.map((item) => +item.value);

  return {
    carServiceId: carServiceId,
    dateFrom,
    dateTo,
    statuses,
  };
};

const getPeriodToday = (): { dateFrom: string, dateTo: string } => ({
  dateFrom: format(new Date(), FILTER_DATE_FORMAT),
  dateTo: format(new Date(), FILTER_DATE_FORMAT),
});

const getPeriodCurrentWeek = (): { dateFrom: string, dateTo: string } => ({
  dateFrom: format(startOfWeek(new Date(), { weekStartsOn: 1 }), FILTER_DATE_FORMAT),
  dateTo: format(endOfWeek(new Date(), { weekStartsOn: 1 }), FILTER_DATE_FORMAT),
});

const getPeriodCurrentMonth = (): { dateFrom: string, dateTo: string } => ({
  dateFrom: format(startOfMonth(new Date()), FILTER_DATE_FORMAT),
  dateTo: format(endOfMonth(new Date()), FILTER_DATE_FORMAT),
});

const isPeriodToday = (period: { dateFrom: string, dateTo: string }): boolean =>
  isEqual(period, getPeriodToday());

const isPeriodCurrentWeek = (period: { dateFrom: string, dateTo: string }): boolean =>
  isEqual(period, getPeriodCurrentWeek());

const isPeriodCurrentMonth = (period: { dateFrom: string, dateTo: string }): boolean =>
  isEqual(period, getPeriodCurrentMonth());

export {
  getPayloadByOrderFilters,
  getPeriodCurrentMonth,
  getPeriodCurrentWeek,
  getPeriodToday,
  isPeriodCurrentMonth,
  isPeriodCurrentWeek,
  isPeriodToday,
};
