import { FC } from 'react';

import { UserCard } from '../user-card/user-card';

const Profile: FC = () => {
  return (
    <UserCard/>
  );
};

export { Profile };
