import { LocalStorageKey } from 'common/enums/enums';
import { CarServiceDto } from 'common/types/types';
import { useAppDispatch, useCallback, useEffect, useSearchParams, useState } from 'hooks/hooks';
import { storage } from 'services/services';
import { carServicesActions } from 'store/actions';

type UseCarServiceResult = {
  activeCarService: CarServiceDto | null,
  handleActiveCarServiceChange: (carService: CarServiceDto | null) => void,
  getActiveCarServiceById: (userCarServices: CarServiceDto[], id: number) => CarServiceDto | null,
};

type UseCarServiceArg = {
  userId: number;
};

const useCarServices = ({ userId }: UseCarServiceArg): UseCarServiceResult => {
  const dispatch = useAppDispatch();
  const [, setTabId] = useSearchParams();
  const [activeCarService, setActiveCarService] = useState<CarServiceDto | null>(null);

  useEffect( () => {
    dispatch(carServicesActions.getAllCarServiceByUserId({ userId }));
  },[dispatch, userId]);

  const getActiveCarServiceById = useCallback(
    (userCarServices: CarServiceDto[], id: number): CarServiceDto | null =>
      userCarServices.find((item) => item?.id === id) ?? null, []);

  const handleActiveCarServiceChange = useCallback((carService: CarServiceDto | null): void => {
    carService?.id && storage.setItem(LocalStorageKey.ACTIVE_CAR_SERVICE_TAB, `${carService?.id}`);
    carService && setTabId({ tabId: carService?.id.toString() ?? '' });
    setActiveCarService(carService);
  }, [setTabId]);

  return { activeCarService, handleActiveCarServiceChange, getActiveCarServiceById };

};

export { useCarServices };
