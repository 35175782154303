import { CBadge } from '@coreui/react-pro';
import React, { FC } from 'react';

type Props = {
  color: string,
  textColor?: string,
  title: string,
  size?: number,
};

const StatusBadge: FC<Props> = (
  {
    title,
    color,
    textColor = 'white',
    size = 130,
  },
) => {

  return (
    <CBadge
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: color,
        color: textColor,
        justifyContent: 'center',
        height: 24,
        wordWrap: 'break-word',
        fontSize: '0.8em',
        width: size,
      }}
    >
      <div style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}>
        {title}
      </div>
    </CBadge>
  );
};

export { StatusBadge };
