import { useState } from 'react';

type UseToggleTableDetails = {
  details: number[],
  closeDetails: (id: number) => void,
  toggleDetails: (id: number) => void,
};

const useToggleTableDetails = (): UseToggleTableDetails => {
  const [ details, setDetails ] = useState<number[]>([]);

  const toggleDetails = (id: number): void => {
    const position = details.indexOf(id);
    let newDetails = details.slice();

    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [ ...details, id ];
    }
    setDetails(newDetails);
  };

  const closeDetails = (id: number): void =>  {
    setDetails(details.splice(details.indexOf(id), 1));
  };

  return { details, toggleDetails, closeDetails };
};

export { useToggleTableDetails };
