import { ApiPath, CarWorkApiPath, ContentType, HttpMethod } from 'common/enums/enums';
import {
  CarWorkFav,
  CarWorkGetAllResponse,
  CarWorkGetByCarServiceId,
  CarWorkUpdateFavRequest,
} from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class CarWorkApi {
  #http: Http;

  readonly #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public getFavoritesCarWorkByCarServiceId(
    { carServiceId }: CarWorkGetByCarServiceId,
  ): Promise<CarWorkFav[]> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CAR_WORK}${
        CarWorkApiPath.BY_CAR_SERVICE}/${carServiceId}${CarWorkApiPath.FAVORITES}`,
    );

  }

  public updateFavoritesCarWork(payload: CarWorkUpdateFavRequest): Promise<CarWorkFav> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CAR_WORK}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public getAllCarWorkByCarService({ carServiceId }: CarWorkGetByCarServiceId): Promise<CarWorkGetAllResponse[]> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CAR_WORK}${CarWorkApiPath.BY_CAR_SERVICE}/${carServiceId}`,
    );
  }

  public updFavListForCarService(
    payload: number[],
    carServiceId: number,
  ): Promise<void> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CAR_WORK}${
        CarWorkApiPath.BY_CAR_SERVICE}/${carServiceId}${CarWorkApiPath.FAVORITES}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify({ workIds: payload }),
      },
    );
  }
}

export { CarWorkApi };
