import { CCol, CForm, CLoadingButton, CRow } from '@coreui/react-pro';
import {
  AppTranslationKey,
  RecoveryPasswordTranslationKey,
} from 'common/enums/enums';
import { SetPasswordRequest } from 'common/types/types';
import { DEFAULT_SET_PASS_PAYLOAD } from 'components/auth/common/common';
import { PasswordInput } from 'components/password-input/password-input';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit: (payload: SetPasswordRequest) => void;
  isLoading:boolean;
};

const ChangePasswordForm:FC<Props> = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation(AppTranslationKey.RECOVERY_PASSWORD);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SetPasswordRequest>({
    defaultValues: DEFAULT_SET_PASS_PAYLOAD,
  });

  return (
    <CForm
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <p style={{ fontSize: 16, paddingBottom: 5 }}>
        {t(RecoveryPasswordTranslationKey.CHANGE_PASSWORD_SUBTITLE_TEXT)}
      </p>
      <PasswordInput
        {...register('password',
          {
            required:
              t(RecoveryPasswordTranslationKey.NEW_PASSWORD_ERROR_TEXT) ?? 'Password cannot be empty',
          })
        }
        invalid={Boolean(errors.password)}
        feedbackInvalid={errors.password?.message}
        placeholder={t(RecoveryPasswordTranslationKey.CHANGE_PASSWORD_PLACEHOLDER) ?? 'Password'}
      />
      <CRow className="mt-4">
        <CCol className="text-center">
          <CLoadingButton
            color="primary"
            type="submit"
            className="px-4"
            loading={isLoading}
          >
            {t(RecoveryPasswordTranslationKey.CHANGE_PASSWORD_BUTTON_TEXT)}
          </CLoadingButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

export { ChangePasswordForm };
