import { RecoveryPasswordRequest, SetPasswordRequest } from 'common/types/types';

const DEFAULT_SET_PASS_PAYLOAD: SetPasswordRequest = {
  uuid: '',
  password: '',
};

const DEFAULT_REQUEST_RECOVERY_FORM_PAYLOAD: RecoveryPasswordRequest = {
  email: '',
};

export { DEFAULT_REQUEST_RECOVERY_FORM_PAYLOAD, DEFAULT_SET_PASS_PAYLOAD };
