import React, { FC } from 'react';

import styles from './styles.module.scss';

interface IProps {
  title: string,
  value: string | number,
}

const CarTableField: FC<IProps> = ({ title, value }) => (
  <div className={styles.tableField}>
    <div className={styles.tableFieldLabel}>
      { title }
    </div>
    <div className={styles.tableFieldValue}>
      { value }
    </div>
  </div>
);

export { CarTableField };
