import { LocalStorageKey } from 'common/enums/enums';
import { DictionaryDto } from 'common/types/types';
import { i18next, LANG } from 'i18n';
import { storage } from 'services/services';

const dictionariesNames = new Set([
  'carBrands',
  'carColors',
  'bodyTypes',
  'counterpartyStatuses',
  'stockStatuses',
  'tariffs',
  'userSettings',
  'orderStatuses',
  'requestStatuses',
  'carServiceStatuses',
]);

const DICTIONARY_STORAGE = {
  'uk': LocalStorageKey.DICTIONARIES_UK,
  'en': LocalStorageKey.DICTIONARIES_ENG,
  'ru': LocalStorageKey.DICTIONARIES_RU,
};

const isValidateDictionaries = (dictionaries: DictionaryDto): boolean => {
  const existsDictionariesNames = new Set(Object.keys(dictionaries));

  let isValidate = existsDictionariesNames.size === dictionariesNames.size;
  isValidate = isValidate && [...dictionariesNames].every((item) => existsDictionariesNames.has(item));

  return isValidate;
};

const loadDictionariesFromStorage = (): DictionaryDto | null => {
  const lang = i18next.language as LANG;
  const dictionariesStr = storage.getItem(DICTIONARY_STORAGE[lang]) || '{}';
  const dictionaries: DictionaryDto = JSON.parse(dictionariesStr);

  return isValidateDictionaries(dictionaries) ? dictionaries : null;
};

const uploadDictionariesToStorage = (dictionaries: DictionaryDto): void => {
  const lang = i18next.language as LANG;
  const dictionariesStr = JSON.stringify(dictionaries);

  storage.setItem(DICTIONARY_STORAGE[lang], dictionariesStr);
};

export { isValidateDictionaries, loadDictionariesFromStorage, uploadDictionariesToStorage };
