import { CCol, CFormFeedback, CFormLabel, CRow } from '@coreui/react-pro';
import cn from 'classnames';
import { AppTranslationKey, SelectComponentKey } from 'common/enums/enums';
import { Brand, CarModalFormData } from 'common/types/types';
import { SelectWithLocalSearch } from 'components/common/select';
import React, { FC, ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control<CarModalFormData>,
  brands: Brand[],
  disabled?: boolean,
};

const BrandField: FC<Props> = ({ disabled = false, brands , control }) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);
  const { t: selectT } = useTranslation(AppTranslationKey.SELECT_COMPONENT);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="brand" className="col-sm-3 col-form-label">
        {t('brand')}
      </CFormLabel>
      <CCol sm={9}>
        <Controller
          name="brand"
          render={({ field, formState: { errors } }): ReactElement => (
            <div>
              <SelectWithLocalSearch<Brand>
                id="brand"
                data={ brands }
                value={ field.value }
                onChange={ (value): void => field.onChange(value) }
                getOptionLabel={ (option): string => option.name }
                getOptionValue={ (option): string => option.id + '' }
                otherOption={ { id: -1, name: selectT(SelectComponentKey.OTHER_OPTION_NAME) } }
                className={ cn( { 'is-invalid': Boolean(errors.brand) }) }
                isError={ Boolean(errors.brand) }
                isDisabled={ disabled }
              />
              <CFormFeedback invalid={ Boolean(errors.brand) }>
                {errors.brand?.message || ''}
              </CFormFeedback>
            </div>
          )}
          control={control}
          // rules={ {
          //   required: t('requiredFormField') ?? 'This field is required',
          // } }
        />
      </CCol>
    </CRow>
  );
};

export { BrandField };
