import { CarWorkFav, CarWorkFavTableRow } from 'common/types/types';

const getFavCarWorkData = (carWork: CarWorkFav[]): CarWorkFavTableRow[] => {

  return carWork.map<CarWorkFavTableRow>((item) => (
    {
      carServiceId: item.carServiceId,
      serviceWorkId: item.serviceWorkId,
      carWorkName: item.serviceWorkName,
      carWorkType: item.serviceWorkTypeName,
      time: item.requiredTimeMinutes,
      cost: item.serviceWorkCost ?? 0,
      info: item.serviceWorkInfo ?? '-',
      serviceWorkTypeId: item.serviceWorkTypeId,
    }
  ));
};

export { getFavCarWorkData };
