import { AppTranslationKey, InfoVinTranslationKey } from 'common/enums/enums';
import { InfoSearchDataDto } from 'common/types/types';
import { i18next } from 'i18n';

interface IConfigItem {
  title: string,
  value: () => string | number,
}

interface IConfig {
  items: IConfigItem[],
}

const configTable = (data:  InfoSearchDataDto): IConfig[] => [
  {
    items: [
      {
        title: i18next.t(`${AppTranslationKey.INFO_VIN}:${InfoVinTranslationKey.TABLE_FIELD_CAR}`) as string,
        value: (): string => {
          if (!data.brand && !data.model) {
            return '...';
          }

          if (!data.brand) {
            return data.model;
          }

          if (data.brand.includes(data.model)) {
            return data.brand;
          }

          return `${data.brand} ${data.model}`;
        },
      },
      {
        title: i18next.t(`${AppTranslationKey.INFO_VIN}:${InfoVinTranslationKey.TABLE_FIELD_BODY}`) as string,
        value: (): string => {
          if (!data.kind && !data.body) {
            return '...';
          }

          if (!data.kind) {
            return data.body;
          }

          if (!data.body) {
            return data.kind;
          }

          return `${data.kind}, ${data.body}`;
        },
      },
      {
        title: i18next.t(`${AppTranslationKey.INFO_VIN}:${InfoVinTranslationKey.TABLE_FIELD_COLOR}`) as string,
        value: (): string => data.color || '...',
      },
      {
        title: i18next.t(`${AppTranslationKey.INFO_VIN}:${InfoVinTranslationKey.TABLE_FIELD_YEAR}`) as string,
        value: (): string | number => data.makeYear || '...',
      },
      {
        title: i18next.t(`${AppTranslationKey.INFO_VIN}:${InfoVinTranslationKey.TABLE_FIELD_VIN}`) as string,
        value: (): string => data.vin || '...',
      },
    ],
  },
  {
    items:  [
      {
        title: i18next.t(`${AppTranslationKey.INFO_VIN}:${InfoVinTranslationKey.TABLE_FIELD_FUEL}`) as string,
        value: (): string => data.fuel || '...',
      },
      {
        title: i18next.t(`${AppTranslationKey.INFO_VIN}:${InfoVinTranslationKey.TABLE_FIELD_REG_NUM}`) as string,
        value: (): string => data.nRegNew || '...',
      },
      {
        title: i18next.t(`${AppTranslationKey.INFO_VIN}:${InfoVinTranslationKey.TABLE_FIELD_CAPACITY}`) as string,
        value: (): string => data.capacity ? `${data.capacity} см³` : '...',
      },
      {
        title: i18next.t(`${AppTranslationKey.INFO_VIN}:${InfoVinTranslationKey.TABLE_FIELD_OWN_WEIGHT}`) as string,
        value: (): string => data.ownWeight ? `${data.ownWeight} кг` : '...',
      },
      {
        title: i18next.t(`${AppTranslationKey.INFO_VIN}:${InfoVinTranslationKey.TABLE_FIELD_TOTAL_WEIGHT}`) as string,
        value: (): string => data.totalWeight ? `${data.totalWeight} кг` : '...',
      },
    ],
  },
];

export { configTable };
