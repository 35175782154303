import background from 'assets/img/auth-bg.jpg';
import { AppRoute, PersonalRoute } from 'common/enums/enums';
import { UserSignInRequestDto, UserSignUpRequestDto } from 'common/types/types';
import { ExternalLangSelector } from 'components/common/common';
import { toBase64 } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useEffect, useLocation, useNavigate, useStatistics } from 'hooks/hooks';
import { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { authActions } from 'store/actions';

import { RecoveryPassword, SignInForm, SignUpForm } from './components/components';

const Auth: FC = () => {

  const location = useLocation();
  const fromPage = location?.state?.from?.pathname
    || `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.DASHBOARDS}`;
  const { currentUser }  = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const hasUser = Boolean(currentUser);
  const { sendStat } = useStatistics();

  useEffect(() => {
    sendStat();
  }, [ location.pathname, location.search, sendStat ]);

  const handleSignInSubmit = (payload: UserSignInRequestDto): void => {
    const credential: UserSignInRequestDto = {
      username: toBase64(payload.username),
      password: toBase64(payload.password),
    };

    dispatch(authActions.signIn(credential));
  };

  const handleSignUpSubmit = ( { locale, password, username, name }: UserSignUpRequestDto): void => {
    dispatch(authActions.signUp({ username, name, password, locale }))
      .unwrap()
      .then(() => navigation((AppRoute.SIGN_IN)));
  };

  const getScreen = (screen: string): ReactElement | null => {
    switch (screen) {

    case AppRoute.SIGN_UP: {
      return <SignUpForm onSubmit={handleSignUpSubmit}/>;
    }

    case AppRoute.SIGN_IN: {
      return <SignInForm onSubmit={handleSignInSubmit}/>;
    }

    case AppRoute.RECOVERY_PASSWORD: {
      return <RecoveryPassword />;
    }
    }

    return <SignInForm onSubmit={handleSignInSubmit} />;
  };

  if(hasUser) {
    return <Navigate to={fromPage} />;
  }

  return <div style={{
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}>
    <div style={{ position: 'absolute', top: 0, right: 0 }}>
      <ExternalLangSelector />
    </div>
    {getScreen(location.pathname)}
  </div>;
};

export { Auth };
