import { CCol, CFormTextarea, CRow } from '@coreui/react-pro';
import { FeedBackModalKey } from 'common/enums/enums';
import { AppTranslationKey } from 'common/enums/translation-key/app-translation-key.enum';
import { FeedBackSendRequestType } from 'common/types/types';
import { useAppSelector, useEffect, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<FeedBackSendRequestType>,
  titleId?: number,
  disabled?: boolean,
  setValue: UseFormSetValue<FeedBackSendRequestType>,
  bodyValue: string,
};

const BodyField: FC<Props> = ({
  disabled = false,
  titleId,
  register,
  setValue ,
  bodyValue,
}) => {
  const { t } = useTranslation(AppTranslationKey.FEED_BACK_MODAL);
  const themes = useAppSelector(({ feedBack }) => feedBack.themes);
  const customPlaceholder = themes.find((item) => item.themeId === titleId)?.placeholder;
  const placeholder =  customPlaceholder || t(FeedBackModalKey.DEFAULT_PLACEHOLDER) as string;
  const [lastPrefix, setLastPrefix] = useState('');

  useEffect(() => {
    if (customPlaceholder) {
      setLastPrefix(customPlaceholder);
      setValue('body', `${ customPlaceholder } ${ bodyValue }`);
    } else if(bodyValue.startsWith(lastPrefix)) {
      setValue('body', bodyValue.replace(`${ lastPrefix } `, ''));
    }
  }, [titleId]);

  return (
    <>
      <CRow className="mb-3">
        <CCol sm={12}>
          <CFormTextarea
            disabled={disabled}
            { ...register('body') }
            id="body"
            required={true}
            rows={5}
            placeholder={ placeholder }
          />
        </CCol>
      </CRow>
    </>
  );
};

export { BodyField };
