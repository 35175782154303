import { CCol, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { SparePartForm, SupplierDto } from 'common/types/types';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<SparePartForm>,
  errors: FieldErrors<SparePartForm>,
  disabled: boolean,
  suppliers: SupplierDto[],
};

const SupplierField: FC<Props> = ({ register, errors, disabled, suppliers }) => {
  const { t } = useTranslation(AppTranslationKey.SPARE_PARTS);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="counterpartyId" className="col-sm-3 col-form-label">
        { t('supplier') }
      </CFormLabel>
      <CCol sm={9}>
        <CFormSelect
          disabled={disabled}
          { ...register('counterpartyId',
            {
              required: t('requiredFormField') ?? 'This field is required',
              validate: (value) => value > -1 || t('requiredFormField') as string,
            },
          ) }
          id="counterpartyId"
          invalid={Boolean(errors.counterpartyId)}
          feedbackInvalid={errors.counterpartyId?.message}
        >
          <option key={-1} value={-1}>{t('selectSupplier')}</option>
          {
            suppliers.map((supplier) => (
              <option key={supplier.counterpartyId} value={supplier.counterpartyId}>
                {supplier.counterpartyName}
              </option>
            ))
          }
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export { SupplierField };
