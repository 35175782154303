import cn from 'classnames';
import { AppTranslationKey } from 'common/enums/enums';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface IProps {
  label: string,
  items: number[],
  isSub?: boolean,
  accuracy?: number,
}

const TotalField: FC<IProps> = ({ label, items, isSub = false, accuracy = 4 }) => {
  const { t } = useTranslation([AppTranslationKey.COMMON]);
  const totalSum = items.reduce((acc, item) => {
    return acc + (isNaN(item) ? 0 : item);
  }, 0);
  const totalSumStr = totalSum.toFixed(accuracy)
    .replace(/0+$/, '')
    .replace(/\.+$/, '');

  return (
    <div className={ cn(styles.totalField, { [styles.subField]: isSub }) }>
      <span className={ styles.totalField_label }>{ `${label}:` }&nbsp;</span>
      <span className={ styles.totalField_value }>{ `${totalSumStr} ${t('uah')}` }</span>
    </div>
  );
};

export { TotalField };
