import { ApiPath, ContentType, EmployeesApiPath, HttpMethod } from 'common/enums/enums';
import { EmployeeCreateDto, EmployeeItem, EmployeeUpdateDto } from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class EmployeesApi {

  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public getEmployeesByCarServiceId({ carServiceId }:{ carServiceId:number }):Promise<EmployeeItem[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.EMPLOYEES}${EmployeesApiPath.CAR_SERVICE}/${carServiceId}`,
    );
  }

  public getEmployeesById({ employeeId }:{ employeeId:number }):Promise<EmployeeItem> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.EMPLOYEES}/${employeeId}`,
    );
  }

  public createEmployee(payload: EmployeeCreateDto): Promise<EmployeeItem> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.EMPLOYEES}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public updateEmployee(payload: EmployeeUpdateDto): Promise<EmployeeItem> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.EMPLOYEES}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }
}

export { EmployeesApi };
