import { LocalStorageKey } from 'common/enums/enums';
import { useState } from 'hooks/hooks';
import { storage } from 'services/services';

type UsePaginationResult = {
  paginationState: PaginationStateType;
  handlePageChange: (page: number) => void;
  handleSizeChange: (size: number) => void;
};

type PaginationStateType = {
  [tabKey: number]: {
    perPage: number,
    activePage: number,
  }
};

const usePersistentPagination = (tabKey: number): UsePaginationResult => {
  // const paginationState: PaginationStateType =
  //   JSON.parse(storage.getItem(StorageKey.PERSIST_PAGINATION_STATE) ?? '{}');
  const [paginationState, setPaginationState]
    = useState<PaginationStateType>(JSON.parse(storage.getItem(LocalStorageKey.PERSIST_PAGINATION_STATE) ?? '{}'));

  const handlePageChange = (value: number): void => {
    const v: PaginationStateType =
      {
        ...paginationState,
        [tabKey]: {
          ...JSON.parse(storage.getItem(LocalStorageKey.PERSIST_PAGINATION_STATE) ?? '{}')[tabKey],
          activePage: value,
        },
      };
    storage.setItem(LocalStorageKey.PERSIST_PAGINATION_STATE, JSON.stringify(v));
    setPaginationState(v);
  };

  const handleSizeChange = (value: number): void => {
    const v: PaginationStateType =
      {
        ...paginationState,
        [tabKey]: {
          ...JSON.parse(storage.getItem(LocalStorageKey.PERSIST_PAGINATION_STATE) ?? '{}')[tabKey],
          perPage: value,
        },
      };
    storage.setItem(LocalStorageKey.PERSIST_PAGINATION_STATE, JSON.stringify(v));
    setPaginationState(v);
  };

  return { paginationState, handlePageChange, handleSizeChange };
};

export { usePersistentPagination };
