import { CButton, CCol, CFormInput, CRow } from '@coreui/react-pro';
import { AppTranslationKey, WorkOrderModalTranslationKey, WorkOrderTranslationKey } from 'common/enums/enums';
import { WorkOrderFormData } from 'common/types/types';
import { ModalLabel } from 'components/modals/components/components';
import { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  label: string,
  onAction: () => void,
  register: UseFormRegister<WorkOrderFormData>,
  disabled: boolean,
  errors: FieldErrors<WorkOrderFormData>,
  selectBtnCaption: string,
  requiredMsg: string,
};
const WorkOrderRequestRow: FC<Props> = (
  {
    label,
    requiredMsg,
    selectBtnCaption,
    onAction,
    register,
    errors,
    disabled }) => {

  const { t } = useTranslation([AppTranslationKey.WORK_ORDER]);

  return(
    <CRow className="mb-3">
      <ModalLabel label={label} />
      <CCol sm={8}>
        <CFormInput
          { ...register('order',
            {
              required: requiredMsg,
            }) }
          placeholder={t(`${WorkOrderTranslationKey.WORK_ORDER_MODAL}.${WorkOrderModalTranslationKey.NO_BIND_ORDER_ERROR_MSG}`) ?? ''}
          style={{ backgroundColor: 'white' }}
          id="order"
          invalid={Boolean(errors.order)}
          feedbackInvalid={errors.order?.message}
          readOnly
          disabled>
        </CFormInput>
      </CCol>
      {!disabled && <CCol sm={2}>
        <CButton disabled={disabled} variant={'outline'} onClick={onAction}>{selectBtnCaption}</CButton>
      </CCol>}
    </CRow>
  );
};

export { WorkOrderRequestRow };
