import { CCardBody, CCardTitle, CFormInput } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { CarServiceProfileFormData } from 'common/types/types';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  register: UseFormRegister<CarServiceProfileFormData>,
  disabled?: boolean,
};

const DetailsCard: FC<Props> = ({ register, disabled }) => {

  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE);

  return (
    <div className={styles.cardContainer}>
      <CCardTitle className={styles.cardTitle}>{t('detailsCardTitle')}</CCardTitle>
      <CCardBody>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <CFormInput
              {...register('requisites.recipientName')}
              disabled={disabled}
              type="text"
              label={t('recipient')}
              name="requisites.recipientName"
            />
          </div>
          <div
            style={{
              marginTop: 15,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '25%' }}>
              <CFormInput
                {...register('requisites.inn')}
                disabled={disabled}
                type="text"
                label={t('ipn')}
                name="requisites.inn"
              />
            </div>
            <div style={{ width: '73%' }}>
              <CFormInput
                {...register('requisites.jurAddress')}
                disabled={disabled}
                type="text"
                label={t('legalAddress')}
                name="requisites.jurAddres"
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 15,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '25%' }}>
              <CFormInput
                { ...register('requisites.edrpou') }
                disabled={disabled}
                type="text"
                label={t('edrpou')}
                name="requisites.edrpou" />
            </div>
            <div style={{ width: '73%' }}>
              <CFormInput
                { ...register('requisites.iban') }
                disabled={disabled}
                type="text"
                label={t('account')}
                name="requisites.iban"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 15,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '25%' }}>
            <CFormInput
              { ...register('requisites.mfo') }
              disabled={disabled}
              type="text"
              label={t('mfo')}
              name="requisites.mfo"
            />
          </div>
          <div style={{ width: '73%' }}>
            <CFormInput
              { ...register('requisites.bankName') }
              disabled={disabled}
              type="text"
              label={t('bank')}
              name="requisites.bankName"
            />
          </div>
        </div>
      </CCardBody>
    </div>
  );
};

export { DetailsCard };
