import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppTranslationKey } from 'common/enums/enums';
import { AsyncThunkConfig, ChangePasswordRequestDto, UserDto } from 'common/types/types';
import { i18next } from 'i18n';

import { ActionType } from './common';

const getUserById = createAsyncThunk<
  UserDto,
  number,
  AsyncThunkConfig
  >(ActionType.GET_USER_BY_ID, async (userId, { extra }) => {
    const { userApi } = extra;

    return userApi.getUserById(userId);
  });

const changeNameUser = createAsyncThunk<
  UserDto,
  { userId: number, name: string },
  AsyncThunkConfig
  >(ActionType.CHANGE_NAME_USER_BY_ID, async (payload, { extra }) => {
    const { userApi } = extra;

    return userApi.changeNameUser(payload);
  });

const changePhoneUser = createAsyncThunk<
  UserDto,
  { userId: number, phone: string | null },
  AsyncThunkConfig
>(ActionType.CHANGE_PHONE_USER_BY_ID, async (payload, { extra }) => {
  const { userApi } = extra;

  return userApi.changePhoneUser(payload);
});

const changePassword = createAsyncThunk<
  { message: string, code: number },
  ChangePasswordRequestDto,
  AsyncThunkConfig
>(ActionType.CHANGE_PASSWORD_USER_BY_ID, async (payload, { extra }) => {
  const { userApi, notification } = extra;

  const response = await userApi.changePasswordUser(payload);

  if (!response.code){
    notification.success(i18next.t(`${AppTranslationKey.PROFILE}:changePasswordModal.successMessage`) as string);
  }

  return response;
});

const changeLocale = createAsyncThunk<
  UserDto,
  { userId: number, locale: string },
  AsyncThunkConfig
>(ActionType.CHANGE_LOCALE, async (payload, { extra, dispatch }) => {
  const { userApi } = extra;

  const response = await userApi.changeLocale(payload);

  dispatch(getUserById(payload.userId));

  return response;
});

export { changeLocale, changeNameUser, changePassword, changePhoneUser, getUserById };
