import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CFormInput, CSmartTable } from '@coreui/react-pro';
import { SelectedWorks } from 'common/types/types';
import { RemoveItemPayload } from 'components/modals/select-work-to-order-modal/type/remove-item-payload.type';
import { FC } from 'react';

import { getSelectedWorkTableColumn } from './heplpers/helpers';

type Props = {
  selectedWorks: SelectedWorks[],
  onRemoveSelectedWork: (item: RemoveItemPayload) => void,
  onChangePrice: (price: string, workId: number, rowId: number | null) => void,
  onChangeQty: (qty: string, workId: number, rowId: number | null) => void,
  onEdit: (rowId: number) => void,
  isCanRemove: (item?: SelectedWorks) => boolean,
  isModifyWorkInTask?: boolean,
};
const SelectedWorksTable: FC<Props> = ({
  selectedWorks,
  onRemoveSelectedWork,
  onChangePrice,
  onChangeQty,
  onEdit,
  isCanRemove,
  isModifyWorkInTask = false,
}) => {

  const columns = getSelectedWorkTableColumn();

  return (
    <CSmartTable
      columns={columns}
      items={selectedWorks}
      itemsPerPage={selectedWorks.length}
      loading={isModifyWorkInTask}
      scopedColumns={{
        remove: (item: SelectedWorks) => (
          <td style={ { verticalAlign: 'middle' } }>
            { isCanRemove(item) ? <CIcon
              style={ { cursor: 'pointer' } }
              className="text-danger"
              size="xl"
              icon={ cilTrash }
              onClick={ (): void =>
                onRemoveSelectedWork({ name: item.name, rowId: item?.rowId ?? null, id: item.workId }) }
            /> : '' }
          </td>
        ),
        price: (item: SelectedWorks) => (
          <td>
            <CFormInput
              type="text"
              id="price"
              value={item.price}
              onChange={ (event): void =>
                onChangePrice(event.target.value, item.workId, item?.rowId ?? null)}
              onBlur={ (): void => {
                item.rowId && onEdit(item.rowId);
              } }
            />
          </td>
        ),
        qty: (item: SelectedWorks) => (
          <td>
            <CFormInput
              type="text"
              id="qty"
              value={item.qty}
              onChange={ (event): void =>
                onChangeQty(event.target.value, item.workId, item?.rowId ?? null)}
              onBlur={ (): void => {
                item.rowId && onEdit(item.rowId);
              } }
            />
          </td>
        ),
        sum : (item: SelectedWorks) => (
          <td>
            <CFormInput
              type="number"
              id="qty"
              min="0"
              value={item.sum}
              disabled
            />
          </td>
        ),
      }}
      tableHeadProps={{
        color: 'light',
      }}
      tableProps={{
        striped: true,
        hover: true,
      }}
    />

  );
};

export { SelectedWorksTable };
