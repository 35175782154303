import { cilPrint } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { documentationAction } from 'store/actions';

import styles from './style.module.scss';

interface IProps {
  orderId: number,
  directionMenu?:  'center' | 'dropup' | 'dropup-center' | 'dropend' | 'dropstart' | undefined,
}

const OrderCertificateButton: FC<IProps> = ({ orderId, directionMenu }) => {
  const dispatch = useAppDispatch();
  const { documentTypes } = useAppSelector(({ dictionary }) =>
    ({
      documentTypes:  dictionary.data?.documentTypes ?? [],
    }));

  const { t } = useTranslation(AppTranslationKey.COMMON);

  const documents = documentTypes.length > 0 ? [...documentTypes].sort((a, b) => a.listPosition - b.listPosition) : [];

  const handleItemClick = (documentTypeId: number): void => {

    dispatch(documentationAction.getDocById(
      {
        requestId: Number(orderId),
        documentTypeId: documentTypeId,
      },
    ))
      .unwrap()
      .then(({ url }) => window.open(url));
  };

  return (
    <CDropdown
      variant="btn-group"
      direction={ directionMenu }
    >
      <CDropdownToggle className={ styles.certificateButton } color="warning" >
        { t('docButtonCaption') }
        <CIcon icon={cilPrint} size="lg"/>
      </CDropdownToggle>
      <CDropdownMenu>
        {
          documents.map((it) => (
            <CDropdownItem
              className={ styles.dropDownItem }
              onClick={():void => handleItemClick(it.documentTypeId)}
              key={it.documentTypeId}
            >
              {it.documentTypeName}
            </CDropdownItem>
          ))
        }
      </CDropdownMenu>
    </CDropdown>
  );
};

export { OrderCertificateButton };
