import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormFeedback,
  CFormInput,
  CInputGroup,
} from '@coreui/react-pro';
import { AppTranslationKey, InfoVinTranslationKey } from 'common/enums/enums';
import { InfoVinSearchRequest } from 'common/types/types';
import { usePermissionToastContext } from 'components/common/common';
import { useAppDispatch, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { carActions } from 'store/actions';

import { CarTableInfo } from './components/components';
import styles from './styles.module.scss';

const InfoVin: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.INFO_VIN);
  const [placeholderInputVin, setPlaceholderInputVin] =
    useState<string>(t(InfoVinTranslationKey.VIN_INPUT_PLACEHOLDER) as string);
  const [placeholderInputGosNum, setPlaceholderInputGosNum] =
    useState<string>(t(InfoVinTranslationKey.GOS_NUMBER_INPUT_PLACEHOLDER) as string);
  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const { onPermissionAction } = usePermissionToastContext();

  const {
    register,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<InfoVinSearchRequest>({
    defaultValues: { regNum: '', vinCode: '' },
  });

  const onFocusInputVin = (): void => {
    setPlaceholderInputVin('');
  };

  const onBlurInputVin = (): void => {
    setPlaceholderInputVin(t(InfoVinTranslationKey.VIN_INPUT_PLACEHOLDER) as string);
  };

  const onFocusInputGosNum = (): void => {
    setPlaceholderInputGosNum(t(InfoVinTranslationKey.GOS_NUMBER_INPUT_ACTIVE_PLACEHOLDER) as string);
  };

  const onBlurInputGosNum = (): void => {
    setPlaceholderInputGosNum(t(InfoVinTranslationKey.GOS_NUMBER_INPUT_PLACEHOLDER) as string);
  };

  const onSearch = (data: InfoVinSearchRequest): void => {
    onPermissionAction(() => {
      if ((!data.vinCode || data.vinCode.trim().length === 0)
        && (!data.regNum || data.regNum.trim().length === 0)) {
        setIsEmptySearch(true);

        return;
      }

      dispatch(carActions.searchCar({
        vinCode: data.vinCode ? data.vinCode.replaceAll(' ', '').toUpperCase() : null,
        regNum: data.regNum ? data.regNum.replaceAll(' ', '').toUpperCase() : null,
      }));
    })();
  };

  const changeFormFields = (): void => {
    trigger();

    if (isEmptySearch) {
      const { vinCode, regNum } = getValues();

      if (vinCode?.trim().length !== 0 || regNum?.trim().length !== 0) {
        setIsEmptySearch(false);
      }
    }
  };

  return (
    <CCard>
      <CCardHeader>
        <h5 className="text-dark">{ t(InfoVinTranslationKey.TITLE) }</h5>
      </CCardHeader>
      <CCardBody>
        <CForm
          onChange={ changeFormFields }
          onSubmit={ handleSubmit(onSearch) }
          className="pb-5"
        >
          <p className={ styles.inputTitle }>
            { t(InfoVinTranslationKey.SEARCH_INPUT_TITLE) }
          </p>
          <CInputGroup className={ styles.groupInput }>
            <CFormInput
              { ...register('vinCode') }
              className={ styles.inputVin }
              onFocus={ onFocusInputVin }
              onBlur={ onBlurInputVin }
              placeholder={ placeholderInputVin }
              invalid={Boolean(errors.vinCode) || isEmptySearch}
            />
            <CFormInput
              { ...register('regNum') }
              className={ styles.inputAutoGosNumber }
              onFocus={ onFocusInputGosNum }
              onBlur={ onBlurInputGosNum }
              placeholder={ placeholderInputGosNum }
              invalid={Boolean(errors.regNum) || isEmptySearch}
            />
            <CButton type="submit" color="primary">
              { t(InfoVinTranslationKey.SEARCH_BUTTON_TEXT) }
            </CButton>
          </CInputGroup>
          <div className={ styles.gorupFeedback }>
            <CFormFeedback invalid={ Boolean(errors.vinCode) } className={ styles.feedbackVin } >
              { errors.vinCode?.message }
            </CFormFeedback>
            <CFormFeedback invalid={ Boolean(errors.regNum) } className={ styles.feedbackGos } >
              { errors.regNum?.message }
            </CFormFeedback>
          </div>
          <CFormFeedback invalid={ isEmptySearch } className="d-block" >
            { isEmptySearch ? t(InfoVinTranslationKey.ERROR_EMPTY_SEARCH) : '' }
          </CFormFeedback>
        </CForm>
        <CarTableInfo/>
      </CCardBody>
    </CCard>
  );
};

export { InfoVin };
