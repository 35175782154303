import { CCol, CFormSelect, CRow } from '@coreui/react-pro';
import { WorkOrderFormData, WorkOrdersStatus } from 'common/types/types';
import { ModalLabel } from 'components/modals/components/components';
import { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

type Props = {
  workOrdersStatuses: WorkOrdersStatus[];
  label: string,
  disabled: boolean,
  register: UseFormRegister<WorkOrderFormData>,
};

const WorkOrderStatusRow: FC<Props> = ({ workOrdersStatuses, label, disabled, register }) => {
  return(
    <CRow className="mb-3">
      <ModalLabel label={label} />
      <CCol sm={10}>
        <CFormSelect
          {...register('workOrderStatusId')}
          name="workOrderStatusId"
          disabled={disabled}
        >
          { workOrdersStatuses.map( (it) => (
            <option key={it.id} value={it.id}>
              {it.name}
            </option>
          ))}
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export { WorkOrderStatusRow };
