import { AppTranslationKey, DataStatus, InfoVinTranslationKey } from 'common/enums/enums';
import { Spinner } from 'components/common/common';
import { useAppDispatch, useAppSelector, useEffect } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clearSearchCarInfo } from 'store/car/reducer';

import { InfoMessage } from '../info-message/info-message';
import { InfoSector } from '../info-sectort/info-sector';
import { CarTableField } from './car-table-field';
import { configTable } from './config-table';
import styles from './styles.module.scss';

const CarTableInfo: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.INFO_VIN);
  const { infoCar, isLoad } = useAppSelector(( { car } ) => ({
    infoCar: car.searchedInfoCar,
    isLoad: car.searchedInfoCarDataStatus === DataStatus.PENDING,
  }));

  useEffect(() => {
    return () => {
      dispatch(clearSearchCarInfo());
    };
  }, []);

  if (isLoad) {
    return <Spinner/>;
  }

  if (infoCar === null) {
    return <InfoSector />;
  }

  if ('title' in infoCar) {
    return (
      <InfoMessage
        title={ infoCar.title }
        message={ infoCar.message + '.' }
        isError
      />
    );
  }

  const config = configTable(infoCar);

  return (
    <div className={ styles.wrapperTable }>
      <p className={styles.title}>
        {t(InfoVinTranslationKey.TABLE_INFO_TITLE)}
      </p>
      <div className={styles.table}>
        {
          config.map((block) => (
            <div className={ styles.tableBlock }>
              { block.items.map(({ title, value }) => <CarTableField title={ title } value={ value() } />) }
            </div>
          ))
        }
      </div>
    </div>
  );
};

export { CarTableInfo };
