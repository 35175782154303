enum AuthApiPath {
  ROOT = '/',
  GET_TOKEN = '/get-token',
  CHECK_TOKEN = '/check-token',
  CREATE_USER = '/create',
  LOGOUT = '/logout',
  REFRESH_TOKEN = '/refresh-token',
}

export { AuthApiPath };
