import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { FC } from 'react';
import { userActions } from 'store/actions';

import { getConfig } from './config';
import styles from './styles.module.scss';

const LangSelector: FC = () => {
  const dispatch = useAppDispatch();

  const { userId, currentLang } = useAppSelector( ({ auth })=> ({
    userId: auth.currentUser?.id ?? 0,
    currentLang: auth.currentUser?.locale,
  }));

  const { current, list } = getConfig(currentLang || 'uk');

  const onChangeLang = (locale: string): void => {
    dispatch(userActions.changeLocale({ userId, locale }));
  };

  return (
    <CDropdown variant="nav-item" className={cn('list-unstyled', styles.langSelector)}>
      <CDropdownToggle className={ styles.dropDownToggle }>
        { current.icon }
      </CDropdownToggle>
      <CDropdownMenu className={ styles.langSelectorMenu }>
        {
          list.map((item) => (
            <CDropdownItem
              href="#"
              className={ styles.dropdownItem }
              onClick={ (): void => onChangeLang(item.lang) }
              key={ item.lang }
            >
              { item.icon }
              <span className="mx-2">
                { item.title }
              </span>
            </CDropdownItem>
          ))
        }
      </CDropdownMenu>
    </CDropdown>
  );
};

export { LangSelector };
