import 'react-tooltip/dist/react-tooltip.css';

import { cilCheckAlt, cilX } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CFormRange } from '@coreui/react-pro';
import cn from 'classnames';
import { AppTranslationKey,DataStatus , ENV } from 'common/enums/enums';
import { UserTariff } from 'common/types/types';
import { useAppDispatch, useAppSelector,useEffect, useState } from 'hooks/hooks';
import { encode } from 'js-base64';
import React, { ChangeEvent, FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { tariffsActions } from 'store/actions';

import { getActivePanelConfig } from './active-panel-config';
import styles from './styles.module.scss';

const UNLIMITED_SYMBOL = '∞';
const SUCCESS_URL = `${ENV.API_PATH}${ENV.TARIFF_SUCCESS_URL}/${encode(ENV.TARIFF_REDIRECT_SUCCESS_URL)}`;
const FAILURE_URL = `${ENV.API_PATH}${ENV.TARIFF_FAILURE_URL}/${encode(ENV.TARIFF_REDIRECT_FAILURE_URL)}`;

type Props = {
  id: number,
  tariffName: string,
  countRequests: number,
  isWorks: boolean,
  isWorkOutfit: boolean,
  isProvider: boolean,
  isStorage: boolean,
  price: number,
  disabled: boolean,
  description: string,
  setIsDisable: (value: boolean) => void,
  isDisableButton?: boolean,
  addInfo?: React.ReactNode[],
  currentTariff?: UserTariff,
  nextTariff?: UserTariff | null,
  maxCountMonths: number,
};

const TariffPanel: FC<Props> = ({
  id,
  tariffName,
  countRequests,
  isWorks,
  isWorkOutfit,
  isProvider,
  isStorage,
  price,
  description,
  disabled,
  setIsDisable,
  addInfo = [],
  isDisableButton = false,
  currentTariff,
  nextTariff,
  maxCountMonths,
}) => {
  const { t } = useTranslation(AppTranslationKey.TARIFFS);
  const dispatch = useAppDispatch();
  const [isClickPayment, setIsClickPayment] = useState(false);
  const [countMonth, setCountMonth] = useState<number>(1);

  const panelConfig = getActivePanelConfig(id, currentTariff || null, nextTariff || null);

  const { userId, paymentId, payeeId, statusPayment } = useAppSelector(({ auth, tariff }) => ({
    userId: auth.currentUser?.id ?? 0,
    paymentId: tariff.payment?.payment.paymentId,
    payeeId: tariff.payment?.payment.payeeId,
    statusPayment: tariff.dataStatusCreatePayment,
  }));

  const refForm = useRef<HTMLFormElement>(null);

  const onCreatePayment = (): void => {
    setIsDisable(true);
    dispatch(tariffsActions.createPayment({
      userId,
      tariffId: id,
      amount: price * countMonth,
      monthCount: countMonth,
      paymentSourceId: 1,
      paymentProviderId: 1,
    })).then((response) => {
      if(response.payload) {
        setIsClickPayment(true);
      } else {
        setIsDisable(false);
      }
    });
  };

  const onChangeCountMonth = (event: ChangeEvent<HTMLInputElement>): void => {
    setCountMonth(Number(event.target.value));
  };

  useEffect(() => {
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        setIsDisable(false);
      }
    });
  }, [setIsDisable]);

  useEffect(() => {
    if(isClickPayment && refForm.current && paymentId && statusPayment === DataStatus.FULFILLED) {
      setIsClickPayment(false);
      refForm.current.submit();
    }
  }, [paymentId, statusPayment, isClickPayment, setIsDisable]);

  return (
    <div className={ styles.tariffPanelWrapper }>
      <div className={ panelConfig.className }>{ panelConfig.text }</div>
      <div className={ styles.tariffPanelContainer } data-tooltip-id={ `${id}_tariff` }>
        <div className={ cn(styles.tariffPanelRow, styles.tariffPanelTitle) }>
          { t(tariffName) }
        </div>
        <div className={ cn(styles.tariffPanelRow, styles.tariffPanelCondition) }>
          <span>{ t('countRequests') }</span>
          <span>{ countRequests === -1 ? UNLIMITED_SYMBOL : countRequests }</span>
        </div>
        <div className={ cn(styles.tariffPanelRow, styles.tariffPanelCondition) }>
          <span>{ t('works') }</span>
          <CIcon icon={ isWorks ? cilCheckAlt : cilX } />
        </div>
        <div className={ cn(styles.tariffPanelRow, styles.tariffPanelCondition) }>
          <span>{ t('workOutfit') }</span>
          <CIcon icon={ isWorkOutfit ? cilCheckAlt : cilX } />
        </div>
        <div className={ cn(styles.tariffPanelRow, styles.tariffPanelCondition) }>
          <span>{ t('provider') }</span>
          <CIcon icon={ isProvider ? cilCheckAlt : cilX } />
        </div>
        <div className={ cn(styles.tariffPanelRow, styles.tariffPanelCondition) }>
          <span>{ t('storage') }</span>
          <CIcon icon={ isStorage ? cilCheckAlt : cilX } />
        </div>
        {
          price === 0
            ? addInfo.map((item, index) => (
              <div key={ index } className={ cn(styles.tariffPanelRow, styles.tariffPanelInformation) }>
                { item }
              </div>
            ))
            : (
              <div className={ cn(styles.tariffPanelFooter) }>
                <span className={ styles.footerTitle }>{ t('calcPrice') }</span>
                <span>{ t('periodSubscribers', { countMonth }) }</span>
                <CFormRange
                  min={ 1 }
                  max={ maxCountMonths }
                  step={ 1 }
                  value={ countMonth }
                  onChange={ onChangeCountMonth }
                />
                <span className={ styles.priceValue }>{ price * countMonth } { t('currencyUAH') }</span>
                <form action="https://www.portmone.com.ua/gateway/" method="post" ref={ refForm }>
                  <input type="hidden" name="payee_id" value={ payeeId } />
                  <input type="hidden" name="shop_order_number" value={ `${paymentId}_ucs` } />
                  <input type="hidden" name="bill_amount" value={ price * countMonth }/>
                  <input type="hidden" name="description"
                    value={ t('descriptionPayment',  { tariffName: t(tariffName), countMonth }) as string }/>
                  <input type="hidden" name="success_url" value={ SUCCESS_URL } />
                  <input type="hidden" name="failure_url" value={ FAILURE_URL } />
                  <input type="hidden" name="lang" value="uk" />
                  <input type="hidden" name="encoding"  value= "UTF-8" />
                  <input type="hidden" name="exp_time"  value= "400" />
                </form>
                <CButton
                  color="primary"
                  onClick={ onCreatePayment }
                  disabled={ disabled || isDisableButton }
                  className={ cn({ [styles.disableButton]: isDisableButton }) }
                >
                  { isDisableButton ? t('disableButtonText') : t('subscribeButton') }
                </CButton>
              </div>
            )
        }
      </div>
      <Tooltip
        id={ `${id}_tariff` }
        content={ description }
        place="top"
        className={ styles.tariffTooltip }
      />
    </div>
  );
};

export { TariffPanel };
