import {
  AppTranslationKey,
  SelectSparePartsTableTranslationKey,
} from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getSelectedSparePartsTableColumnHelper = (): TableColumn[] => {

  return [
    {
      key: 'sparePartName',
      label: t(
        `${
          AppTranslationKey.SELECT_SPARE_PARTS_TABLE
        }:${
          SelectSparePartsTableTranslationKey.SPARE_PARTS_NAME
        }`,
      ),
      _style: { width: '50%' },
    },
    {
      key: 'count',
      label: t(`${
        AppTranslationKey.SELECT_SPARE_PARTS_TABLE
      }:${
        SelectSparePartsTableTranslationKey.SPARE_PARTS_QTY
      }`),
      _style: { width: '15%' },
    },
    {
      key: 'priceOne',
      label: t(`${
        AppTranslationKey.SELECT_SPARE_PARTS_TABLE
      }:${
        SelectSparePartsTableTranslationKey.SPARE_PARTS_PRICE
      }`),
      _style: { width: '15%' },
    },
    {
      key: 'priceTotal',
      label: t(`${
        AppTranslationKey.SELECT_SPARE_PARTS_TABLE
      }:${
        SelectSparePartsTableTranslationKey.SPARE_PARTS_SUM
      }`),
      _style: { width: '15%' },
    },
    {
      key: 'remove',
      label: '',
    },
  ];
};

export { getSelectedSparePartsTableColumnHelper };
