import { ApiPath, CarApiPath, ContentType, HttpMethod } from 'common/enums/enums';
import {
  CarDto,
  GetCarByIdRequestDto,
  GetCarsDataRequestDto,
  GetCarsDataResponseDto, InfoVinSearchDto, InfoVinSearchRequest,
  Model,
  ModifyCarDataRequestDto,
} from 'common/types/types';

import { Http } from '../http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class CarApiService {
  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public getModels({ car_brand_id }:{ car_brand_id: number }):Promise<Model[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.DICTIONARY}${CarApiPath.MODELS}?car_brand_id=${car_brand_id}`,
    );
  }

  public addCar(payload:ModifyCarDataRequestDto):Promise<CarDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CARS}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public getCars({
    carServiceId,
    page = 0,
    size = 10,
    searchBrand = '',
    searchModel = '',
    searchRegNum = '',
    searchInfo = '',
  }:GetCarsDataRequestDto):Promise<GetCarsDataResponseDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CARS}${CarApiPath.CARSERVICE}/${carServiceId}`,
      {
        queryString: {
          page,
          size,
          searchBrand,
          searchModel,
          searchRegNum,
          searchInfo,
        },
      },
    );
  }

  public getCarById({ carId, locale = '' }: GetCarByIdRequestDto): Promise<CarDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CARS}/${carId}`,
      {
        queryString: {
          locale,
        },
      },
    );
  }

  public updateCar(payload: ModifyCarDataRequestDto):Promise<CarDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CARS}/${payload.carId}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
        queryString: {
          locale: '',
        },
      },
    );
  }

  public searchCar(payload: InfoVinSearchRequest):Promise<InfoVinSearchDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CARS}${CarApiPath.SEARCH}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }
}

export { CarApiService };
