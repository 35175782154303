import { AppTranslationKey, SalaryKey } from 'common/enums/enums';
import { withCarServicesTabs } from 'hoc/hoc';
import { t as tt } from 'i18next';
import { FC } from 'react';

const Salary: FC = () => {
  return (
    <div>Salary</div>
  );
};

export const SalaryWithCarServicesTabs = withCarServicesTabs(
  Salary,
  false,
  undefined,
  () => tt(`${ AppTranslationKey.SALARY }:${ SalaryKey.TITLE }`) ?? '',
);
