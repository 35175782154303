import { CFormSwitch } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { getPeriodToday, isPeriodToday } from 'helpers/helpers';
import { useEffect } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useFilterPanelContext } from '../filter-panel-context';

const FieldToday: FC = () => {
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE_ORDER);
  const { filters: { isToday, dateFrom, dateTo }, setOrderFilters } = useFilterPanelContext();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;

    setOrderFilters({
      isToday: checked,
      isCurrentWeek: false,
      isCurrentMonth: false,
      ...getPeriodToday(),
    });
  };

  useEffect(() => {
    if(isToday && !isPeriodToday({ dateFrom, dateTo })) {
      setOrderFilters({ isToday: false });
    }
  }, [isToday, dateFrom, dateTo]);

  return (
    <CFormSwitch
      size="lg"
      label={ t('filters.today') }
      checked={ isToday }
      onChange={ onChange }
    />
  );
};

export { FieldToday };
