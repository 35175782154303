import { AppTranslationKey } from 'common/enums/enums';
import { TariffDataItem, TariffDto, TariffView } from 'common/types/types';
import { Tariffs } from 'constants/tariffs';
import { i18next } from 'i18n';
import React from 'react';

const additionalInfo: Record<number, React.ReactNode[]> = {
  [Tariffs.PARTNERSHIP]: [
    i18next.t(`${AppTranslationKey.TARIFFS}:addInfo.partnership.info1`),
    <>&bull;&nbsp;{ i18next.t(`${AppTranslationKey.TARIFFS}:addInfo.partnership.info2`) }</>,
    <>&bull;&nbsp;{ i18next.t(`${AppTranslationKey.TARIFFS}:addInfo.partnership.info3`) }</>,
  ],
  [Tariffs.TEST_UNLIMITED]: [
    i18next.t(`${AppTranslationKey.TARIFFS}:addInfo.testUnlimited.info1`),
    i18next.t(`${AppTranslationKey.TARIFFS}:addInfo.testUnlimited.info2`),
    i18next.t(`${AppTranslationKey.TARIFFS}:addInfo.testUnlimited.info3`),
  ],
};

const getTariffsConfig = (
  tariffs: TariffDto[],
  tariffsData: TariffDataItem[],
): TariffView[] => {
  const config: TariffView[] = [];

  tariffsData.forEach(({ id, btn, cnt }) => {
    const tariff = tariffs.find((item) => item.tariffId === id);

    if (tariff) {
      config.push({
        id: tariff.tariffId,
        tariffName: tariff.name,
        countRequests: tariff.countOpenRequest || -1,
        isWorks: !!tariff.jsonData?.works,
        isWorkOutfit: !!tariff.jsonData?.orders,
        isProvider: !!tariff.jsonData?.counterparties,
        isStorage: !!tariff.jsonData?.stocks,
        price: tariff.costPerMonth,
        isRender: tariff.tariffId !== Tariffs.NO_TARIFF,
        description: tariff.description,
        maxCountMonths: cnt,
        isDisableButton: !btn,
        addInfo: [...(additionalInfo[tariff.tariffId] ? additionalInfo[tariff.tariffId] : [])],
      });
    }
  });

  return config;
};

export { getTariffsConfig };
