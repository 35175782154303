import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { SparePartForm } from 'common/types/types';
import { onlyNumberString } from 'helpers/helpers';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<SparePartForm>,
  errors: FieldErrors<SparePartForm>,
  setValue: UseFormSetValue<SparePartForm>,
  disabled: boolean,
};

const ValueMinField: FC<Props> = ({ register, errors, disabled, setValue }) => {
  const { t } = useTranslation(AppTranslationKey.SPARE_PARTS);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="valueMin" className="col-sm-3 col-form-label">
        { t('valueMin') }
      </CFormLabel>
      <CCol sm={9}>
        <CFormInput
          type="text"
          disabled={ disabled }
          { ...register('valueMin', { required: t('requiredFormField') ?? 'This field is required' }) }
          id="valueMin"
          invalid={Boolean(errors.valueMin)}
          feedbackInvalid={errors.valueMin?.message}
          placeholder="0"
          onChange={(event): void => {
            setValue('valueMin', onlyNumberString(event.target.value));
          }}
        />
      </CCol>
    </CRow>
  );
};

export { ValueMinField };
