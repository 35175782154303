import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { CarWorkFav, CarWorkGetAllResponse } from 'common/types/types';

import {
  getAllCarWorkByCarService,
  getFavoritesCarWork,
  updateFavoritesCarWork,
  updFavListByCarServiceId,
} from './actions';

type State = {
  favoritesCarWork: CarWorkFav[],
  allCarWork: CarWorkGetAllResponse[],
  favoritesCarWorkDataStatus: DataStatus,
  updateFavCarWorkDataStatus: DataStatus,
  updateFavCarWorkListDataStatus: DataStatus,
  getAllCarWorkDataStatus: DataStatus,
};

const initialState: State = {
  favoritesCarWork: [],
  allCarWork: [],
  favoritesCarWorkDataStatus: DataStatus.IDLE,
  updateFavCarWorkDataStatus: DataStatus.IDLE,
  updateFavCarWorkListDataStatus: DataStatus.IDLE,
  getAllCarWorkDataStatus: DataStatus.IDLE,
};

const carWorkSlice = createSlice({
  name: 'carWork',
  initialState,
  reducers: {
    updateCarWork: (state, action: PayloadAction<number>) => {
      state.allCarWork = state.allCarWork.map((item) => {
        if (item.serviceWorkId === action.payload) {
          item.isFavorite = !item.isFavorite;
        }

        return item;
      });
    },
  },
  extraReducers: ( builder ) => {
    builder
      //get favorites car work
      .addCase(getFavoritesCarWork.pending, (state) => {
        state.favoritesCarWorkDataStatus = DataStatus.PENDING;
      })
      .addCase(getFavoritesCarWork.rejected, (state) => {
        state.favoritesCarWorkDataStatus = DataStatus.REJECTED;
        state.favoritesCarWork = [];
      })
      .addCase(getFavoritesCarWork.fulfilled, (state, { payload }) => {
        state.favoritesCarWorkDataStatus = DataStatus.FULFILLED;
        state.favoritesCarWork = payload;
      })

      //update favorites car work
      .addCase(updateFavoritesCarWork.pending, (state) => {
        state.updateFavCarWorkDataStatus = DataStatus.PENDING;
      })
      .addCase(updateFavoritesCarWork.rejected, (state) => {
        state.updateFavCarWorkDataStatus = DataStatus.REJECTED;
      })
      .addCase(updateFavoritesCarWork.fulfilled, (state, { payload }) => {
        state.updateFavCarWorkDataStatus = DataStatus.FULFILLED;
        state.favoritesCarWork = state.favoritesCarWork.map( (item) => {
          if(item.serviceWorkId === payload.serviceWorkId) {
            return { ...item, ...payload };
          }

          return item;
        });
      })

      //get all car work by car service
      .addCase(getAllCarWorkByCarService.pending, (state) => {
        state.getAllCarWorkDataStatus = DataStatus.PENDING;
      })
      .addCase(getAllCarWorkByCarService.rejected, (state) => {
        state.getAllCarWorkDataStatus = DataStatus.REJECTED;
        state.allCarWork = [];
      })
      .addCase(getAllCarWorkByCarService.fulfilled, (state, { payload }) => {
        state.getAllCarWorkDataStatus = DataStatus.FULFILLED;
        state.allCarWork = payload;
      })

      //update fav car work list
      .addCase(updFavListByCarServiceId.pending, (state) => {
        state.updateFavCarWorkListDataStatus = DataStatus.PENDING;
      })
      .addCase(updFavListByCarServiceId.rejected, (state) => {
        state.updateFavCarWorkListDataStatus = DataStatus.REJECTED;
      })
      .addCase(updFavListByCarServiceId.fulfilled, (state) => {
        state.updateFavCarWorkListDataStatus = DataStatus.FULFILLED;
      });
  },
});
const carWorkReducer = carWorkSlice.reducer;
const { updateCarWork } = carWorkSlice.actions;

export { carWorkReducer, updateCarWork };
