import { cilHistory } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CCard, CCardBody, CCollapse, CListGroup, CListGroupItem } from '@coreui/react-pro';
import cn from 'classnames';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeTableRow } from 'common/types/types';
import { ContactField, TypeContactField } from 'components/common/common';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemButtons } from './item-buttons';
import styles from './style.module.scss';

type Props = {
  item: EmployeeTableRow,
  carServiceId: number,
  visible: boolean,
};

const ItemDetails: FC<Props> = ({ item, carServiceId, visible }) => {
  const { t } = useTranslation([AppTranslationKey.EMPLOYEES_PROFILES, AppTranslationKey.COMMON]);

  return (
    <CCollapse visible={ visible }>
      <CCard color="light">
        <CCardBody>
          <CListGroup>
            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                {t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.BIRTHDATE}`)}:
              </span>
              <span className={ styles.itemValue }>{item.birthDate}</span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                {t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.REGISTRATION_DATE}`)}:
              </span>
              <span className={ styles.itemRegistrationDateContainer }>
                <span className={ styles.itemValue }>{item.registrationDate}</span>
                <span className={ cn(styles.workPeriodValue, { [styles.dismissal]: item.isDismissal }) }>
                  <CIcon icon={ cilHistory } />
                  {`${t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.WORK_PERIOD}`)}:`}
                  <span>{item.workPeriod}</span>
                </span>
              </span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                {t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.PERCENT_WORKS_V2}`)}:
              </span>
              <span className={ styles.itemValue }>
                { item.percentWorks ? `${ item.percentWorks }%` : '0%' }
              </span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                {t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.PERCENT_SPARES_V2}`)}:
              </span>
              <span className={ styles.itemValue }>
                { item.percentSpares ? `${ item.percentSpares }%` : '0%' }
              </span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                {t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.HOURLY_RATE_V2}`)}:
              </span>
              <span className={ styles.itemValue }>
                {
                  item.hourlyRate
                    ? `${ item.hourlyRate } ${ t('uah', { ns: AppTranslationKey.COMMON }) }`
                    : `0 ${ t('uah', { ns: AppTranslationKey.COMMON }) }`
                }
              </span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                {t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.FIXED_RATE_V2}`)}:
              </span>
              <span className={ styles.itemValue }>
                {
                  item.fixedRate
                    ? `${ item.fixedRate } ${ t('uah', { ns: AppTranslationKey.COMMON }) }`
                    : `0 ${ t('uah', { ns: AppTranslationKey.COMMON }) }`
                }
              </span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                {t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.CONTACTS}`)}:
              </span>
              <div className={ styles.itemContactContainer }>
                <ContactField type={ TypeContactField.VIBER } value={ item.viber } />
                <ContactField type={ TypeContactField.TELEGRAM } value={ item.telegram } />
                <ContactField type={ TypeContactField.EMAIL } value={ item.email } />
              </div>
            </CListGroupItem>

            <ItemButtons employeeId={ item.id } carServiceId={ carServiceId } />
          </CListGroup>
        </CCardBody>
      </CCard>
    </CCollapse>
  );
};

export { ItemDetails };
