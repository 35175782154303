import {
  AppTranslationKey,
  CarWorkTranslationKey,
  EmployeesProfilesKey,
  Pages,
  StorageTranslationKey,
  WorkOrderTranslationKey,
} from 'common/enums/enums';

const extraButtonTitle = {
  [Pages.ORDERS]: `${AppTranslationKey.CAR_SERVICE_ORDER}:addOrderCaptionButton`,
  [Pages.WORK_ORDERS]: `${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.ADD_WORK_ORDER}`,
  [Pages.CARS]: `${AppTranslationKey.CAR_SERVICE_AUTO}:addCarCaptionButton`,
  [Pages.WORKS]: `${AppTranslationKey.CAR_WORK}:${CarWorkTranslationKey.ADD_CAR_WORK_CAPTION_BUTTON}`,
  [Pages.CAR_SERVICE]: `${AppTranslationKey.CAR_SERVICE}:addCarServiceCaptionButton`,
  [Pages.STORAGE]: `${AppTranslationKey.STOCK}:${StorageTranslationKey.ADD_STORAGE}`,
  [Pages.EMPLOYEES]: `${AppTranslationKey.EMPLOYEES_PROFILES}:${EmployeesProfilesKey.ADD_BUTTON_TITLE}`,
};

export { extraButtonTitle };
