import { cifUa } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import React, { FC } from 'react';

import styles from './styles.module.scss';

const CarStateLeftSide: FC = () => {
  return (
    <div className={styles.carStateNumLeftSide}>
      <CIcon className={styles.carStateNumLeftSideIcon} icon={cifUa} width={10}/>
      <span className={styles.carStateNumLeftSideText}>UA</span>
    </div>
  );
};

export { CarStateLeftSide };
