enum OrderStatus {
  NEW = 1,
  ON_APPROVAL = 2,
  APPROVED = 3,
  WAITING_TO_RECEIVE_CAR = 4,
  WAITING_TO_START_WORK = 5,
  IN_WORK = 6,
  WAINING_FOR_PARTS = 7,
  IN_WORK_AT_CONTRACTOR = 8,
  WORKS_COMPLETED = 9,
  AWAITING_PAYMENT = 10,
  WAITING_TO_RETURN_CAR = 11,
  CAR_IS_RETURNED = 12,
  CLOSE = 13,
  REJECTED = 14,
}
export { OrderStatus };
