import { ApiPath, ContentType, HttpMethod, UserApiPath } from 'common/enums/enums';
import { ChangePasswordRequestDto, UserDto } from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http,
  apiPrefix: string,
};

class UserApi {

  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;

    this.#apiPrefix = apiPrefix;
  }

  public getUserById(userId: number): Promise<UserDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.USERS}${UserApiPath.ROOT}${userId}`,
    );
  }

  public changeNameUser({ userId, name }:{ userId: number, name: string }): Promise<UserDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.USERS}${UserApiPath.ROOT}${userId}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify({ name }),
      },
    );
  }

  public changePhoneUser({ userId, phone }:{ userId: number, phone: string | null }): Promise<UserDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.USERS}${UserApiPath.ROOT}${userId}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify({ phone }),
      },
    );
  }

  public changePasswordUser(payload: ChangePasswordRequestDto): Promise<{ message: string, code: number }> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.USERS}${UserApiPath.CHANGE_PASSWORD}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public changeLocale({ userId, locale }:{ userId: number, locale: string }): Promise<UserDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.USERS}${UserApiPath.ROOT}${userId}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify({ locale }),
      },
    );
  }

}

export { UserApi };
