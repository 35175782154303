import { CFormTextarea } from '@coreui/react-pro';
import React from 'react';
import { FieldValues, Path } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props<T extends FieldValues> = {
  limitSymbols: number,
  value: string,
  label?: string | null,
  rows: number,
  disabled?: boolean,
  register: UseFormRegister<T>,
  nameFiled: Path<T>,
  id?: string,
};

const TextArea= <T extends FieldValues>({
  value,
  label,
  limitSymbols,
  rows,
  register,
  nameFiled,
  disabled,
  id,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation('common');

  const isValid = limitSymbols - (value?.length || 0) < 0;

  return (
    <CFormTextarea
      {
        ...register(nameFiled, {
          maxLength: {
            value: limitSymbols,
            message: t('textAreaErrorMaxLength') ?? 'Too many characters',
          },
        })
      }
      label={ label }
      id={ id }
      rows={ rows }
      invalid={ isValid }
      feedbackInvalid={ t('textAreaErrorMaxLength') ?? 'Too many characters' }
      style={{ backgroundPosition: 'top 5px right 20px' }}
      disabled={ disabled }
      text={t('textAreaText', { countSymbols: (limitSymbols - (value?.length || 0)) })}
    />
  );
};

export { TextArea };
