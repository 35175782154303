import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { CarDto, GetCarsDataResponseDto, InfoVinSearchDto, Model } from 'common/types/types';

import { addCar, getCarById, getCars, getModels, searchCar, updateCarById } from './actions';

type State = {
  models: Model[],
  carsData: GetCarsDataResponseDto,
  currentCar: {
    carId: number,
    isSelect: boolean,
  },
  searchedInfoCar: InfoVinSearchDto | null,
  modalSelectedCar: CarDto | null,
  dataStatusModels: DataStatus,
  dataStatusGetCars: DataStatus,
  getCarByIdDataStatus: DataStatus,
  modifyCarDataStatus: DataStatus,
  searchedInfoCarDataStatus: DataStatus,
};

const initialState: State = {
  models: [],
  carsData: {
    currentPage: 0,
    totalPages: 0,
    carsCount: 0,
    cars: [],
  },
  currentCar: {
    carId: 0,
    isSelect: false,
  },
  searchedInfoCar: null,
  modalSelectedCar: null,
  dataStatusModels: DataStatus.IDLE,
  dataStatusGetCars: DataStatus.IDLE,
  getCarByIdDataStatus: DataStatus.IDLE,
  modifyCarDataStatus: DataStatus.IDLE,
  searchedInfoCarDataStatus: DataStatus.IDLE,
};

const carSlice = createSlice({
  name: 'car',
  initialState,
  reducers: {
    manualAddCar: (state, action:PayloadAction<CarDto>) => {
      state.carsData.cars = [...state.carsData.cars, action.payload];
    },
    setCurrentCar: (state, action:PayloadAction<number>) => {
      state.currentCar.carId = action.payload;
      state.currentCar.isSelect = false;
    },
    selectCurrentCar: (state) => {
      state.currentCar.isSelect = true;
    },
    clearModalSelectedCar: (state) => {
      state.modalSelectedCar = null;
    },
    clearSearchCarInfo: (state) => {
      state.searchedInfoCar = null;
      state.searchedInfoCarDataStatus = DataStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder

      // get models
      .addCase(getModels.pending, (state) => {
        state.dataStatusModels = DataStatus.PENDING;
      })
      .addCase(getModels.rejected, (state) => {
        state.dataStatusModels = DataStatus.REJECTED;
        state.models = [];
      })
      .addCase(getModels.fulfilled, (state, { payload }) => {
        state.dataStatusModels = DataStatus.FULFILLED;
        state.models = payload;
      })

      // add car
      .addCase(addCar.pending, (state) => {
        state.modifyCarDataStatus = DataStatus.PENDING;
      })
      .addCase(addCar.rejected, (state) => {
        state.modifyCarDataStatus = DataStatus.REJECTED;
      })
      .addCase(addCar.fulfilled, (state) => {
        state.modifyCarDataStatus = DataStatus.FULFILLED;
      })

      // get cars
      .addCase(getCars.pending, (state) => {
        state.dataStatusGetCars = DataStatus.PENDING;
      })
      .addCase(getCars.rejected, (state) => {
        state.dataStatusGetCars = DataStatus.REJECTED;
        state.carsData = {
          currentPage: 0,
          totalPages: 0,
          carsCount: 0,
          cars: [],
        };
      })
      .addCase(getCars.fulfilled, (state , { payload }) => {
        state.dataStatusGetCars = DataStatus.FULFILLED;
        state.carsData = payload;
      })
      //get car by id
      .addCase(getCarById.pending, (state) => {
        state.getCarByIdDataStatus = DataStatus.PENDING;
      })
      .addCase(getCarById.rejected, (state) => {
        state.getCarByIdDataStatus = DataStatus.REJECTED;
      })
      .addCase(getCarById.fulfilled, (state, { payload }) => {
        state.getCarByIdDataStatus = DataStatus.FULFILLED;
        state.modalSelectedCar = payload;
      })
      //update car by id
      .addCase(updateCarById.pending, (state) => {
        state.modifyCarDataStatus = DataStatus.PENDING;
      })
      .addCase(updateCarById.rejected, ( state ) => {
        state.modifyCarDataStatus = DataStatus.REJECTED;
      })
      .addCase(updateCarById.fulfilled, ( state, { payload } ) => {
        state.modifyCarDataStatus = DataStatus.FULFILLED;
        state.carsData.cars = state.carsData.cars.map((item) => {
          if (item.carId === payload.carId) {
            return { ...item, ...payload };
          }

          return item;
        });
      })
      // search car
      .addCase(searchCar.pending, (state) => {
        state.searchedInfoCarDataStatus = DataStatus.PENDING;
      })
      .addCase(searchCar.rejected, (state) => {
        state.searchedInfoCarDataStatus = DataStatus.REJECTED;
      })
      .addCase(searchCar.fulfilled, (state, { payload }) => {
        state.searchedInfoCarDataStatus = DataStatus.FULFILLED;
        state.searchedInfoCar = payload;
      });
  },
});

const carReducer = carSlice.reducer;
const {
  manualAddCar,
  setCurrentCar,
  selectCurrentCar,
  clearModalSelectedCar,
  clearSearchCarInfo,
} = carSlice.actions;

export { carReducer, clearModalSelectedCar, clearSearchCarInfo ,manualAddCar, selectCurrentCar, setCurrentCar };
