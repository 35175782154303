import { CarWorkFav, WorkDto } from 'common/types/types';
import { isNull } from 'lodash';

type AdditionalFields = {
  requestId?: number,
  workCount: number,
};

const carWorkFavToWorkDto = (carWorkFav: CarWorkFav, additionalFields: AdditionalFields): WorkDto => {
  return {
    rowId: carWorkFav.rowId,
    priceOne: isNull(carWorkFav.serviceWorkCost) ? 0 : carWorkFav.serviceWorkCost,
    priceTotal: isNull(carWorkFav.serviceWorkCost) ? 0 : carWorkFav.serviceWorkCost,
    requestId: additionalFields.requestId,
    serviceWorkId: carWorkFav.serviceWorkId,
    serviceWorkName: carWorkFav.serviceWorkName,
    sparePartsId: null,
    workCount: additionalFields.workCount,
    orderId: '',
  };
};

export { carWorkFavToWorkDto };
