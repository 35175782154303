import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { UserDto } from 'common/types/types';

import { changeLocale, changeNameUser, changePassword, getUserById } from './actions';

type State = {
  dataStatus: DataStatus,
  changePasswordStatus: DataStatus,
  changeLocaleStatus: DataStatus,
  changePasswordError: string,
  user: UserDto | null,
};

const initialState: State = {
  dataStatus: DataStatus.IDLE,
  changePasswordStatus: DataStatus.IDLE,
  changeLocaleStatus: DataStatus.IDLE,
  changePasswordError: '',
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.user = null;
    },
    resetChangePasswordStatus: (state) => {
      state.changePasswordStatus = DataStatus.IDLE;
      state.changePasswordError = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getUserById.pending,
        (state) => {
          state.dataStatus = DataStatus.PENDING;
        })
      .addCase(
        getUserById.fulfilled,
        (state, { payload }) => {
          state.dataStatus = DataStatus.FULFILLED;
          state.user = payload;
        })
      .addCase(
        getUserById.rejected,
        (state) => {
          state.dataStatus = DataStatus.REJECTED;
        })
      .addCase(
        changeNameUser.pending,
        (state) => {
          state.dataStatus = DataStatus.PENDING;
        })
      .addCase(
        changeNameUser.fulfilled,
        (state, { payload }) => {
          state.dataStatus = DataStatus.FULFILLED;
          state.user = payload;
        })
      .addCase(
        changeNameUser.rejected,
        (state) => {
          state.dataStatus = DataStatus.REJECTED;
        })
      .addCase(changePassword.pending, (state) => {
        state.changePasswordStatus = DataStatus.PENDING;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.changePasswordStatus = DataStatus.FULFILLED;
        state.changePasswordError = payload.code === 201 ? payload.message : '';
      })
      .addCase(changePassword.rejected, (state) => {
        state.changePasswordStatus = DataStatus.REJECTED;
      })
      .addCase(changeLocale.pending, (state) => {
        state.changeLocaleStatus = DataStatus.PENDING;
      })
      .addCase(changeLocale.fulfilled, (state) => {
        state.changeLocaleStatus = DataStatus.FULFILLED;
      })
      .addCase(changeLocale.rejected, (state) => {
        state.changeLocaleStatus = DataStatus.REJECTED;
      });
  },
});

const userReducer = userSlice.reducer;
const { clearUser, resetChangePasswordStatus } = userSlice.actions;

export { clearUser, resetChangePasswordStatus, userReducer };
