import React, { createContext, FC, useContext } from 'react';

import { useChangePasswordModal } from './use-change-password-modal';

type ChangePasswordModalContextType = ReturnType<typeof useChangePasswordModal>;

const ChangePasswordModalContext = createContext<ChangePasswordModalContextType | null>(null);

const useChangePasswordModalContext = (): ChangePasswordModalContextType => {
  const context = useContext(ChangePasswordModalContext);

  if (!context) {
    throw new Error('useChangePasswordModalContext must be used within the ChangePasswordModalContext');
  }

  return context;
};

interface IProps {
  userId: number,
  children: React.ReactNode,
}

const ChangePasswordModalContextProvider: FC<IProps> = ({ userId, children }) => (
  <ChangePasswordModalContext.Provider value={ useChangePasswordModal(userId) }>
    { children }
  </ChangePasswordModalContext.Provider>
);

export { ChangePasswordModalContextProvider, useChangePasswordModalContext };
