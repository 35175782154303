import { createAsyncThunk } from '@reduxjs/toolkit';
import { LocalStorageKey } from 'common/enums/enums';
import {
  AddWorkOrderRequest,
  AsyncThunkConfig, FiltersPayload, FiltersStorage,
  GetWorkOrderByIdRequest, GetWorkOrderWorkRequest, GetWorkOrderWorkResponse, UpdClientSpWorkOrderRequest,
  WorkDto, WorkOrderAddWorkResponse,
  WorkOrderDto, WorkOrderFormData,
} from 'common/types/types';
import { initialFilters } from 'components/common/common';
import { getFormattedDate, getPayloadByOrderFilters } from 'helpers/helpers';
import { isNull } from 'lodash';
import { storage } from 'services/services';

import { ActionType } from './common';

const getWorkOrdersByFilters = createAsyncThunk<
  WorkOrderDto[],
  FiltersPayload,
  AsyncThunkConfig
>(ActionType.GET_WORK_ORDERS_BY_FILTERS, async (payload, { extra }) => {
  const { workOrderApi } = extra;

  return workOrderApi.getWorkOrdersByFilters(payload);
});

const addWorkOrder = createAsyncThunk<
  void,
  AddWorkOrderRequest,
  AsyncThunkConfig
>(ActionType.ADD_WORK_ORDERS, async (payload, { extra, dispatch }) => {
  const { workOrderApi } = extra;
  const { carServiceId } = payload;

  await workOrderApi.addWorkOrder(payload);

  if (carServiceId) {
    const filtersSettings: FiltersStorage = JSON.parse(storage.getItem(LocalStorageKey.WORK_ORDER_FILTERS) ?? '{}');
    const payload = getPayloadByOrderFilters(carServiceId, filtersSettings[carServiceId] || initialFilters);

    await dispatch(getWorkOrdersByFilters(payload));
  }
});

const getWorkOrderById = createAsyncThunk<
  WorkOrderDto,
  GetWorkOrderByIdRequest,
  AsyncThunkConfig
>( ActionType.GET_WORK_ORDER_BY_ID, async ({ orderId }, { extra }) => {
  const { workOrderApi } = extra;

  return  workOrderApi.getWorkOrderById({ orderId });
});

const getWorkOrderWork = createAsyncThunk<
  GetWorkOrderWorkResponse,
  GetWorkOrderWorkRequest,
  AsyncThunkConfig
>( ActionType.GET_WORK, async ({ orderId }, { extra }) => {
  const { workOrderApi } = extra;

  return workOrderApi.getWorkOrderWorks(  { orderId });
});

const removeWork = createAsyncThunk<
  void,
  WorkDto[],
  AsyncThunkConfig
>(ActionType.REMOVE_WORK, async (payload, { extra }) => {
  const { workOrderApi } = extra;
  payload.map(async (it) => it.rowId && await workOrderApi.removeWorkFromWorkOrder({ rowId: it.rowId }));
});

const addWork = createAsyncThunk<
  WorkOrderAddWorkResponse,
  WorkDto,
  AsyncThunkConfig
>(ActionType.ADD_WORK, async (payload, { extra }) => {
  const { workOrderApi } = extra;

  return workOrderApi.addWorkToWorkOrder(payload);
});

const updateWorkOrder = createAsyncThunk<
  void,
  WorkOrderFormData,
  AsyncThunkConfig
>(ActionType.UPDATE_WORK_ORDER, async (payload, { extra, getState }) => {
  const { workOrderApi, sparePartsApi } = extra;
  const {
    updatedWorkId,
    updatedSparePartsId,
    bindWork,
    bindSpareParts,
    workOrder,
    removedWorkId,
    removedSparePartsId,
    updatedClientSpareParts,
  } = getState().workOrder;

  const removedWork = workOrder?.works.filter((it) => removedWorkId.includes(it.serviceWorkId));
  const addedWork = bindWork?.filter((it) => isNull(it.rowId));

  const addedSpareParts = bindSpareParts.filter((it) => isNull(it.rowId));
  const removedSpareParts = workOrder?.spareParts.filter(
    (it) => (removedSparePartsId.includes(it.sparePartId)),
  );

  //update Client Spare Parts
  updatedClientSpareParts.length >0 && await sparePartsApi.editClientSpareParts({
    sparePartFromClientList: updatedClientSpareParts,
  });

  //remove
  removedWork?.map(async (it) =>
    it.rowId && await workOrderApi.removeWorkFromWorkOrder({ rowId: it.rowId }));
  removedSpareParts?.map(async (it) =>
    it.rowId && await workOrderApi.removeSparePartFromWorkOrder({ rowId: it.rowId }));

  //add
  addedWork?.map(async (it) => {
    await workOrderApi.addWorkToWorkOrder({ ...it, orderId: workOrder?.orderId, requestId: workOrder?.requestId });
  });
  addedSpareParts?.map(async (it) => {
    workOrder && await workOrderApi.addSparePartToWorkOrder({
      orderId: workOrder?.orderId,
      priceOne: it.priceOne as number,
      priceTotal: it.priceTotal as number,
      requestId: workOrder?.requestId,
      sparePartId: it.sparePartId,
      sparePartsCount: it.count as number,
    });
  });

  //update
  bindWork?.map(async (it) => {

    if(it?.rowId && updatedWorkId.includes(it?.serviceWorkId)) {
      await workOrderApi.updateWorkInWorkOrder({
        rowId: it.rowId,
        workCount: it.workCount as number,
        priceOne: it.priceOne as number,
        priceTotal: it.priceTotal as number,
      });
    }
  });
  bindSpareParts?.map(async (it) => {

    if(it?.rowId && updatedSparePartsId.includes(it?.sparePartId)) {
      await workOrderApi.updateSparePartInWorkOrder({
        rowId: it.rowId,
        priceTotal: it.priceTotal as number,
        priceOne: it.priceOne as number,
        sparePartsCount: it.count as number,
      });
    }
  });

  //update work order
  workOrder?.orderId && await workOrderApi.updateWorkOrder({
    orderId: workOrder?.orderId,
    carServiceId: payload.carServiceId,
    orderStatusId: payload.workOrderStatusId,
    dateStartPlane: `${getFormattedDate(payload.workOrderDate, 'yyyy-MM-dd')}T${getFormattedDate(payload.workOrderTime, 'HH:mm:ss')}`,
    employeeId: payload.employeeId,
    comment: payload.comment,
  });
});

const updateWorkOrderClientSP = createAsyncThunk<
    UpdClientSpWorkOrderRequest,
    UpdClientSpWorkOrderRequest,
    AsyncThunkConfig
  >(ActionType.UPDATE_CLIENT_SP_IN_WORK_ORDER, async (payload, { extra }) => {

    const { sparePartsApi, workOrderApi } = extra;
    const { clientSpareParts,  workOrder } = payload;

    const addSpareParts = clientSpareParts
      .filter((it) => Boolean(it.id))
      .map((it) => ({ ...it, requestId: workOrder.requestId, orderId: workOrder.orderId }));

    workOrder.sparePartsFromClient.forEach((it) => {
      if(clientSpareParts.every((item) => item.sparePartId !== it.sparePartId)) {
        clientSpareParts.push({ ...it, orderId: null });
      }
    });

    const updateSpareParts = clientSpareParts.filter((it) => Boolean(it.sparePartId));

    addSpareParts.length > 0 && await sparePartsApi.addClientSpareParts({
      sparePartFromClientList: addSpareParts,
    });

    updateSpareParts.length > 0 && await sparePartsApi.editClientSpareParts({
      sparePartFromClientList: updateSpareParts,
    });

    const res = await workOrderApi.getWorkOrderById({ orderId: workOrder.orderId });

    return {
      workOrder: payload.workOrder,
      clientSpareParts: res.sparePartsFromClient,
    };

  });

export {
  addWork,
  addWorkOrder,
  getWorkOrderById,
  getWorkOrdersByFilters,
  getWorkOrderWork,
  removeWork,
  updateWorkOrder,
  updateWorkOrderClientSP,
};
