import {
  AppTranslationKey, ClientSparePartsTranslationKey,
} from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getClientSparePartsRowTableColumns = (): TableColumn[] => [
  {
    key: 'sparePartName',
    label: t(`${AppTranslationKey.CLIENT_SPARE_PARTS}:${ClientSparePartsTranslationKey.NAME}`),
    _style: { width: '45%' },
  },
  {
    key: 'quantity',
    label: t(`${AppTranslationKey.CLIENT_SPARE_PARTS}:${ClientSparePartsTranslationKey.COUNT}`),
    _style: { width: '10%' },
  },
];

export { getClientSparePartsRowTableColumns };
