import { ClientSpareParts } from 'common/types/types';
import { CustomerSparePartsModal } from 'components/modals/modals';
import { useState } from 'hooks/hooks';
import { ReactElement } from 'react';

type Props = {
  onSaveClientSpareParts: (clientSpareParts: ClientSpareParts[]) => void,
};

type UseCustomerSparePartsModal = {
  getCustomerSparePartsModal: (onSaveClientSpareParts: Props) => ReactElement,
  toggleCustomerSparePartsModal: (isVisible: boolean) => void,
};

const useCustomerSparePartsModal = (
  bindSpareParts: ClientSpareParts[],
  orderId: number | null,
  alwaysEdit?: boolean,
): UseCustomerSparePartsModal => {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleCustomerSparePartsModal = (isVisible: boolean): void => {
    setIsOpen(isVisible);
  };
  const handleCloseModal = (): void => {
    toggleCustomerSparePartsModal(false);
  };

  const getCustomerSparePartsModal = ({ onSaveClientSpareParts }: Props): ReactElement => {

    return <CustomerSparePartsModal
      onSaveSpareParts={onSaveClientSpareParts}
      orderId={orderId}
      isVisible={isOpen}
      alwaysEdit={alwaysEdit}
      onClose={handleCloseModal}
      bindSpareParts={bindSpareParts}
    />;
  };

  return { getCustomerSparePartsModal, toggleCustomerSparePartsModal };
};

export { useCustomerSparePartsModal };
