import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { StorageDto } from 'common/types/types';

import { createStorage, getStocksByUserId, getStorageById, updateStorageById } from './actions';

type State = {
  stocks: StorageDto[],
  stock: StorageDto | null,
  getStocksByUserIdDataStatus: DataStatus,
  modifyStorageDataStatus: DataStatus,
  getStorageDataStatus: DataStatus,
};

const initialState: State = {
  stocks: [],
  stock: null,
  getStocksByUserIdDataStatus: DataStatus.IDLE,
  modifyStorageDataStatus: DataStatus.IDLE,
  getStorageDataStatus: DataStatus.IDLE,
};

const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {},
  extraReducers: ( builder ) => {
    builder
      // get storage by user id
      .addCase(getStocksByUserId.pending, (state) => {
        state.getStocksByUserIdDataStatus = DataStatus.PENDING;
      })
      .addCase(getStocksByUserId.rejected, (state) => {
        state.getStocksByUserIdDataStatus = DataStatus.REJECTED;
      })
      .addCase(getStocksByUserId.fulfilled, (state, { payload }) => {
        state.getStocksByUserIdDataStatus = DataStatus.FULFILLED;
        state.stocks = payload;
      })

      //create storage
      .addCase(createStorage.pending, (state) => {
        state.modifyStorageDataStatus = DataStatus.PENDING;
      })
      .addCase(createStorage.rejected, (state) => {
        state.modifyStorageDataStatus = DataStatus.REJECTED;
      })
      .addCase(createStorage.fulfilled, (state, { payload })=> {
        state.modifyStorageDataStatus = DataStatus.FULFILLED;
        state.stocks.push(payload);
      })

      //update storage
      .addCase(updateStorageById.pending, (state) => {
        state.modifyStorageDataStatus = DataStatus.PENDING;
      })
      .addCase(updateStorageById.rejected, (state) => {
        state.modifyStorageDataStatus = DataStatus.REJECTED;
        state.stock = null;
      })
      .addCase(updateStorageById.fulfilled, (state, { payload }) => {
        state.modifyStorageDataStatus = DataStatus.FULFILLED;
        state.stock = payload;
      })

      //get storage by storage id
      .addCase(getStorageById.pending, (state) => {
        state.getStorageDataStatus = DataStatus.PENDING;
      })
      .addCase(getStorageById.rejected, (state) => {
        state.getStorageDataStatus = DataStatus.REJECTED;
        state.stock = null;
      })
      .addCase(getStorageById.fulfilled, (state, { payload }) => {
        state.getStorageDataStatus = DataStatus.FULFILLED;
        state.stock = payload;
      });

  },
});
const stockReducer = stockSlice.reducer;

export { stockReducer };
