import { CCol, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeFormData } from 'common/types/types';
import { useAppSelector } from 'hooks/hooks';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<EmployeeFormData>,
  errors: FieldErrors<EmployeeFormData>,
  isDisable: boolean,
};

const StatusField: FC<Props> = ({ register, errors, isDisable }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const employeeStatuses = useAppSelector(({ dictionary }) => dictionary.data?.employeeStatuses || []);
  const componentProps = register(
    'statusId',
    {
      required: t(EmployeesProfilesKey.REQUIRED_FORM_FIELD) ?? 'This field is required',
      validate: (value) => value > -1 || t(EmployeesProfilesKey.REQUIRED_FORM_FIELD) as string,
    },
  );

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="status" className="col-sm-3 col-form-label">
        { `${ t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.STATUS }`) }*` }
      </CFormLabel>
      <CCol sm={9}>
        <CFormSelect
          { ...componentProps }
          id="status"
          invalid={Boolean(errors.statusId)}
          feedbackInvalid={errors.statusId?.message || ''}
          disabled={ isDisable }
        >
          {
            employeeStatuses.map(({ id, employeeStatusName }) => (
              <option key={id} value={id}>{employeeStatusName}</option>
            ))
          }
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export { StatusField };
