enum RecoveryPasswordTranslationKey {
  TITLE_RECOVERY_PASSWORD = 'titleRecoveryPassword',
  SUBTITLE_RECOVERY_PASSWORD = 'subtitleRecoveryPassword',
  PLACEHOLDER_RECOVERY_PASSWORD = 'placeholderRecoveryPassword',
  EMAIL_ERROR_TEXT = 'emailErrorText',
  RECOVERY_PASS_BUTTON_TEXT = 'recoveryPassButtonText',
  WELCOME_TEXT = 'welcomeText',
  RECOVERY_PASS_TEXT = 'recoveryPassText',
  BACK_BUTTON_TEXT = 'backButtonText',
  CONFIRM_MSG = 'confirmMsg',
  BACK_TO_SIGN_IN = 'backToSignIn',
  REQUEST_ERROR_MSG = 'requestErrorMsg',
  CHANGE_PASSWORD_TITLE_TEXT = 'changePasswordTitleText',
  CHANGE_PASSWORD_SUBTITLE_TEXT = 'changePasswordSubtitleText',
  CHANGE_PASSWORD_PLACEHOLDER = 'changePasswordPlaceholder',
  CHANGE_PASSWORD_BUTTON_TEXT = 'changePasswordButtonText',
  NEW_PASSWORD_ERROR_TEXT = 'passwordErrorText',
  ANY_DIFFICULTY = 'anyDifficulty',
  CALL_ME = 'callMe',
  CHANGE_PASS_TEXT = 'changePassText',
  ANY_QUESTION = 'anyQuestion',
}

export { RecoveryPasswordTranslationKey };
