import { WorkOrderFormData } from 'common/types/types';
import {
  currentDateTimeWithZeroMinutes,
} from 'helpers/helpers';

const DEFAULT_WORK_ORDER_MODAL_PAYLOAD: WorkOrderFormData = {
  workOrderStatusId: 1,
  workOrderTime: currentDateTimeWithZeroMinutes(),
  workOrderDate: currentDateTimeWithZeroMinutes(),
  order: '',
  carServiceId: null,
  comment: '',
  employeeId: -1,
};

export { DEFAULT_WORK_ORDER_MODAL_PAYLOAD };
