import { LocalStorageKey,Pages } from 'common/enums/enums';
import { RootState } from 'common/types/types';

interface IStatus {
  id: number,
  name: string,
}

interface IFilterSettingsItem {
  storageName: LocalStorageKey,
  selectorStatuses: (state: RootState) => IStatus[],
}

interface IFilterSettings {
  [Pages.ORDERS] : IFilterSettingsItem,
  [Pages.WORK_ORDERS]: IFilterSettingsItem,
}

const settings: IFilterSettings = {
  [Pages.ORDERS]: {
    storageName: LocalStorageKey.ORDER_FILTERS,
    selectorStatuses: (state) => state.dictionary.data?.requestStatuses || [],
  },
  [Pages.WORK_ORDERS]: {
    storageName: LocalStorageKey.WORK_ORDER_FILTERS,
    selectorStatuses: (state) => state.dictionary.data?.orderStatuses || [],
  },
};

export { settings };
