import {
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalHeader, CModalTitle,
  CRow,
} from '@coreui/react-pro';
import { AppTranslationKey, CarWorkTranslationKey, DataStatus } from 'common/enums/enums';
import { CarWorkFavModalFormData, CarWorkFavTableRow } from 'common/types/types';
import { ModalFooter } from 'components/modals/components/components';
import { TextArea } from 'components/textarea/text-area';
import { useAppSelector, useEffect } from 'hooks/hooks';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DEFAULT_CAR_WORK_FAV_MODAL_PAYLOAD } from './common';

type Props = {
  isOpen: boolean,
  favCarWork: CarWorkFavTableRow| null,
  onCloseModal: () => void,
  onSubmit: (payload: CarWorkFavModalFormData) => void,
};

const FavCarWorkModalForm: FC<Props> = ({ isOpen, onCloseModal, favCarWork, onSubmit }) => {

  const { updateFavCarWorkDataStatus } = useAppSelector((state) => state.carWork);
  const { t } = useTranslation(AppTranslationKey.CAR_WORK);
  const {
    reset,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<CarWorkFavModalFormData>({
    defaultValues: DEFAULT_CAR_WORK_FAV_MODAL_PAYLOAD,
  });

  const watchServiceWorkInfo = watch('serviceWorkInfo');

  const isUpdating = updateFavCarWorkDataStatus === DataStatus.PENDING;

  useEffect(() => {
    if(favCarWork) {
      reset({
        serviceWorkName: favCarWork.carWorkName,
        serviceWorkTypeName: favCarWork.carWorkType,
        requiredTimeMinutes: favCarWork.time,
        serviceWorkCost: favCarWork.cost,
        serviceWorkInfo: favCarWork.info,
        serviceWorkId: favCarWork.serviceWorkId,
        serviceWorkTypeId: favCarWork.serviceWorkTypeId,
        carServiceId: favCarWork.carServiceId,
      });
    } else {
      reset(DEFAULT_CAR_WORK_FAV_MODAL_PAYLOAD);
    }
  }, [favCarWork, reset]);

  return (
    <CModal
      visible={isOpen}
      backdrop="static"
      onClose={onCloseModal}
      size="lg"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>
          {t(CarWorkTranslationKey.EDIT_CAR_WORK_MODAL_TITLE)}
        </CModalTitle>
      </CModalHeader>
      <CForm className="form-scroll" onSubmit={handleSubmit(onSubmit)}>
        <CModalBody>
          <CRow className="mb-3">
            <CFormLabel htmlFor="serviceWorkName" className="col-sm-3 col-form-label">
              {t(CarWorkTranslationKey.FAV_CAR_WORK_NAME)}
            </CFormLabel>
            <CCol sm={9}>
              <CFormInput
                { ...register('serviceWorkName') }
                id="serviceWorkName"
                name="serviceWorkName"
                readOnly
                plainText
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel htmlFor="serviceWorkTypeName" className="col-sm-3 col-form-label">
              {t(CarWorkTranslationKey.FAV_CAR_WORK_TYPE)}
            </CFormLabel>
            <CCol sm={9}>
              <CFormInput
                {...register('serviceWorkTypeName')}
                id="serviceWorkTypeName"
                name="serviceWorkTypeName"
                readOnly
                plainText
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel htmlFor="requiredTimeMinutes" className="col-sm-3 col-form-label">
              {t(CarWorkTranslationKey.FAV_CAR_WORK_TIME)}
            </CFormLabel>
            <CCol sm={9}>
              <CFormInput
                {...register('requiredTimeMinutes',
                  {
                    min: {
                      value: 0,
                      message: t(CarWorkTranslationKey.VALUE_LESS_ZERO),
                    },
                  },
                )}
                id="requiredTimeMinutes"
                name="requiredTimeMinutes"
                type="number"
                invalid={Boolean(errors.requiredTimeMinutes)}
                feedbackInvalid={errors.requiredTimeMinutes?.message}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel htmlFor="serviceWorkCost" className="col-sm-3 col-form-label">
              {t(CarWorkTranslationKey.FAV_CAR_WORK_COST)}
            </CFormLabel>
            <CCol sm={9}>
              <CFormInput
                {...register('serviceWorkCost',
                  {
                    min: {
                      value: 0,
                      message: t(CarWorkTranslationKey.VALUE_LESS_ZERO),
                    },
                    pattern: {
                      value: /^(?![0.]+$)\d+(\.\d{1,2})?$/,
                      message: t(CarWorkTranslationKey.INVALID_NUMBER),
                    },
                  })}
                id="serviceWorkCost"
                name="serviceWorkCost"
                type="text"
                invalid={Boolean(errors.serviceWorkCost)}
                feedbackInvalid={errors.serviceWorkCost?.message}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel htmlFor="serviceWorkInfo" className="col-sm-3 col-form-label">
              {t(CarWorkTranslationKey.FAV_CAR_WORK_INFO)}
            </CFormLabel>
            <CCol sm={9}>
              <TextArea<CarWorkFavModalFormData>
                id="serviceWorkInfo"
                register={ register }
                limitSymbols={ 1024 }
                rows={3}
                nameFiled="serviceWorkInfo"
                value={ watchServiceWorkInfo }
                disabled={ false }
              />
            </CCol>
          </CRow>
        </CModalBody>
        <ModalFooter
          isReadOnly={false}
          isUpdateData={isUpdating}
          onEditButtonClick={
            (): void => {
            //do nothing
            }}
          onClose={onCloseModal}/>
      </CForm>
    </CModal>
  );
};

export { FavCarWorkModalForm };
