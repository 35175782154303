import { CarWorkFav, SelectedWorks } from 'common/types/types';

const carWorkFavToSelectedWorks = (carWorkFav: CarWorkFav): SelectedWorks => {
  return {
    rowId: carWorkFav.rowId,
    workId: carWorkFav.serviceWorkId,
    name: carWorkFav.serviceWorkName,
    workOrder: '',//<==== наряд
    price: (carWorkFav.serviceWorkCost)?.toString() ?? '0',
    qty: '1',
    sum: (carWorkFav.serviceWorkCost)?.toFixed(4) ?? '0',
  };
};

export { carWorkFavToSelectedWorks };
