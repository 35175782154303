import { CButton } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { SparePartsInOrder } from 'common/types/types';
import { TableField } from 'components/common/table-field/table-field';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { workOrderAction } from 'store/actions';

import { getSparePartsFieldTableColumns } from './helpers/helpers';

type Props = {
  toggleWorkOrderModal: (isOpen: boolean) => void,
  totalLabel?: React.ReactElement,
};

const SparePartsField: FC<Props> = ({ toggleWorkOrderModal, totalLabel }) => {

  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE_ORDER);
  const dispatch = useAppDispatch();
  const {
    spareParts,
  } = useAppSelector(({ task }) => ({
    spareParts: task.order?.spares ?? [],
  }));

  const handleOrderNumberClick = (workOrder: number): void => {
    dispatch(workOrderAction.getWorkOrderById({ orderId: workOrder }));
    toggleWorkOrderModal(true);
  };

  const columns = getSparePartsFieldTableColumns();

  const handleRemoveClick = (): void => {
    //do nothing
  };

  return <TableField
    isDisable={true}
    onRemoveClick={handleRemoveClick}
    columns={ columns }
    data={ spareParts }
    noDataMsg={t('orderNoSpareParts') ?? 'The order has not spare parts'}
    totalLabel={ totalLabel }
    scopedColumns={{
      orderId: (item: SparePartsInOrder) => (
        <td>
          <CButton
            color="link"
            size={'sm'}
            className="p-0"
            onClick={():void => handleOrderNumberClick(item.orderId) }
          >
            { item.orderId }
          </CButton>
        </td>
      ),
    }}
  />;
};

export { SparePartsField };
