import 'react-phone-input-2/lib/style.css';

import { AppTranslationKey } from 'common/enums/enums';
import { ChangePasswordModalContextProvider } from 'components/modals/modals';
import { getFormattedDate } from 'helpers/helpers';
import { useMemo } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangePasswordButton } from './change-password-button';
import styles from './styles.module.scss';
import { UserNameValue } from './user-name-value';
import { UserPhoneValue } from './user-phone-value';

type Props = {
  userId: number,
  username: string,
  name: string,
  phone: string,
  registrationDateTime?: string,
};

const UserInfoBlock: FC<Props> = ({ userId, username, name, phone, registrationDateTime }) => {
  const { t } = useTranslation(AppTranslationKey.PROFILE);

  const registrationDate = useMemo(() => {
    return registrationDateTime ? getFormattedDate(registrationDateTime, 'dd.MM.yyyy') : t('noData');
  }, [registrationDateTime, t]);

  return (
    <div className={styles.profileDataBlock}>
      <div className={styles.profileDataRow}>
        <span className={styles.profileDataLabel}>{t('login')}</span>
        <span className={styles.profileDataValue}>{username}</span>
      </div>
      <div className={styles.profileDataRow}>
        <span className={styles.profileDataLabel}>{t('name')}</span>
        <UserNameValue userId={userId} name={name}/>
      </div>
      <div className={styles.profileDataRow}>
        <span className={styles.profileDataLabel}>{t('dateProfile')}</span>
        <span className={styles.profileDataValue}>{registrationDate}</span>
      </div>
      <div className={styles.profileDataRow}>
        <span className={styles.profileDataLabel}>{t('phone')}</span>
        <UserPhoneValue userId={userId} phone={phone} />
      </div>
      <div className={styles.profileDataRow}>
        <ChangePasswordModalContextProvider userId={ userId }>
          <ChangePasswordButton />
        </ChangePasswordModalContextProvider>
      </div>
    </div>
  );
};

export { UserInfoBlock };
