import { cilX } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { AppColor } from 'common/enums/enums';
import React, { FC } from 'react';

type Props = {
  colorCode: string,
  size?: number,
};

const ColorItem: FC<Props> = ({ colorCode, size = 25 }) => {

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 3,
      backgroundColor: colorCode,
      width: size,
      height: size,
    }}>
      {
        (colorCode === AppColor.CAR_COLOR.UNKNOWN) ? <CIcon icon={cilX} height={25}/> : null
      }
    </div>
  );
};

export { ColorItem };
