import React, { createContext, FC, useContext } from 'react';

import { usePermissionToast } from './use-permission-toast';

type PermissionToastContextType = ReturnType<typeof usePermissionToast>;

const PermissionToastContext = createContext<PermissionToastContextType | null>(null);

const usePermissionToastContext = (): PermissionToastContextType => {
  const context = useContext(PermissionToastContext);

  if (!context) {
    throw new Error('usePermissionToastContext must be used within the PermissionToastContext');
  }

  return context;
};

interface IProps {
  children: React.ReactNode,
}

const PermissionToastContextProvider: FC<IProps> = ({ children }) => (
  <PermissionToastContext.Provider value={ usePermissionToast() }>
    { children }
  </PermissionToastContext.Provider>
);

export { PermissionToastContextProvider,usePermissionToastContext };
