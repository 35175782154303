import { ModalFormMode, ModalFormType } from 'common/enums/enums';
import { WorkOrderModal } from 'components/modals/modals';
import { useAppDispatch, useAppSelector, useState } from 'hooks/hooks';
import React, { ReactElement } from 'react';
import { disableReadOnlyModalMode, enabledReadOnlyModalMode } from 'store/modal/reducer';

type Props = {
  activeCarServiceId: number,
};

type UseWorkOrderModal = {
  getWorkOrderModal: (payload: Props) => ReactElement,
  toggleWorkOrderModal: (isVisible: boolean, isReadOnly?: boolean) => void,
};
const useWorkOrderModal = (): UseWorkOrderModal => {

  const {
    workOrderModalFormMode,
  } = useAppSelector(({ modal }) => ({
    workOrderModalFormMode: modal.modalFormMode?.[ModalFormType.WORK_ORDER_MODAL] ?? ModalFormMode.READONLY_MODE,
  }));
  const [ isWorkOrderModalOpen, setIsWorkOrderModalOpen ] = useState<boolean>(false);
  const isWorkOrderModalReadOnly = workOrderModalFormMode === ModalFormMode.READONLY_MODE;
  const dispatch = useAppDispatch();

  const getWorkOrderModal = ({ activeCarServiceId }: Props): ReactElement => {
    return <WorkOrderModal
      isOpen={isWorkOrderModalOpen}
      isReadOnly={isWorkOrderModalReadOnly}
      onCloseModal={():void => setIsWorkOrderModalOpen(false)}
      carServiceId={activeCarServiceId}
    />;
  };

  const toggleWorkOrderModal = (isVisible: boolean, isReadOnly = true): void => {
    dispatch(isReadOnly ?
      enabledReadOnlyModalMode(ModalFormType.WORK_ORDER_MODAL) :
      disableReadOnlyModalMode(ModalFormType.WORK_ORDER_MODAL));
    setIsWorkOrderModalOpen(isVisible);
  };

  return { getWorkOrderModal, toggleWorkOrderModal };
};

export { useWorkOrderModal };
