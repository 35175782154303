import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { Item } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey, WorkOrderTranslationKey } from 'common/enums/enums';
import { TableField } from 'components/common/table-field/table-field';
import { CarWorkTableRow } from 'components/modals/work-order-modal/type/type';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getCarWorkRowTableColumns } from './helpers/helpers';

type Props = {
  isDisable?: boolean,
  carWork: CarWorkTableRow[],
  onSelectClick?: () => void,
  onRemoveWorkItem: (workId: number) => void,
  totalLabel?: React.ReactElement,
};

const WorkRow: FC<Props> = ({
  isDisable = false ,
  carWork,
  onSelectClick,
  onRemoveWorkItem,
  totalLabel,
}) => {
  const { t } = useTranslation(AppTranslationKey.WORK_ORDER);
  const columns = getCarWorkRowTableColumns();

  const handleRemoveClick = (item:  Item): void =>  {
    onRemoveWorkItem(item.workId);
  };

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="problemDescription" className="col-sm-2 col-form-label">
        {t(WorkOrderTranslationKey.WORK_ORDER_DETAILS_WORK_TITLE)}
      </CFormLabel>
      <CCol sm={10}>
        <TableField
          noDataMsg=
            {t(`${WorkOrderTranslationKey.WORK_ORDER_NO_WORK}`,
              { ns: AppTranslationKey.WORK_ORDER }) ?? undefined}
          columns={columns}
          data={carWork}
          onRemoveClick={handleRemoveClick}
          isDisable={isDisable}
          onSelectClick={onSelectClick}
          totalLabel={ totalLabel }
        />
      </CCol>
    </CRow>
  );
};

export { WorkRow };
