import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react-pro';
import MDEditor from '@uiw/react-md-editor';
import { AppTranslationKey, GdrpTranslationKey } from 'common/enums/enums';
import { useAppDispatch, useAppSelector, useEffect } from 'hooks/hooks';
import { i18next } from 'i18n';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { confidentialTextAction } from 'store/actions';

import styles from './style.module.scss';

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
};
const GdprModal: FC<Props> = ({ onCloseModal, isOpen }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.GDRP_MODAL);

  const { title, text } = useAppSelector(({ confidentialText }) => confidentialText.confidentialText);

  useEffect(() => {
    dispatch(confidentialTextAction.getConfidentialText({ textId: 1 }));
  }, [i18next.language]);

  return (
    <CModal
      visible={isOpen}
      backdrop="static"
      onClose={onCloseModal}
      size="lg"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>
          {title}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <MDEditor.Markdown
          className={styles.MDEditor} source={text} />
      </CModalBody>
      <CModalFooter className="justify-content-center">
        <CButton
          className="w-25"
          variant="outline"
          color="primary"
          onClick={onCloseModal}
        >
          {t(GdrpTranslationKey.CLOSE_BUTTON_CAPTION)}
        </CButton>
      </CModalFooter>
    </CModal>
  );

};

export { GdprModal };
