import { SupplierAddRequestDto } from 'common/types/types';

const DEFAULT_EDIT_SUPPLIER_PAYLOAD: SupplierAddRequestDto = {
  counterpartyStatusId: 1,
  counterpartyName: '',
  address: '',
  counterpartyInfo: '',
};

export { DEFAULT_EDIT_SUPPLIER_PAYLOAD };
