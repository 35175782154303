import { CCollapse } from '@coreui/react-pro';
import React, { ReactElement } from 'react';

import { CarDetails } from './car-details';

type CarRawDetails = {
  visible: boolean,
  carBodyTypeName: string,
  createYear: number,
  mileageKm: number,
  carInfo: string,
  capacity: number | null,
  handleViewEditTaskClick: (readOnly: boolean ) => void
};
const renderCarItemDetails = (item: CarRawDetails): ReactElement => {
  return (
    <CCollapse visible={item.visible}>
      <CarDetails
        carBodyTypeName={item.carBodyTypeName}
        createYear={item.createYear}
        mileageKm={String(item.mileageKm ?? '---').replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')}
        carInfo={item.carInfo}
        capacity={item.capacity}
        onViewEditOrderClick={(readOnly): void => {
          item.handleViewEditTaskClick(readOnly);
        }}
      />
    </CCollapse>
  );
};

export { renderCarItemDetails };
