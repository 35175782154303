import { SparePartsBindToWorkOrder, UserSpareParts } from 'common/types/types';

const userSparePartToBindSpareParts = (userSparePart: UserSpareParts): SparePartsBindToWorkOrder => (
  {
    rowId: userSparePart.rowId ?? null,
    priceOne: userSparePart.priceSell,
    sparePartId: userSparePart.sparePartsId,
    sparePartName: userSparePart.sparePartsName,
    priceTotal: userSparePart.priceSell,
    count: 1,
  }
);

export { userSparePartToBindSpareParts };
