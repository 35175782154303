import { CarServiceProfileFormData, CarServiceRequisites } from 'common/types/types';

const getListRequisitesFromFormData = (data: CarServiceProfileFormData): CarServiceRequisites[] => {

  if (
    data.requisites !== null &&
    Object.values(data.requisites).some((it) => it !== null)
  ) {
    return [{ ...data.requisites, flMain: true }];
  }

  return [];
};

export { getListRequisitesFromFormData };
