import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppTranslationKey, AuthTranslationKey, LocalStorageKey, NotificationType } from 'common/enums/enums';
import {
  AsyncThunkConfig,
  CurrentUserDto,
  InactiveUserResponse,
  LogoutRequestDto,
  LogoutResponseDto, PlainResponse,
  RecoveryPasswordRequest,
  RefreshTokenRequestDto,
  RefreshTokenResponseDto,
  ResendValidateRequest,
  SetPasswordRequest,
  SetPasswordResponse,
  UserDto,
  UserSignInRequestDto,
  UserSignInResponseDto,
  UserSignUpRequestDto,
  UserSignUpResponseDto,
  ValidateUserRequest,
} from 'common/types/types';
import { i18next } from 'i18n';
import { appActions } from 'store/actions';

import { ActionType } from './common';

const signIn = createAsyncThunk<
  UserSignInResponseDto | InactiveUserResponse,
  UserSignInRequestDto,
  AsyncThunkConfig
  >(ActionType.SIGN_IN, async (payload, { extra }) => {
    const { authApi, storage } = extra;
    const response = await authApi.signIn(payload);

    if ('access_token' && 'refresh_token' in response) {
      storage.setItem(LocalStorageKey.TOKEN, response.access_token);
      storage.setItem(LocalStorageKey.REFRESH_TOKEN, response.refresh_token);
      storage.removeItem(LocalStorageKey.DICTIONARIES_ENG);
      storage.removeItem(LocalStorageKey.DICTIONARIES_UK);
    }

    return response;
  });

const signUp = createAsyncThunk<
  UserSignUpResponseDto | PlainResponse,
  UserSignUpRequestDto,
  AsyncThunkConfig
  >(ActionType.SIGN_UP, async (payload,
    { extra }) => {
    const { authApi } = extra;

    return authApi.signUp(payload);
  });

const getCurrentUser = createAsyncThunk<
  CurrentUserDto,
  void,
  AsyncThunkConfig
>(ActionType.GET_CURRENT_USER, async (_payload,
  { extra }) => {
  const { authApi, storage } = extra;

  const token = storage.getItem(LocalStorageKey.TOKEN) ?? '';

  return authApi.getCurrentUser(token);
});

const refreshToken = createAsyncThunk<
  RefreshTokenResponseDto,
  RefreshTokenRequestDto,
  AsyncThunkConfig
>(ActionType.REFRESH_TOKEN, async (payload, { extra }) => {
  const { authApi, storage } = extra;
  const response = await authApi.refreshToken(payload);
  storage.setItem(LocalStorageKey.TOKEN, response.access_token);
  storage.setItem(LocalStorageKey.REFRESH_TOKEN, response.refresh_token);

  return response;
});

const logOut = createAsyncThunk<
  LogoutResponseDto,
  void,
  AsyncThunkConfig
>( ActionType.LOG_OUT, async (_, { extra }) => {
  const { authApi, storage } = extra;
  const payload: LogoutRequestDto = {
    access_token: storage.getItem(LocalStorageKey.TOKEN) ?? '',
    refresh_token: storage.getItem(LocalStorageKey.REFRESH_TOKEN) ?? '',
  };
  storage.removeItem(LocalStorageKey.TOKEN);
  storage.removeItem(LocalStorageKey.REFRESH_TOKEN);
  storage.removeItem(LocalStorageKey.ACTIVE_CAR_SERVICE_TAB);
  storage.removeItem(LocalStorageKey.ACTIVE_STORAGE_TAB);

  return authApi.logout(payload);
});

const recoveryPasswordSendEmail = createAsyncThunk<
  void,
  RecoveryPasswordRequest,
  AsyncThunkConfig
>( ActionType.RECOVERY_PASSWORD, async (payload, { extra }) => {
  const { authApi } = extra;

  return authApi.recoveryPasswordSendEmail(payload);
});

const changePassword = createAsyncThunk<
  SetPasswordResponse,
  SetPasswordRequest,
  AsyncThunkConfig
>( ActionType.SET_PASSWORD, async (payload, { extra }) => {
  const { authApi } = extra;

  return authApi.changePassword(payload);
});

const validateUser = createAsyncThunk<
  UserDto,
  ValidateUserRequest,
  AsyncThunkConfig
>( ActionType.VALIDATE_USER, async (payload, { extra }) => {
  const { authApi } = extra;

  return authApi.validateUser(payload);
});

const resendValidation = createAsyncThunk<
  void,
  ResendValidateRequest,
  AsyncThunkConfig
>(ActionType.RESEND_VALIDATION, async (payload, { extra, dispatch }) => {
  const { authApi } = extra;
  await authApi.resendValidation(payload);
  dispatch(appActions.notify(
    {
      type: NotificationType.SUCCESS,
      message: i18next.t(`${AppTranslationKey.AUTH}:${AuthTranslationKey.RESEND_VALIDATION_SUCCESS}`) },
  ));
});

export {
  changePassword,
  getCurrentUser,
  logOut,
  recoveryPasswordSendEmail,
  refreshToken,
  resendValidation,
  signIn,
  signUp,
  validateUser,
};
