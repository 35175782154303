import { ApiPath, ContentType, HttpMethod, TariffsApiPath } from 'common/enums/enums';
import { TariffsCreatePaymentDto,TariffsCreatePaymentRequestDto } from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class TariffsApiService {
  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public createPayment(payload: TariffsCreatePaymentRequestDto): Promise<TariffsCreatePaymentDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.PAYMENTS}${TariffsApiPath.CREATE}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }
}

export { TariffsApiService };
