enum ErrorPageTranslationKey {
  TITLE = 'title',
  BACK_TO_MAIN = 'backToMain',
  MSG = 'message',
  CONTACT_US_TEXT = 'contactUsText',
  CONTACT_US_LINK_TEXT = 'contactUsLinkText',

}

export { ErrorPageTranslationKey };
