enum OrderCertificateKey {
  PAGE_TITLE = 'pageTitle',
  TITLE = 'title',
  PERFORMER_SIDE_TITLE = 'performerSideTitle',
  CUSTOMER_SIDE_TITLE = 'customerSideTitle',
  NAME_SERVICE_STATION = 'nameServiceStation',
  ADDRESS_SERVICE_STATION = 'addressServiceStation',
  PHONE_SERVICE_STATION = 'phoneServiceStation',
  BRAND_CAR = 'brandCar',
  MODEL_CAR = 'modelCar',
  GOV_NUMBER_CAR = 'govNumberCar',
  COMPLETED_WORKS_TABLE_TITLE = 'completedWorksTableTitle',
  NAME_WORKS = 'nameWorks',
  QUANTITY = 'quantity',
  PRICE = 'price',
  SUM = 'sum',
  UNIT = 'unit',
  UAH = 'uah',
  TOTAL = 'total',
  SPARE_PARTS_TABLE_TITLE = 'sparePartsTableTitle',
  NAME_SPARE_PARTS = 'nameSpareParts',
  CODE = 'code',
  SPARE_PARTS_CLIENT_TABLE_TITLE = 'sparePartsClientTableTitle',
  TOTAL_TABLE_TITLE = 'totalTableTitle',
  TOTAL_PRICE_WORKS = 'totalPriceWorks',
  TOTAL_PRICE_SPARE_PARTS = 'totalPriceSpareParts',
  TOTAL_PRICE = 'totalPrice',
  FOOTER_TITLE = 'footerTittle',
  VEHICLE_CONFIRM = 'vehicleConfirm',
  CONDITIONS_CONFIRM = 'conditionsConfirm',
  PERFORMER_SIGN_TITLE = 'performerSignTitle',
  CUSTOMER_SIGN_TITLE = 'customerSignTitle',
  FULL_NAME = 'fullName',
  SIGNATURE = 'signature',
  DATE = 'date',
  DOWNLOAD_PDF = 'downloadPdf',
  BACK_BUTTON = 'backButton',
}

export { OrderCertificateKey };
