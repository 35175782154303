import {
  AppTranslationKey,
  DataStatus,
  ModalFormMode,
  ModalFormType,
  Pages,
  WorkOrderTranslationKey,
} from 'common/enums/enums';
import { WithCarServicesTabs } from 'common/types/types';
import { NoDataWithAction, Spinner, useFilterPanelContext, usePermissionToastContext } from 'components/common/common';
import { WorkOrderModal } from 'components/modals/modals';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts';
import { withCarServicesTabs } from 'hoc/hoc';
import { useAppDispatch, useAppSelector, useEffect, useOrderModal, useState } from 'hooks/hooks';
import { t } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { widgetActions, workOrderAction } from 'store/actions';
import { disableReadOnlyModalMode, enabledReadOnlyModalMode } from 'store/modal/reducer';
import { clearOpenWorkOrders } from 'store/widget/reducer';

import { WorkOrderTable } from './components/components';

const CarServicesWorkOrders: FC<WithCarServicesTabs> = ({ activeCarService }) => {

  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const { t, i18n } = useTranslation(AppTranslationKey.WORK_ORDER);
  const dispatch = useAppDispatch();
  const { getOrderModal, handleOpenTaskModal } = useOrderModal(activeCarService);
  const [ isWorkOrderModalOpen, setIsWorkOrderModalOpen ] = useState<boolean>(false);
  const {
    workOrders,
    workOrderModalFormMode,
    allWorkOrdersDataStatus,
    userId,
  } = useAppSelector(({ auth, workOrder, modal }) => ({
    workOrders: workOrder.workOrders,
    userId: auth.currentUser?.id ?? 0,
    allWorkOrdersDataStatus: workOrder.allWorkOrdersDataStatus,
    workOrderModalFormMode: modal.modalFormMode?.[ModalFormType.WORK_ORDER_MODAL] ?? ModalFormMode.READONLY_MODE,
  }));
  const { onPermissionAction } = usePermissionToastContext();
  const isWorkOrderModalReadOnly = workOrderModalFormMode === ModalFormMode.READONLY_MODE;
  const isLoadingWorkOrders = allWorkOrdersDataStatus === DataStatus.PENDING;

  const { getListItems, isInit, setIsInit } = useFilterPanelContext();

  const handleOpenWorkOrderModal = (): void => {
    setIsWorkOrderModalOpen(true);
  };
  const handledAddWorkOrder = (): void =>  {
    dispatch(disableReadOnlyModalMode(ModalFormType.WORK_ORDER_MODAL));
    handleOpenWorkOrderModal();
  };

  //enable Extra Button
  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.ADD_WORK_ORDER}`,
      action: onPermissionAction(handledAddWorkOrder, Tariffs.NO_TARIFF),
    });

    return () => {
      removeExtraButton();
    };
  }, [workOrders, i18n.language]);

  // fetch all work orders by filter
  useEffect(() => {
    if (isInit) {
      getListItems();
      setIsInit(false);
    }
  }, [ isInit ]);

  useEffect(() => {
    dispatch(widgetActions.getOpenWorkOrders({ userId }));

    return () => {
      dispatch(clearOpenWorkOrders());
    };
  }, [ dispatch, userId ]);

  const handleCloseModal = (): void => {
    setIsWorkOrderModalOpen(false);
    // dispatch(clearBindOrder());
  };

  if (isLoadingWorkOrders && workOrders.length === 0) return <Spinner isOverflow containerHeight={200} />;

  if (workOrders.length === 0 && !isWorkOrderModalOpen) {
    return <NoDataWithAction
      actionName={ t(WorkOrderTranslationKey.ADD_WORK_ORDER) }
      title={ t(WorkOrderTranslationKey.NO_WORK_ORDERS_TITLE) }
      message={ t(WorkOrderTranslationKey.NO_WORK_ORDERS_TEXT) }
      callback={ onPermissionAction(handledAddWorkOrder, Tariffs.NO_TARIFF) }
    />;

  }

  const handleViewEditWorkOrderClick = (orderId: number, readOnly: boolean): void => {
    dispatch(workOrderAction.getWorkOrderById({ orderId }));
    dispatch(readOnly ?
      enabledReadOnlyModalMode(ModalFormType.WORK_ORDER_MODAL) :
      disableReadOnlyModalMode(ModalFormType.WORK_ORDER_MODAL));
    handleOpenWorkOrderModal();
  };

  const handledWorkOrderNumberClick = (id: number): void =>  {
    handleViewEditWorkOrderClick(id, true);
  };

  const handledOrderNumberClick = (id: number): void => {
    handleOpenTaskModal(id);
  };

  return (
    <>
      <WorkOrderTable
        onWorkOrderNumberClick={handledWorkOrderNumberClick}
        onOrderNumberClick={handledOrderNumberClick}
        workOrders={workOrders}
        onViewEditWorkOrderClick={handleViewEditWorkOrderClick}
        isLoadingWorkOrders={isLoadingWorkOrders}
      />
      {getOrderModal()}
      <WorkOrderModal
        isOpen={isWorkOrderModalOpen}
        isReadOnly={isWorkOrderModalReadOnly}
        onCloseModal={handleCloseModal}
        carServiceId={activeCarService?.id ?? 0}
      />
    </>
  );
};

export const WorkOrdersCarServicesTabs = withCarServicesTabs(
  CarServicesWorkOrders,
  true,
  Pages.WORK_ORDERS,
  () => t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.TITLE}`) ?? '',
);
