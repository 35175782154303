import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { CarModalFormData } from 'common/types/types';
import { TextArea } from 'components/textarea/text-area';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<CarModalFormData>,
  disabled?: boolean,
  value: string,
};

const CarInfoField: FC<Props> = ({ disabled = false, register, value }) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="carInfo" className="col-sm-3 col-form-label">
        {t('carInfo')}
      </CFormLabel>
      <CCol sm={9}>
        <TextArea<CarModalFormData>
          id="carInfo"
          register={ register }
          limitSymbols={ 1024 }
          rows={3}
          nameFiled="carInfo"
          value={ value }
          disabled={ disabled }
        />
      </CCol>
    </CRow>
  );
};

export { CarInfoField };
