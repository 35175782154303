import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig, SendErrorRequest, SendErrorResponse } from 'common/types/types';

import { ActionType } from './common';

const sendError = createAsyncThunk<
  SendErrorResponse,
  SendErrorRequest,
  AsyncThunkConfig
>(ActionType.SEND_ERROR, async (payload, { extra }) => {
  const { errorHandlerApi } = extra;

  return errorHandlerApi.sendError(payload);
});

export { sendError };
