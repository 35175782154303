const AppColor = {
  UNKNOWN: '#00000000',
  // MSG_COLOR: {
  //   SUCCESS: '#237804',
  //   ERROR: '#A8071A',
  //   DEFAULT: '#6F7783',
  // },
  CAR_COLOR: {
    UNKNOWN: '#00000000',
  },
} as const;

export { AppColor };
