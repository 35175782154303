import { CCollapse } from '@coreui/react-pro';
import { ClientSpareParts, SparePartsBindToWorkOrder, WorkDto } from 'common/types/types';
import React, { ReactElement } from 'react';

import { WorkOrderDetails } from './work-order-details';

type WorkOrderRowDetails = {
  visible: boolean,
  work?: WorkDto[],
  spareParts: SparePartsBindToWorkOrder[],
  clientSpareParts: ClientSpareParts[],
  handleViewEditWorkOrderClick: (readOnly: boolean ) => void
};

const renderWorkOrderItemDetails = (item: WorkOrderRowDetails): ReactElement => {

  return (
    <CCollapse visible={item.visible}>
      <WorkOrderDetails
        spareParts={item.spareParts ?? []}
        clientSpareParts={item.clientSpareParts ?? []}
        work={item.work ?? []}
        onViewEditWorkOrderClick={(readOnly): void => {
          item.handleViewEditWorkOrderClick(readOnly);
        }}
      />
    </CCollapse>
  );
};

export { renderWorkOrderItemDetails };
