import { OrderDto, OrderTableRaw } from 'common/types/types';
import { getFormattedDate } from 'helpers/helpers';

const CAR_NAME_MAX_LENGTH = 20;
const PROBLEM_DESC_MAX_LENGTH = 20;

const getOrderTableData = (orders: OrderDto[], defPerformer: string): OrderTableRaw[] =>
  orders.map<OrderTableRaw>((item) => {
    const performer =
      `${item.employee.lastName ?? ''} ${item.employee.firstName ?? ''} ${item.employee.middleName ?? ''}`;
    const { carBrandName = '', carModelName = '' } = item?.car ?? {};
    const autoName = `${carBrandName} ${carModelName}`;

    return {
      id: item.id,
      auto: autoName?.length > CAR_NAME_MAX_LENGTH ? autoName?.slice(0, 17) + '...' : autoName ?? '',
      // dateTime: getFormattedDate(item.dateCarReceive, 'dd.MM.yyyy HH:mm') ?? '',
      dateCarReceive: getFormattedDate(item.dateCarReceive, 'yyyy-MM-dd HH:mm') ?? '',
      comment: item?.comment ?? '',
      statusName: item?.status.name ?? '',
      statusTextColor: item.status.colorFont,
      statusId: item.status.id,
      statusColor: item.status.color,
      problemDescription:
        item.problemDescription?.length > PROBLEM_DESC_MAX_LENGTH ?
          item.problemDescription?.slice(0, 17) + '...' :
          item.problemDescription ?? '',
      performer: performer.trim() || defPerformer,
      car: item.car,
      orderTask: item.works,
      spares: item.spares,
      clientSpares: item.sparePartsFromClient,
      totalSum: item.totalSum.toFixed(2),
      sumOrders: item.sumOrders.toFixed(2),
      sumWorksWithoutOrders: item.sumWorksWithoutOrders.toFixed(2),
    };
  });

export { getOrderTableData };
