import React, { createContext, FC, useContext } from 'react';

import { useSparePartsTable } from './use-spare-parts-table';

type SparePartsTableContextType = ReturnType<typeof useSparePartsTable>;

const SparePartsTableContext = createContext<SparePartsTableContextType | null>(null);

const useSparePartTableContext = (): SparePartsTableContextType => {
  const context = useContext(SparePartsTableContext);

  if (!context) {
    throw new Error('useSparePartTableContext must be used within the SparePartsTableContext');
  }

  return context;
};

interface IProps {
  children: React.ReactNode,
}

const SparePartsTableContextProvider: FC<IProps> = ({ children }) => (
  <SparePartsTableContext.Provider value={ useSparePartsTable() }>
    { children }
  </SparePartsTableContext.Provider>
);

export { SparePartsTableContextProvider, useSparePartTableContext };
