import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig, DictionaryDto } from 'common/types/types';

import { ActionType } from './common';

const getDictionaries = createAsyncThunk<
  DictionaryDto,
  number,
  AsyncThunkConfig
>(ActionType.GET_DICTIONARIES, async (userId, { extra }) => {
  const { dictionaryApi } = extra;

  return dictionaryApi.getDictionaries(userId);
});

export { getDictionaries };
