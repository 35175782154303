const compareDate = (a: Date, b: Date, state: string): number => {
  const dateA = a.getTime();
  const dateB = b.getTime();

  return state === 'asc' ? dateA - dateB : dateB - dateA;
};

const compareNumber = (a: number, b: number, state: string): number => {

  return state === 'asc' ? a - b : b - a;
};

const compareString = (a: string, b: string, state: string): number => {
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();

  if (nameA < nameB) {
    return state === 'asc' ? 1 : -1;
  }

  if (nameA > nameB) {
    return state === 'asc' ? -1 : 1;
  }

  return 0;
};

export { compareDate, compareNumber, compareString };
