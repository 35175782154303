import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { Item } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey, ClientSparePartsTranslationKey } from 'common/enums/enums';
import { ClientSpareParts } from 'common/types/types';
import { TableField } from 'components/common/table-field/table-field';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getClientSparePartsRowTableColumns } from './helpers/helper';

type Props = {
  isDisable: boolean,
  clientSpareParts: ClientSpareParts[],
  onRemoveClientSparePartItem: (sparePart: ClientSpareParts) => void,
  onSelectClick: () => void,
};

const WorkOrderClientSparePartsRow: FC<Props> = ({
  onSelectClick,
  clientSpareParts,
  onRemoveClientSparePartItem,
  isDisable,
}) => {

  const { t } = useTranslation(AppTranslationKey.CLIENT_SPARE_PARTS);
  const columns = getClientSparePartsRowTableColumns();

  const handleRemoveClick = (item: Item): void =>  {
    item.sparePartId && onRemoveClientSparePartItem(item as ClientSpareParts);
  };

  return <CRow className="mb-3">
    <CFormLabel className="col-sm-2 col-form-label">
      {t(ClientSparePartsTranslationKey.TITLE)}
    </CFormLabel>
    <CCol sm={10}>
      <TableField
        noDataMsg= { t(ClientSparePartsTranslationKey.NO_SPARE_PARTS) ?? undefined }
        columns={columns}
        data={clientSpareParts.map((it) => ({
          ...it,
          sparePartName: it.sparePartName.split(' ')[0].length > 50 ?
            it.sparePartName.slice(0, 47) + '...' :
            it.sparePartName,
        }))}
        onRemoveClick={handleRemoveClick}
        isDisable={isDisable}
        onSelectClick={onSelectClick}
      />
    </CCol>
  </CRow>;
};

export { WorkOrderClientSparePartsRow };
