import { CSmartPagination, CSmartTable } from '@coreui/react-pro';
import { ColumnFilterValue, Item } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { GetCarsDataRequestDto } from 'common/types/types';
import { NoData } from 'components/common/common';
import { useAppDispatch, useAppSelector, useEffect, useMemo, useState } from 'hooks/hooks';
import debounce from 'lodash/debounce';
import truncate from 'lodash/truncate';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { carActions } from 'store/actions';

import { columns } from './columns';

type Props = {
  onCheckCar: (items: Item[]) => void,
  selected: Item[],
  carServiceId?: number,
};

const CarTable: FC<Props> = ({ carServiceId, onCheckCar, selected }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.COMMON);
  const { carsData: { cars, totalPages }, dataStatusGetCars } = useAppSelector(({ car }) => car);
  const [activePage, setActivePage] = useState<number>(0);
  const [filters, setFilters] = useState<ColumnFilterValue>({});
  const debounceApplyFilters = useMemo(() => debounce((filter: ColumnFilterValue): void => {
    setFilters(filter);
  }, 500), []);

  const onChangePage = (page: number): void => {
    setActivePage(page - 1);
  };

  useEffect(() => {
    if(carServiceId) {
      const payload: GetCarsDataRequestDto = {
        carServiceId,
        page: activePage,
        size: 10,
        searchBrand: filters.carBrandName || '',
        searchModel: filters.carModelName || '',
        searchRegNum: filters.carRegNum || '',
        searchInfo: filters.carInfo || '',
      };

      dispatch(carActions.getCars(payload));
    }
  }, [carServiceId, activePage, filters, dispatch]);

  return (
    <>
      <CSmartTable
        columns={ columns }
        items={ cars }
        loading={ dataStatusGetCars === DataStatus.PENDING }
        itemsPerPage={ 10 }
        columnFilter={ { external: true } }
        onColumnFilterChange={ debounceApplyFilters }
        selectable
        noItemsLabel={<NoData title={t('noItemsFoundTitle')} message={t('noItemsFoundMsg')} />}
        selectAll={ false }
        onSelectedItemsChange={ onCheckCar }
        selected={ selected }
        scopedColumns={ {
          carInfo: (item: Item) => <td>{item.carInfo ? truncate(item.carInfo, { length: 20 }) : ''}</td>,
        } }
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CSmartPagination
          pages={ totalPages }
          activePage={ activePage + 1 }
          onActivePageChange={ onChangePage }
        />
      </div>
    </>
  );
};

export { CarTable };
