import {
  cilCarAlt,
  cilClipboard,
  cilCog,
  cilCreditCard,
  cilFactory,
  cilFeaturedPlaylist,
  cilInfo,
  cilLibrary,
  cilMemory,
  cilMoney,
  cilMovie,
  cilPeople,
  cilQrCode,
  cilSpeedometer,
  cilStorage,
  cilTouchApp,
  cilTruck,
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CNavGroup, CNavItem } from '@coreui/react-pro';
import { AppRoute, EmployeesRoute, HelpRoute, PersonalRoute, ServicesRoute } from 'common/enums/enums';
import React from 'react';

const config = [
  {
    component: CNavItem,
    name: 'dashboard',
    to: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.DASHBOARDS}`,
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    onClick: (): void => {
      const openItem: (HTMLElement | null) = document.querySelector('.nav-group.show a');

      if (openItem) {
        openItem.click();
      }
    },
  },
  {
    component: CNavGroup,
    name: 'myAutoServices',
    icon: <CIcon icon={cilCog} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'ordersNavItem',
        icon: <CIcon icon={ cilClipboard } customClassName="nav-icon" />,
        to: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.CAR_SERVICES_ORDERS}`,
      },
      {
        component: CNavItem,
        name: 'workOrders',
        icon: <CIcon icon={ cilFeaturedPlaylist } customClassName="nav-icon" />,
        to: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.WORK_ORDERS}`,
      },
      {
        component: CNavItem,
        name: 'carsNavItem',
        icon: <CIcon icon={ cilCarAlt } customClassName="nav-icon" />,
        to: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.CAR_SERVICES_AUTO}`,
      },
      {
        component: CNavItem,
        name: 'work',
        icon: <CIcon icon={ cilTouchApp } customClassName="nav-icon" />,
        to: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.WORK}`,
      },
      {
        component: CNavItem,
        name: 'carServicesNavItem',
        icon: <CIcon icon={ cilFactory } customClassName="nav-icon" />,
        to: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.CAR_SERVICES_PROFILES}`,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'storage',
    icon: <CIcon icon={cilStorage} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'parts',
        icon: <CIcon icon={ cilMemory } customClassName="nav-icon" />,
        to: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.SPARE_PARTS}`,
      },
      {
        component: CNavItem,
        name: 'suppliers',
        icon: <CIcon icon={ cilTruck } customClassName="nav-icon" />,
        to: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.SUPPLIERS}`,
      },
      {
        component: CNavItem,
        name: 'storageProfiles',
        icon: <CIcon icon={ cilFactory } customClassName="nav-icon" />,
        to: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.STORAGE_PROFILES}`,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'employees',
    icon: <CIcon icon={ cilPeople } customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'salary',
        icon: <CIcon icon={ cilMoney } customClassName="nav-icon" />,
        to: `${AppRoute.EMPLOYEES}${EmployeesRoute.ROOT}${EmployeesRoute.SALARY}`,
      },
      {
        component: CNavItem,
        name: 'employeesProfiles',
        icon: <CIcon icon={ cilFactory } customClassName="nav-icon" />,
        to: `${AppRoute.EMPLOYEES}${EmployeesRoute.ROOT}${EmployeesRoute.PROFILES}`,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'services',
    icon: <CIcon icon={ cilQrCode } customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'infoVin',
        icon: <CIcon icon={ cilInfo } customClassName="nav-icon" />,
        to: `${AppRoute.SERVICES}${ServicesRoute.ROOT}${ServicesRoute.INFO_VIN}`,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'help',
    icon: <CIcon icon={ cilLibrary } customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'videoReview',
        icon: <CIcon icon={ cilMovie } customClassName="nav-icon" />,
        to: `${AppRoute.HELP}${HelpRoute.ROOT}${HelpRoute.VIDEO_REVIEW}`,
      },
      {
        component: CNavItem,
        name: 'tariffs',
        icon: <CIcon icon={ cilCreditCard } customClassName="nav-icon" />,
        to: `${AppRoute.HELP}${HelpRoute.ROOT}${HelpRoute.TARIFFS}`,
      },
      {
        component: CNavItem,
        name: 'allAboutRequest',
        icon: <CIcon icon={ cilClipboard } customClassName="nav-icon" />,
        to: `${AppRoute.HELP}${HelpRoute.ROOT}${HelpRoute.ABOUT_REQUEST}`,
      },
    ],
  },
];

export { config };
