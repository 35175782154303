import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppTranslationKey } from 'common/enums/enums';
import {
  AsyncThunkConfig, SparePartsByStockIdRequest,
  SparePartsByUserIdRequest,
  SparePartsCreateRequestDto,
  SparePartsDto,
  SparePartsGetListDto,
  SparePartsGetListRequestDto,
  UserSpareParts,
} from 'common/types/types';
import { i18next } from 'i18n';

import { ActionType } from './common';

const createSparePart = createAsyncThunk<
  SparePartsDto,
  SparePartsCreateRequestDto,
  AsyncThunkConfig
>(ActionType.CREATE, async (payload, { extra }) => {
  const { sparePartsApi, notification } = extra;
  const response = await sparePartsApi.create(payload);
  const successMessage = i18next.t(`${AppTranslationKey.SPARE_PARTS}:createSuccessMessage`);

  notification.success(successMessage);

  return response;
});

const getListSpareParts = createAsyncThunk<
  SparePartsGetListDto,
  SparePartsGetListRequestDto,
  AsyncThunkConfig
>(ActionType.GET_LIST, async (payload, { extra }) => {
  const { sparePartsApi } = extra;

  return sparePartsApi.getList(payload);
});

const updateSparePart = createAsyncThunk<
  SparePartsDto,
  { sparePartId: number, payload: SparePartsCreateRequestDto },
  AsyncThunkConfig
>(ActionType.UPDATE, async (payload, { extra }) => {
  const { sparePartsApi, notification } = extra;
  const response = await sparePartsApi.update(payload);
  const successMessage = i18next.t(`${AppTranslationKey.SPARE_PARTS}:editSuccessMessage`);

  notification.success(successMessage);

  return response;
});

const getSparePartsByUserId = createAsyncThunk<
  UserSpareParts[],
  SparePartsByUserIdRequest,
  AsyncThunkConfig
>(ActionType.GET_BY_USER_ID, async (payload, { extra }) => {
  const { sparePartsApi } = extra;

  return sparePartsApi.getByUserId(payload);
});

const getAllSparePartsByStoreId = createAsyncThunk<
  SparePartsDto[],
  SparePartsByStockIdRequest,
  AsyncThunkConfig
>(ActionType.GET_BY_STORE_ID, async (payload, { extra }) => {
  const { sparePartsApi } = extra;

  return sparePartsApi.getAllByStockId(payload);
});

export { createSparePart, getAllSparePartsByStoreId, getListSpareParts, getSparePartsByUserId, updateSparePart };
