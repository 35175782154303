import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import { useMemo, useState } from 'hooks/hooks';
import { i18next } from 'i18n';
import { changeLanguage } from 'i18next';
import React, { FC } from 'react';

import { getConfig } from './config';
import styles from './styles.module.scss';

const ExternalLangSelector: FC = () => {
  const [currentLang, setCurrentLang] = useState(i18next.language);

  const { current, list } = useMemo(
    () => getConfig(currentLang),
    [currentLang],
  );

  const onChangeLang = (locale: string): void => {
    changeLanguage(locale).then(() => {
      setCurrentLang(i18next.language);
    });
  };

  return (
    <CDropdown variant="dropdown" className={ styles.externalLangSelector }>
      <CDropdownToggle>
        { current?.icon || null }
        <span>
          { current?.title || null }
        </span>
      </CDropdownToggle>
      <CDropdownMenu>
        {
          list.map((item) => (
            <CDropdownItem href="#" onClick={ (): void => onChangeLang(item.lang) } key={ item.lang }>
              { item.icon }
              <span className="mx-2">
                { item.title }
              </span>
            </CDropdownItem>
          ))
        }
      </CDropdownMenu>
    </CDropdown>
  );
};

export { ExternalLangSelector };
