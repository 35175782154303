import { AppRoute } from 'common/enums/enums';
import { useAppSelector, useStatistics } from 'hooks/hooks';
import { FC, ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

type Props = {
  redirectTo?: AppRoute,
  component: ReactNode,
};

const ProtectedRoute: FC<Props> = ({ redirectTo = AppRoute.SIGN_IN, component }) => {

  const { currentUser } = useAppSelector((state) => state.auth);

  const hasUser = Boolean(currentUser);
  const { sendStat } = useStatistics();
  const location = useLocation();

  useEffect(() => {
    sendStat();
  }, [ location.pathname, location.search, sendStat ]);

  if (!hasUser) {
    return  <Navigate to={redirectTo} state={{ from: location }} />;
  }

  return <>{component}</>;
};

export { ProtectedRoute };
