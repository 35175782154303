import { CButton, CContainer, CNavbar } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { useFilterPanelContext } from 'components/common/common';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldCurrentMonth } from './fields/field-current-month';
import { FieldCurrentWeek } from './fields/field-current-week';
import { FieldPeriod } from './fields/field-period';
import { FieldStatuses } from './fields/field-statuses';
import { FieldToday } from './fields/field-today';
import styles from './style.module.scss';

const FilterPanel: FC = () => {
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE_ORDER);
  const { getListItems } = useFilterPanelContext();

  return (
    <CNavbar expand="xxl" colorScheme="light" className="bg-light">
      <CContainer fluid className={ styles.filterWrapper }>
        <FieldToday />
        <FieldCurrentWeek />
        <FieldCurrentMonth />
        <FieldPeriod />
        <FieldStatuses />
        <CButton color="primary" onClick={ getListItems }>
          { t('filters.apply') }
        </CButton>
      </CContainer>
    </CNavbar>
  );
};

export { FilterPanel };
