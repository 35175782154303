import { WorkOrderDto, WorkOrderTableRow } from 'common/types/types';
import { getFormattedDate } from 'helpers/helpers';

const getWorkOrderTableData = (workOrder: WorkOrderDto[]): WorkOrderTableRow[] => {
  const CAR_NAME_MAX_LENGTH = 20;

  return workOrder.map((it) => {

    const { carBrandName = '', carModelName = '' } = it?.car ?? {};
    const autoName = `${carBrandName} ${carModelName}`;

    return {
      orderId: it.orderId,
      requestId: it.requestId,
      comment: it.comment,
      // dateTime: getFormattedDate(it.dateStartPlane, 'dd.MM.yyyy HH:mm'),
      dateTime: getFormattedDate(it.dateStartPlane, 'yyyy-MM-dd HH:mm'),
      carName: autoName.length > CAR_NAME_MAX_LENGTH ? autoName?.slice(0, 17) + '...' : autoName ?? '',
      car: it.car,
      status: it.orderStatus,
      work: it.works,
      spareParts: it.spareParts,
      clientSpareParts: it.sparePartsFromClient,
      sumFull: it.sumFull.toFixed(2),
      sumWorks: it.sumWorks.toFixed(2),
      sumSpareParts: it.sumSpareParts.toFixed(2),
    };
  });
};

export { getWorkOrderTableData };
