import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeFormData } from 'common/types/types';
import { FILTER_DATE_FORMAT_VIEW } from 'constants/date-formats';
import { parse } from 'date-fns';
import { getFormattedDate } from 'helpers/helpers';
import React, { FC, ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'rsuite/DatePicker';

import styles from '../../styles.module.scss';

type Props = {
  control: Control<EmployeeFormData>,
  isDisable: boolean,
};

const RegistrationDateField: FC<Props> = ({ control, isDisable }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="regDate" className="col-sm-3 col-form-label">
        { `${ t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.REGISTRATION_DATE }`) }*` }
      </CFormLabel>
      <CCol sm={9}>
        <Controller
          name="registrationDate"
          rules={{ required: t(EmployeesProfilesKey.REQUIRED_FORM_FIELD) ?? 'This field is required' } }
          render={({ field, formState: { errors } }): ReactElement => {
            const value = parse(field.value, FILTER_DATE_FORMAT_VIEW, new Date());

            return (
              <div className={ styles.dataPickerContainer }>
                <DatePicker
                  id="regDate"
                  onChange={(value): void => field.onChange(getFormattedDate(value || new Date(), 'dd.MM.yyyy'))}
                  format={ FILTER_DATE_FORMAT_VIEW }
                  isoWeek
                  oneTap
                  placement="bottomEnd"
                  value={ value }
                  disabled={ isDisable }
                />
                { Boolean(errors.registrationDate) && (<p>{errors.registrationDate?.message || ''}</p>) }
              </div>
            );
          }}
          control={ control }
        />
      </CCol>
    </CRow>
  );
};

export { RegistrationDateField };
