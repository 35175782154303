enum WidgetApiPath {
  USER = '/user',
  CAR_SERVICE = '/carservice',
  NOT_COMPLETED_REQUEST = '/not-completed-requests',
  OPEN_REQUESTS = '/open-requests',
  OPEN_WORK_ORDERS = '/open-orders',
  CAR = '/car',
  REQUEST = '/request',
}

export { WidgetApiPath };
