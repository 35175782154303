import { FeedBackSendRequestType } from 'common/types/types';

const DEFAULT_FEED_BACK_MODAL_PAYLOAD: FeedBackSendRequestType = {
  // userId: 0,
  // title: '',
  body: '',
  filesBase64: [],
};

export { DEFAULT_FEED_BACK_MODAL_PAYLOAD };
