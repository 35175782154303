import { CButton } from '@coreui/react-pro';
import { Item } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey, OrderModalTranslationKey } from 'common/enums/enums';
import { orderDtoToBindOrderMap } from 'common/map/maps';
import { OrderDto, SelectedWorks } from 'common/types/types';
import { TableField } from 'components/common/table-field/table-field';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { isNull } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { taskActions, workOrderAction } from 'store/actions';
import { removeWorkFromOrderLocally } from 'store/car-service/reducer';
import { removeSelectedWorkByName, removeSelectedWorkByRowId } from 'store/task/reducer';
import { addBindOrder } from 'store/work-order/reducer';

import { getWorkFieldTableColumns } from './helpers/helpers';

type Props = {
  isDisable?: boolean,
  action: () => void,
  order?: OrderDto | null,
  toggleWorkOrderModal: (isOpen: boolean, readOnly?: boolean) => void,
  totalLabel?: React.ReactElement,
};

const WorksField: FC<Props> = ({
  action,
  order = null,
  isDisable= false,
  toggleWorkOrderModal,
  totalLabel,
}) => {

  const columns = getWorkFieldTableColumns();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.ORDER_MODAL);

  const {
    selectedWorks,
  } = useAppSelector(({ task }) => ({
    selectedWorks: task.selectedWorks,
  }));

  const handleRemoveClick = (item: Item): void => {
    const { name, rowId } = (item as SelectedWorks);

    if(rowId) {
      dispatch(taskActions.removeWork({ rowId } ))
        .unwrap()
        .then(() => {
          dispatch(removeSelectedWorkByRowId(rowId));
          order && dispatch(removeWorkFromOrderLocally({ orderId: order?.id, rowId: rowId }));
        });
    } else {
      dispatch(removeSelectedWorkByName(name));
    }
  };

  const handleOrderNumberClick = (workOrder: number): void => {
    dispatch(workOrderAction.getWorkOrderById({ orderId: workOrder }));
    toggleWorkOrderModal(true);
  };

  const handleAddWorkOrderClick = (): void => {
    order && dispatch(addBindOrder(orderDtoToBindOrderMap(order)));
    toggleWorkOrderModal(true, false);
  };

  const isDisabledAddWorkOrderButton = (): boolean => {
    return isDisable || isNull(order);
  };

  return <>
    <TableField
      isDisable={isDisable}
      onRemoveClick={handleRemoveClick}
      columns={ columns }
      data={ selectedWorks.map((it) => ({ ...it, canRemove: it.workOrder === '' } )) }
      onSelectClick={action}
      totalLabel={ totalLabel }
      noDataMsg={t(OrderModalTranslationKey.ORDER_NO_WORK) ?? 'The order has not works'}
      scopedColumns={{
        workOrder: (item: { workOrder: number }) => (
          <td>
            { item.workOrder ?
              <CButton
                color="link"
                size={'sm'}
                className="p-0"
                onClick={():void => handleOrderNumberClick(item.workOrder) }
              >
                { item.workOrder }
              </CButton> :
              <CButton
                color={ isDisabledAddWorkOrderButton() ? 'dark' : 'primary' }
                size="sm"
                className="p-0"
                disabled={isDisabledAddWorkOrderButton()}
                onClick={(): void => handleAddWorkOrderClick() }
              >
                <span className="p-1">{t(OrderModalTranslationKey.ADD_NEW_WORK_ORDER)}</span>
              </CButton>
            }
          </td>
        ),
      }}
    />
  </>;
};

export { WorksField };
