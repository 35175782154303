import {
  AppTranslationKey,
  WorkOrderModalTranslationKey,
  WorkOrderTranslationKey,
} from 'common/enums/enums';
import { TableColumn } from 'common/types/common/table-column.type';
import { t } from 'i18next';

const getCarWorkRowTableColumns = (): TableColumn[] => [
  {
    key: 'carWorkName',
    label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.WORK_ORDER_MODAL}:${WorkOrderModalTranslationKey.WORK_NAME_TABLE_FIELD}`),
    _style: { width: '58%' },
  },
  {
    key: 'qnt',
    label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.WORK_ORDER_MODAL}:${WorkOrderModalTranslationKey.QTY_TABLE_FIELD}`),
    _style: { width: '17%' },
  },
  {
    key: 'cost',
    label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.WORK_ORDER_MODAL}:${WorkOrderModalTranslationKey.COST_TABLE_FIELD}`),
    _style: { width: '10%' },
  },
  {
    key: 'sum',
    label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.WORK_ORDER_MODAL}:${WorkOrderModalTranslationKey.SUM_TABLE_FIELD}`),
    _style: { width: '10%' },
  },
  {
    key: 'remove',
    label: '',
    _style: { width: '5%' },
  },
];

export { getCarWorkRowTableColumns };
