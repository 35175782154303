import { cilPaperclip, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton, CCol,
  CForm,
  CModal,
  CModalBody, CModalFooter,
  CModalHeader, CModalTitle, CRow,
} from '@coreui/react-pro';
import { AppTranslationKey, FeedBackModalKey, NotificationType } from 'common/enums/enums';
import { FeedBackSendRequestType  } from 'common/types/types';
import { useAppDispatch, useAppSelector, useNavigate, useState } from 'hooks/hooks';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { appActions, feedBackActions } from 'store/actions';

import { DEFAULT_FEED_BACK_MODAL_PAYLOAD } from './common';
import { BodyField, TitleField } from './components/components';
import { FEED_BACK_ATTACH_BASE64_SEPARATOR } from './constants/feed-back-attach-constant.enum';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  isReadOnly: boolean;
  isUpdating: boolean;
  onCloseModal: () => void,
  onSubmit?: (payload: FeedBackSendRequestType) => void,
  feedBack?: FeedBackSendRequestType | null,
  defaultThemeId?: number,
};

const FeedBackModalForm: FC<Props> = ({
  isOpen,
  onCloseModal,
  // onSubmit,
  feedBack,
  defaultThemeId,
}) => {
  const { t } = useTranslation(AppTranslationKey.FEED_BACK_MODAL);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, themes } = useAppSelector(({ auth, feedBack }) => ({
    user: auth.currentUser,
    themes: feedBack.themes,
  }));
  const { id = 0 } = (user || {});

  const [ attachments, setAttachments ] = useState<string[]>([]);
  const [ attachmentsFileSize, setAttachmentsFileSize ] = useState<string>('');

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<FeedBackSendRequestType>({
    defaultValues: DEFAULT_FEED_BACK_MODAL_PAYLOAD,
  });

  const titleIdWatch = watch('titleId');
  const bodyWatch = watch('body');

  const getBase64FilesFromAttach = (): [] => {
    const result = [];

    for (let i = 0; i < attachments.length; i++) {
      const tmpAttach = attachments[i].split(FEED_BACK_ATTACH_BASE64_SEPARATOR);

      const tmpFileDataArr = [];
      tmpFileDataArr[0] = tmpAttach[0];
      tmpFileDataArr[1] = tmpAttach[2];
      result[i] = tmpFileDataArr;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return result;
  };

  const handleFormSubmit = (payload: FeedBackSendRequestType):void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById('feed-back-submit').disabled = true;
    dispatch(appActions.notify({ type: NotificationType.INFO,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
      message: t(FeedBackModalKey.SUCCESS_MESSAGE, { email: user.username }) }));
    // dispatch(appActions.notify({ type: NotificationType.SUCCESS, message: 'Вашого листа відправлено' }));
    handleClose();
    payload.userId = payload.userId ?? id;
    payload.filesBase64 = getBase64FilesFromAttach();
    payload.title = themes.find((item) => item.themeId === payload.titleId)?.feedbackTheme || '';
    dispatch(feedBackActions.sendFeedBack(payload))
      .unwrap()
      .then(() => {
        navigate(-1);
        // dispatch(appActions.notify({ type: NotificationType.SUCCESS, message: 'Вашого листа відправлено' }));
        handleCloseWithClear();
      })
      .catch( () => {
        dispatch(appActions.notify({ type: NotificationType.ERROR, message: t(FeedBackModalKey.ERROR_MESSAGE) }));
        handleClose();
      });
  };

  useEffect(() => {
    if(feedBack) {
      reset({
        userId: feedBack.userId,
        title: feedBack.title,
        body: feedBack.body,
        filesBase64: feedBack.filesBase64,
      });
    } else {
      reset();
    }
  }, [feedBack, reset]);

  const handleClose = (): void => {
    // reset(DEFAULT_FEED_BACK_MODAL_PAYLOAD);
    onCloseModal();
  };

  const handleCloseWithClear = (): void => {
    reset(DEFAULT_FEED_BACK_MODAL_PAYLOAD);
    setAttachments([]);
    setAttachmentsFileSize('');
    onCloseModal();
  };

  const getSizeText = (sizeInBytes: string): string => {
    const fileSize = parseInt(sizeInBytes);
    let result = '';

    if(fileSize < 1){
      result = '';
    }else if(fileSize < 1024){
      result = '('+fileSize+' Б)';
    }else if(fileSize < 1048576){
      result = '('+(fileSize / 1024).toFixed(2) + ` ${ t(FeedBackModalKey.KB) })`;
    }else{
      result = '('+(fileSize / 1048576).toFixed(2) + ` ${ t(FeedBackModalKey.MB) })`;
    }

    return result;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const reptileListItems = (attachments: string[]) => {

    return attachments.map((attachment) =>
      <span className={styles.fileName}>
        <span>
          {attachment.split(FEED_BACK_ATTACH_BASE64_SEPARATOR)[0]}
          <span className={styles.fileSize}>
            {getSizeText(attachment.split(FEED_BACK_ATTACH_BASE64_SEPARATOR)[1])}
          </span>
          <CIcon className={styles.deleteIcon}
            size="sm" icon={cilTrash}
            onClick={(): void => onClickDeleteAttachment(attachment)}  />
        </span>
      </span>,
    );
  };

  const onClickDeleteAttachment = (attachment: string): void => {
    closeMaxSizeAlertBlock();
    const tmpAttachments = attachments.filter((fileName) => attachment !== fileName);
    setAttachments(tmpAttachments);
    let sumFileSizes = 0;

    for(let i = 0; i < tmpAttachments.length; i++){
      sumFileSizes += parseInt(tmpAttachments[i].split(FEED_BACK_ATTACH_BASE64_SEPARATOR)[1]);
    }
    setAttachmentsFileSize(getSizeText(String(sumFileSizes)));
  };

  const onClickAddAttachment = (): void => {
    closeMaxSizeAlertBlock();
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    input.onchange = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const files = Array.from(input.files);

      let sumFileSizes = 0;

      for(let i = 0; i < attachments.length; i++){
        sumFileSizes += parseInt(attachments[i].split(FEED_BACK_ATTACH_BASE64_SEPARATOR)[1]);
      }

      let tmpSumFileSizes = sumFileSizes;
      for (let i = 0; i < files.length; i++) {
        tmpSumFileSizes += files[i].size;
      }

      if (tmpSumFileSizes / 1024 / 1024 > 25) {
        // alert('Загальний розмір файлів більше 25Мб. Видаліть зайві, щоб було менше 25Мб.');
        const alertDiv = document.getElementById('size-alert');
        const classes = alertDiv !== null ? alertDiv.className : '';

        if(classes.startsWith('styles_displayNone')){
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          alertDiv.className = '_'+classes;
        }
      } else {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          let baseFile: string | ArrayBuffer | null = '';
          const reader = new FileReader();
          reader.readAsDataURL(file);
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,no-loop-func
          reader.onload = function () {
            baseFile = reader.result;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const filesBase64 = baseFile.toString()
              .replace('data:', '')
              .replace(/^.+,/, '');

            let existFile = false;
            for (let j = 0; j < attachments.length; j++) {
              if(attachments[j].split(FEED_BACK_ATTACH_BASE64_SEPARATOR)[2] === filesBase64
                && attachments[j].split(FEED_BACK_ATTACH_BASE64_SEPARATOR)[0] === file.name
              ){
                existFile = true;
                break;
              }
            }

            if(!existFile) {
              attachments.push(
                file.name + FEED_BACK_ATTACH_BASE64_SEPARATOR +
                file.size + FEED_BACK_ATTACH_BASE64_SEPARATOR +
                filesBase64);
              setAttachments(attachments.filter((value) => value !== null));
              sumFileSizes += file.size;
              setAttachmentsFileSize(getSizeText(String(sumFileSizes)));
            }else{
              // alert('Файл ' + file.name + ' вже додано раніше.');
            }
          };

          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          reader.onerror = function (error) {
            // eslint-disable-next-line no-console
            console.log('FeedBackModalForm : Error: ', error);
          };
        }
      }
    };
    input.click();
  };

  const closeMaxSizeAlertBlock = (): void => {
    const alertDiv = document.getElementById('size-alert');
    const classes = alertDiv !== null ? alertDiv.className : '';

    if(classes.startsWith('_styles_displayNone')){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      alertDiv.className = classes.substring(1);
    }
  };

  return (
    <>
      <CModal
        onClose={handleClose}
        visible={isOpen}
        backdrop="static"
        size="lg"
        scrollable
      >
        <CModalHeader>
          <CModalTitle>{ t(FeedBackModalKey.TITLE) }</CModalTitle>
        </CModalHeader>
        <CForm className="form-scroll-no-padding" onSubmit={handleSubmit(handleFormSubmit)}>
          <CModalBody>
            <TitleField register={register} setValue={ setValue } defaultThemeId={ defaultThemeId } />
            <BodyField
              register={register}
              setValue={ setValue }
              titleId={ titleIdWatch ? +titleIdWatch : undefined }
              bodyValue={ bodyWatch }
            />
            <div id="size-alert" className={styles.displayNone}>
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                { t(FeedBackModalKey.WARNING_FILES_SIZE) }
                <button type="button" onClick={(): void => closeMaxSizeAlertBlock()}
                  className="btn btn-close" aria-label="Close"></button>
              </div>
            </div>
            <div>{reptileListItems(attachments)}</div>
          </CModalBody>
          <CModalFooter className={styles.displayBlock} >
            <CRow>
              <CCol>
                <CButton id="feed-back-submit"
                  color="primary"
                  className="px-4"
                  type="submit"
                >
                  { t(FeedBackModalKey.SEND_BUTTON_TITLE) }
                </CButton>
                <CButton title="max 25Мб"
                  onClick={(): void => onClickAddAttachment()} color="light" className="ms-2" >
                  <CIcon icon={cilPaperclip} />
                </CButton>
                <span className={styles.fileSize}>{attachmentsFileSize}</span>
              </CCol>
            </CRow>
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  );
};

export { FeedBackModalForm };
