import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react-pro';
import background from 'assets/img/auth-bg.jpg';
import ucsLogo from 'assets/img/ucs-logo.png';
import { AppTranslationKey, DataStatus, RecoveryPasswordTranslationKey } from 'common/enums/enums';
import { SetPasswordRequest } from 'common/types/types';
import { codeToMessageStatus } from 'components/auth/common/maps/maps';
import { Message } from 'components/auth/components/message/message';
import { ExternalLangSelector } from 'components/common/common';
import { toBase64 } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useParams } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { authActions } from 'store/actions';

import {  ContactUs } from '../components';
import { ChangePasswordForm } from './components/components';
import styles from './styles.module.scss';

const ChangePassword: FC = () => {

  const { t } = useTranslation(AppTranslationKey.RECOVERY_PASSWORD);
  const { uuid } = useParams();
  const dispatch = useAppDispatch();
  const { changePassRequestStatus, changePassResponse } = useAppSelector((state) => state.auth);
  const isLoading = changePassRequestStatus === DataStatus.PENDING;
  const handleChangePassClick = (payload: SetPasswordRequest): void => {
    const requestDto: SetPasswordRequest = {
      uuid: uuid ?? '',
      password: toBase64(payload.password),
    };

    dispatch(authActions.changePassword(requestDto));
  };

  return (
    <div style={{
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <div style={{ position: 'absolute', top: 0, right: 0 }} >
        <ExternalLangSelector/>
      </div>
      <div className="min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={9}>
              <CCardGroup>
                <CCard className="p-3">
                  <CCardBody className="px-4 py-2">
                    <h3 className={styles.title}>
                      {t(RecoveryPasswordTranslationKey.TITLE_RECOVERY_PASSWORD)}
                    </h3>
                    {changePassResponse ?
                      <Message
                        message={changePassResponse.message.toUpperCase()}
                        msgType={codeToMessageStatus[changePassResponse.code]}/> :
                      <ChangePasswordForm onSubmit={handleChangePassClick} isLoading={isLoading}/>}
                  </CCardBody>
                </CCard>
                <CCard className="text-white bg-primary p-3" style={{ width: '100%' }}>
                  <CCardBody className="text-center">
                    <div style={{ fontSize: 16 }}>
                      <img className={styles.ucsLogo} src={ucsLogo} alt={'UCS Logo'}/>
                      <p className={styles.text}>{t(RecoveryPasswordTranslationKey.CHANGE_PASS_TEXT)}</p>
                      <div className="pt-3">
                        <p>{t(RecoveryPasswordTranslationKey.ANY_QUESTION)}</p>
                        <span>{t(RecoveryPasswordTranslationKey.CALL_ME)}</span>
                      </div>
                      <ContactUs/>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  );
};

export { ChangePassword };
