import { AllCarWorkModalTranslationKey, AppTranslationKey, CarWorkTranslationKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getSelectedWorkTableColumn = (): TableColumn[] => {

  return [
    {
      key: 'name',
      label: t(
        `${
          AppTranslationKey.CAR_WORK
        }:${
          CarWorkTranslationKey.ALL_CAR_WORK_MODAL
        }:${
          AllCarWorkModalTranslationKey.CAR_WORK_MODAL_WORK_NAME
        }`,
      ),
      _style: { width: '45%' },
    },
    {
      key: 'workOrder',
      label: t(`${
        AppTranslationKey.CAR_WORK
      }:${
        CarWorkTranslationKey.ALL_CAR_WORK_MODAL
      }:${
        AllCarWorkModalTranslationKey.CAR_WORK_NUM_MODAL
      }`),
      _style: { width: '15%' },
    },
    {
      key: 'qty',
      label: t(`${
        AppTranslationKey.CAR_WORK
      }:${
        CarWorkTranslationKey.ALL_CAR_WORK_MODAL
      }:${
        AllCarWorkModalTranslationKey.CAR_WORK_QTY_MODAL
      }`),
      _style: { width: '10%' },
    },
    {
      key: 'price',
      label: t(`${
        AppTranslationKey.CAR_WORK
      }:${
        CarWorkTranslationKey.ALL_CAR_WORK_MODAL
      }:${
        AllCarWorkModalTranslationKey.CAR_WORK_PRICE_MODAL
      }`),
      _style: { width: '15%' },
    },
    {
      key: 'sum',
      label: t(`${
        AppTranslationKey.CAR_WORK
      }:${
        CarWorkTranslationKey.ALL_CAR_WORK_MODAL
      }:${
        AllCarWorkModalTranslationKey.CAR_WORK_SUM_MODAL
      }`),
      _style: { width: '15%' },
    },
    {
      key: 'remove',
      label: '',
    },
  ];
};

export { getSelectedWorkTableColumn };
