import { ApiPath, ContentType, HttpMethod, SparePartsApiPath, SparePartsFromClientApiPath } from 'common/enums/enums';
import {
  AddClientSparePartsRequest, DeleteClientSparePartsRequest, EditClientSparePartsRequest,
  SparePartsByStockIdRequest,
  SparePartsByUserIdRequest,
  SparePartsCreateRequestDto,
  SparePartsDto,
  SparePartsGetListDto,
  SparePartsGetListRequestDto,
  UserSpareParts,
} from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class SparePartsApiService {
  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public create(payload: SparePartsCreateRequestDto): Promise<SparePartsDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SPARE_PARTS}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public getList({ stockId, page = 0, size = 10 }: SparePartsGetListRequestDto): Promise<SparePartsGetListDto> {
    const query = `?page=${page}&size=${size}`;

    return this.#http.load(`${this.#apiPrefix}${ApiPath.SPARE_PARTS}${SparePartsApiPath.STOCK}/${stockId}${query}`);
  }

  public update(
    { sparePartId, payload }: { sparePartId: number, payload: SparePartsCreateRequestDto },
  ): Promise<SparePartsDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SPARE_PARTS}/${sparePartId}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public getByUserId({ userId }: SparePartsByUserIdRequest) : Promise<UserSpareParts[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SPARE_PARTS}${SparePartsApiPath.USER}/${userId}`,
    );
  }

  public getAllByStockId({ stockId }: SparePartsByStockIdRequest): Promise<SparePartsDto[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SPARE_PARTS}${SparePartsApiPath.STOCK}${SparePartsApiPath.ALL}/${stockId}`,
    );
  }

  public addClientSpareParts(payload: AddClientSparePartsRequest): Promise<void> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SPARE_PARTS_FROM_CLIENT}${SparePartsFromClientApiPath.ADD}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public deleteClientSpareParts(payload: DeleteClientSparePartsRequest): Promise<void> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SPARE_PARTS_FROM_CLIENT}${SparePartsFromClientApiPath.DELETE}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public editClientSpareParts(payload: EditClientSparePartsRequest): Promise<void> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SPARE_PARTS_FROM_CLIENT}${SparePartsFromClientApiPath.EDIT}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }
}

export { SparePartsApiService };
