import { CCard, CCardHeader, CContainer } from '@coreui/react-pro';
import { AppTranslationKey, DataStatus, StorageTranslationKey } from 'common/enums/enums';
import { StorageModifyRequest } from 'common/types/types';
import { Spinner } from 'components/common/common';
import { Page404 } from 'components/page-404/page-404';
import { StorageForm } from 'components/storage-profiles/storage-form/storage-form';
import { useAppDispatch, useAppSelector, useEffect, useNavigate,useParams } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { storageActions } from 'store/actions';

const EditStorage: FC = () => {
  const { t } = useTranslation(AppTranslationKey.STOCK);
  const { storageId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userId, userStorages, dataStatus } = useAppSelector(({ stock, auth }) => ({
    userStorages: stock.stocks,
    dataStatus: stock.getStocksByUserIdDataStatus,
    userId: auth.currentUser?.id ?? 0,
  }));

  const storage = userStorages.find((item) => item.stockId.toString() === storageId) ?? null;
  const  isLoading = dataStatus === DataStatus.PENDING;

  useEffect( () => {
    if (storage === null) {
      dispatch(storageActions.getStocksByUserId({ userId }));
    }
  },[dispatch, storage, userId]);

  if(isLoading) {
    return <Spinner isOverflow containerHeight={250} />;
  }

  if (storage === null) {
    return <Page404 />;
  }

  const handleConfirmEdit = (storage: StorageModifyRequest ): void => {
    dispatch(storageActions.updateStorageById(storage))
      .unwrap()
      .then(() => navigate(-1));
  };

  return (
    <CContainer fluid>
      <CCard>
        <CCardHeader>
          {t(StorageTranslationKey.EDIT_STORAGE_TITLE)}
        </CCardHeader>
        <StorageForm onSubmit={handleConfirmEdit} storage={storage}/>
      </CCard>
    </CContainer>
  );
};

export { EditStorage };
