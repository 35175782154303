import { CCard, CCardBody, CCardHeader,CCol, CRow  } from '@coreui/react-pro';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AppTranslationKey } from '../../../../common/enums/translation-key/app-translation-key.enum';
import styles from './style.module.scss';

const VideoPreview: FC = () => {
  const { t } = useTranslation(AppTranslationKey.HELP);

  return (
    <CCard>
      <CCardHeader>
        <h5 className="text-dark">{ t('title') }</h5>
      </CCardHeader>
      <CCardBody >
        <CRow className="text-center"><h3>{ t('video_title') }</h3></CRow>
        <CRow className="justify-content-center">
          <CCol className="text-center">
            {/*<iframe className={styles.iframeParams}*/}
            {/*  src="https://www.youtube-nocookie.com/embed/hcJ1vc6MqAk?si=Px7mJw-5p3RDZg1S"*/}
            {/*  title="Відеоогляд UkrCarsService" frameBorder="0"*/}
            {/*  allow="accelerometer; autoplay; clipboard-write;*/}
            {/*  encrypted-media; gyroscope; picture-in-picture; web-share"*/}
            {/*  allowFullScreen></iframe>*/}
            {/*<iframe className={styles.iframeParams} //width="560" height="315"*/}
            {/*        src="https://www.youtube.com/embed/b6CwHe1aqNY?si=PHXGXHduJNCVH9ps"*/}
            {/*        title="YouTube video player" frameBorder="0"*/}
            {/*        allow="accelerometer; autoplay; clipboard-write;*/}
            {/*        encrypted-media; gyroscope; picture-in-picture; web-share"*/}
            {/*        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>*/}
            <iframe className={styles.iframeParams}
              src="https://www.youtube.com/embed/OyBgKRGUY8Q?si=ehz7ZfYuR2ZTDy0L"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write;
              encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export { VideoPreview };
