import cn from 'classnames';
import React, { FC } from 'react';

interface Props {
  className?: string;
}

const IconRu: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301 201" className={ cn('icon', className) } role="img">
    <g fill="none">
      <path fill="#FFF" d="M.5.5h300v100H.5z"></path>
      <path fill="#FFF" d="M.5 100.5h300v100H.5z"></path>
      <path fill="#0039A6" d="M.5 67.166h300v66.667H.5z"></path>
    </g>
  </svg>
);

export { IconRu };
