import { createSlice } from '@reduxjs/toolkit';
import { AuthTranslationKey, DataStatus } from 'common/enums/enums';
import { PlainResponse, SetPasswordResponse, UserDto } from 'common/types/types';

import { changeNameUser, getUserById } from '../user/actions';
import {
  changePassword,
  getCurrentUser,
  logOut,
  recoveryPasswordSendEmail,
  refreshToken,
  resendValidation,
  signIn,
  signUp,
} from './actions';

type State = {
  dataStatus: DataStatus;
  currentUser: UserDto | null;
  userHash: string | null;
  toastMsgType: AuthTranslationKey.REGISTRATION_MSG | AuthTranslationKey.INACTIVE_USER__MSG | '';
  recoveryPassRequestStatus: DataStatus;
  changePassRequestStatus: DataStatus;
  changePassResponse: SetPasswordResponse | null;
  resendValidationDataStatus: DataStatus,
  errorSendEmail: PlainResponse | null,
};

const initialState: State = {
  dataStatus: DataStatus.IDLE,
  currentUser: null,
  userHash: null,
  toastMsgType: '',
  recoveryPassRequestStatus: DataStatus.IDLE,
  changePassRequestStatus: DataStatus.IDLE,
  changePassResponse: null,
  resendValidationDataStatus: DataStatus.IDLE,
  errorSendEmail: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //refresh token
      .addCase(refreshToken.fulfilled, (state, { payload }) => {
        state.currentUser = payload.user;
        state.dataStatus = DataStatus.FULFILLED;
      })
      .addCase(refreshToken.pending, (state) => {
        state.dataStatus = DataStatus.PENDING;
      })
      .addCase(refreshToken.rejected, (state) => {
        state.dataStatus = DataStatus.REJECTED;
      })

      //signIn
      .addCase(signIn.pending, (state) => {
        state.dataStatus = DataStatus.PENDING;
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.errorSendEmail = null;
        state.userHash = null;
        'user' in payload && (state.currentUser = payload.user);

        if ('user_hash' in payload) {
          state.userHash = payload.user_hash;
          state.toastMsgType = AuthTranslationKey.INACTIVE_USER__MSG;
        } else {
          state.userHash = null;
        }

        state.dataStatus = DataStatus.FULFILLED;
      })
      .addCase(signIn.rejected, (state) => {
        state.currentUser = null;
        state.dataStatus = DataStatus.REJECTED;
      })

      //signUp
      .addCase(signUp.fulfilled, (state, { payload }) => {
        state.userHash = null;
        state.errorSendEmail = null;

        if ('userHash' in payload) {
          state.userHash = payload.userHash;
          state.toastMsgType =AuthTranslationKey.REGISTRATION_MSG;
        }
        ('code' in payload && payload.code === 201) && (state.errorSendEmail = payload);
        state.dataStatus = DataStatus.FULFILLED;
      })
      .addCase(signUp.pending, (state) => {
        state.dataStatus = DataStatus.PENDING;
      })
      .addCase(signUp.rejected, (state) => {
        state.currentUser = null;
        state.dataStatus = DataStatus.REJECTED;
      })

      //logout
      .addCase(logOut.fulfilled, () => {
        window.location.reload();
      })

      //getCurrentUser
      .addCase(getCurrentUser.pending, (state) => {
        state.dataStatus = DataStatus.PENDING;
      })
      .addCase(getCurrentUser.fulfilled, (state, { payload }) => {
        state.currentUser = payload.user;
        state.dataStatus = DataStatus.FULFILLED;
      })
      .addCase(getCurrentUser.rejected, (state) => {
        state.currentUser = null;
        state.dataStatus = DataStatus.REJECTED;
      })

      //getUserById
      .addCase(
        getUserById.pending,
        (state) => {
          state.dataStatus = DataStatus.PENDING;
        })
      .addCase(
        getUserById.fulfilled,
        (state, { payload }) => {
          state.dataStatus = DataStatus.FULFILLED;
          state.currentUser = payload;
        })
      .addCase(
        getUserById.rejected,
        (state) => {
          state.dataStatus = DataStatus.REJECTED;
        })

      //changeNameUser
      .addCase(
        changeNameUser.pending,
        (state) => {
          state.dataStatus = DataStatus.PENDING;
        })
      .addCase(
        changeNameUser.fulfilled,
        (state, { payload }) => {
          state.dataStatus = DataStatus.FULFILLED;
          state.currentUser = payload;
        })
      .addCase(
        changeNameUser.rejected,
        (state) => {
          state.dataStatus = DataStatus.REJECTED;
        })

      //recovery password
      .addCase(
        recoveryPasswordSendEmail.pending,
        (state) => {
          state.recoveryPassRequestStatus = DataStatus.PENDING;
        })
      .addCase(
        recoveryPasswordSendEmail.rejected,
        (state) => {
          state.recoveryPassRequestStatus = DataStatus.REJECTED;
        })
      .addCase(
        recoveryPasswordSendEmail.fulfilled,
        (state) => {
          state.recoveryPassRequestStatus = DataStatus.FULFILLED;
        })
      //change password
      .addCase(
        changePassword.pending,
        (state) => {
          state.changePassRequestStatus = DataStatus.PENDING;
          state.changePassResponse = null;
        })
      .addCase(
        changePassword.rejected,
        (state) => {
          state.changePassRequestStatus = DataStatus.REJECTED;
          state.changePassResponse = null;
        })
      .addCase(
        changePassword.fulfilled,
        (state, { payload }) => {
          state.changePassRequestStatus = DataStatus.FULFILLED;
          state.changePassResponse = payload;
        })

      //resend validation mail
      .addCase(resendValidation.pending, (state) => {
        state.resendValidationDataStatus = DataStatus.PENDING;
      })
      .addCase(resendValidation.rejected, (state) => {
        state.resendValidationDataStatus = DataStatus.REJECTED;
      })
      .addCase(resendValidation.fulfilled, (state) => {
        state.resendValidationDataStatus = DataStatus.FULFILLED;
      });
  },
});

const authReducer = authSlice.reducer;

export { authReducer };
