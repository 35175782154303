import { CButton, CForm, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { ChangePasswordForm } from 'common/types/types';
import { PasswordInput } from 'components/password-input/password-input';
import { useAppDispatch, useAppSelector, useEffect, useForm } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { resetChangePasswordStatus } from 'store/user/reducer';

import { DEFAULT_VALUES_CHANGE_PASSWORD_FORM } from './common';
import { useChangePasswordModalContext } from './context/change-password-modal-context';
import styles from './styles.module.scss';

const ChangePasswordModal: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.PROFILE);
  const { onClose, isOpen, onSave } = useChangePasswordModalContext();
  const changePasswordError = useAppSelector(({ user }) => user.changePasswordError);

  const changePasswordStatus = useAppSelector(({ user }) => user.changePasswordStatus);

  const {
    handleSubmit,
    register,
    reset,
    trigger,
    formState: { errors, isValid },
  } = useForm<ChangePasswordForm>({
    defaultValues: DEFAULT_VALUES_CHANGE_PASSWORD_FORM,
  });

  const validateNewPasswordField = (value: string, formValues: ChangePasswordForm): string | boolean | undefined => {
    if (value === formValues.oldPassword) {
      return t('changePasswordModal.matchNewPasswordError') as string;
    }

    if (value !== formValues.repeatPassword) {
      return t('changePasswordModal.matchPasswordError') as string;
    }

    return true;
  };

  const validateRepeatPasswordField = (value: string, formValues: ChangePasswordForm): string | boolean | undefined => {
    return value === formValues.newPassword || t('changePasswordModal.matchPasswordError') as string;
  };

  const handleClose = (): void => {
    onClose();
    reset(DEFAULT_VALUES_CHANGE_PASSWORD_FORM);
  };

  const changeFormFields = (): void => {
    trigger();
    dispatch(resetChangePasswordStatus());
  };

  useEffect(() => {
    if (changePasswordStatus === DataStatus.FULFILLED && !changePasswordError) {
      onClose();
      reset(DEFAULT_VALUES_CHANGE_PASSWORD_FORM);
      dispatch(resetChangePasswordStatus());
    }
  }, [changePasswordStatus, onClose, reset, dispatch]);

  return (
    <CModal
      visible={ isOpen }
      onClose={ handleClose }
      backdrop="static"
    >
      <CModalHeader>
        <CModalTitle>{ t('changePasswordModal.title') }</CModalTitle>
      </CModalHeader>
      <CForm autoComplete="off" onSubmit={ handleSubmit(onSave) } onChange={ changeFormFields }>
        <CModalBody className={ styles.changePasswordForm }>
          <p className={ styles.titleField }>
            {t('changePasswordModal.oldPasswordTitle')}
          </p>
          <PasswordInput
            {
              ...register('oldPassword', {
                required: t('changePasswordModal.passwordErrorText') ?? 'Password cannot be empty',
              })
            }
            invalidFeedbackClass={ styles.invalidFeedback }
            invalid={Boolean(errors.oldPassword) || Boolean(changePasswordError)}
            valid={ isValid }
            feedbackInvalid={errors.oldPassword?.message || changePasswordError}
            placeholder={ t('changePasswordModal.passwordPlaceholder') as string }
            autoComplete
          />

          <div className={ styles.separateLine } />

          <p className={ styles.titleField }>
            {t('changePasswordModal.newPasswordTitle')}
          </p>
          <PasswordInput
            {
              ...register('newPassword', {
                required: t('changePasswordModal.passwordErrorText') ?? 'Password cannot be empty',
                validate: validateNewPasswordField,
              },
              )
            }
            invalidFeedbackClass={ styles.invalidFeedback }
            invalid={Boolean(errors.newPassword)}
            valid={ isValid }
            feedbackInvalid={errors.newPassword?.message}
            placeholder={ t('changePasswordModal.passwordPlaceholder') as string }
          />
          <p className={ styles.titleField }>
            {t('changePasswordModal.repeatNewPasswordTitle')}
          </p>
          <PasswordInput
            {
              ...register('repeatPassword', {
                required: t('changePasswordModal.passwordErrorText') ?? 'Password cannot be empty',
                validate: validateRepeatPasswordField,
              })
            }
            invalidFeedbackClass={ styles.invalidFeedback }
            invalid={Boolean(errors.repeatPassword)}
            valid={ isValid }
            feedbackInvalid={errors.repeatPassword?.message}
            placeholder={ t('changePasswordModal.passwordPlaceholder') as string }
          />
        </CModalBody>
        <CModalFooter>
          <CButton color="dark" variant="ghost" onClick={ handleClose }>
            {t('changePasswordModal.closeButton') }
          </CButton>
          <CButton color="primary" type="submit">
            { t('changePasswordModal.saveButton') }
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
};

export { ChangePasswordModal };
