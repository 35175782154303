import { ViewImage } from 'components/modals/modals';
import React,  { FC } from 'react';

import styles from './styles.module.scss';

interface IProps {
  img: string,
  width: number,
  title: string,
  text: string | React.ReactElement,
}

const InfoBlock: FC<IProps> = ({ img, width, title, text }) => {
  return (
    <div className={ styles.infoBlockContainer }>
      <ViewImage
        src={ img }
        width={ width }
        alt="VIN"
        className={ styles.picture }
        bodyClass={ styles.pictureModalBody }
      />
      <div className={ styles.textBlock }>
        <div className={ styles.textBlockTitle }>{ title }</div>
        <div>{ text }</div>
      </div>
    </div>
  );
};

export { InfoBlock };
