import { cifGb, cifUa } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import cn from 'classnames';
import { Lang } from 'common/enums/enums';
import { IconRu } from 'components/common/common';
import React from 'react';

import styles from './styles.module.scss';

interface ILangConfigItem {
  lang: Lang,
  title: string,
  icon: React.ReactElement,
}

interface IConfig {
  current: ILangConfigItem,
  list: ILangConfigItem[],
}

const langConfig: Record<Lang, ILangConfigItem> = {
  [Lang.UK]: {
    lang: Lang.UK,
    title: 'Українська',
    icon: <CIcon icon={cifUa} className={ cn('icon-custom-size', styles.iconFlag) } />,
  },
  [Lang.EN]: {
    lang: Lang.EN,
    title: 'English',
    icon: <CIcon icon={cifGb} className={ cn('icon-custom-size', styles.iconFlag) } />,
  },
  [Lang.RU]: {
    lang: Lang.RU,
    title: 'Язык',
    icon: <IconRu className={ cn('icon-custom-size', styles.iconFlag) } />,
  },
};

export const getConfig = (currentLang: string): IConfig => {
  let lang = currentLang.split('-')[0] as Lang;

  if (!langConfig[lang]) {
    lang = Lang.UK;
  }

  const langKeyList = Object.keys(langConfig).filter((key) => key !== lang);
  const langList = langKeyList.map((key) => langConfig[key as Lang]);

  return {
    current: langConfig[lang],
    list: langList,
  };
};
