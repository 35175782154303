import { CSpinner } from '@coreui/react-pro';
import { Colors } from 'common/types/types';
import { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  isOverflow?: boolean,
  color?: Colors,
  containerHeight?: string | number,
};

const Spinner: FC<Props> = ({
  isOverflow= false,
  containerHeight = '90vh',
  color = 'primary',
}) => {

  if (isOverflow) {
    return (
      <div className={styles.container} style={{ height: containerHeight }}>
        <CSpinner className={styles.loader} color={color} variant={'grow'}/>
      </div>
    );
  }

  return <CSpinner style={{ marginRight: 5, marginLeft: 5 }} color={color} size={'sm'}/>;

};

export { Spinner };
