import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { SendErrorResponse } from 'common/types/types';

import { sendError } from './actions';

type State = {
  response: SendErrorResponse | null,
  dataStatusSendError: DataStatus,
};

const initialState: State = {
  dataStatusSendError: DataStatus.IDLE,
  response: null,
};

const sendErrorSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // sent error
      .addCase(sendError.pending, (state) => {
        state.dataStatusSendError = DataStatus.PENDING;
      })
      .addCase(sendError.rejected, (state) => {
        state.dataStatusSendError = DataStatus.REJECTED;
      })
      .addCase(sendError.fulfilled, (state, { payload }) => {
        state.dataStatusSendError = DataStatus.FULFILLED;
        state.response = payload;
      });
  },
});

const sendErrorReducer = sendErrorSlice.reducer;
export { sendErrorReducer };
