import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FeedBackSendRequestType,
  FeedBackSendResponseType,
  FeedbackThemeResponse,
} from 'common/types/types';

import { AsyncThunkConfig } from '../../common/types/app/async-thunk-config.type';
import { ActionType } from './common';

const sendFeedBack = createAsyncThunk<
  FeedBackSendResponseType,
  // void,
  FeedBackSendRequestType,
  AsyncThunkConfig
>(ActionType.FEEDBACK_SEND, async(payload, { extra }) => {
  const { feedBackApi } = extra;

  return feedBackApi.create(payload);
});

const getFeedBackThemes = createAsyncThunk<
  FeedbackThemeResponse[],
  void,
  AsyncThunkConfig
>(ActionType.FEEDBACK_THEMES, async (payload, { extra }) => {
  const { feedBackApi } = extra;

  return feedBackApi.getThemes();
});

export { getFeedBackThemes,sendFeedBack };
