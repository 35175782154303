import { ApiPath, AuthApiPath, ContentType, HttpMethod, MailApiPath, UserApiPath } from 'common/enums/enums';
import {
  CurrentUserDto, InactiveUserResponse,
  LogoutRequestDto,
  LogoutResponseDto,
  RecoveryPasswordRequest,
  RefreshTokenRequestDto,
  RefreshTokenResponseDto, ResendValidateRequest,
  SetPasswordRequest,
  SetPasswordResponse, UserDto,
  UserSignInRequestDto,
  UserSignInResponseDto,
  UserSignUpRequestDto,
  UserSignUpResponseDto, ValidateUserRequest,
} from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class AuthApi {
  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;

    this.#apiPrefix = apiPrefix;
  }

  public signIn(payload: UserSignInRequestDto): Promise<UserSignInResponseDto | InactiveUserResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.AUTH}${AuthApiPath.GET_TOKEN}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
        hasAuth: false,
        basicAuth: true,
      },
    );
  }

  public signUp(payload: UserSignUpRequestDto): Promise<UserSignUpResponseDto> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.USER}${AuthApiPath.CREATE_USER}`,
      {
        method: HttpMethod.POST,
        payload: JSON.stringify(payload),
        hasAuth: false,
        basicAuth: true,
      },
    );
  }

  public getCurrentUser(payload: string): Promise<CurrentUserDto> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.AUTH}${AuthApiPath.CHECK_TOKEN}`,
      {
        queryString: {
          token: payload,
        },
      },
    );
  }

  public refreshToken(payload: RefreshTokenRequestDto): Promise<RefreshTokenResponseDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.AUTH}${AuthApiPath.REFRESH_TOKEN}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
        hasAuth: false,
        basicAuth: true,
      },
    );
  }

  public logout(payload: LogoutRequestDto): Promise<LogoutResponseDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.AUTH}${AuthApiPath.LOGOUT}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public recoveryPasswordSendEmail({ email }: RecoveryPasswordRequest): Promise<void> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.RECOVERY_PASSWORD}`,
      {
        hasAuth: false,
        queryString: {
          email,
        },
      },
    );
  }

  public changePassword(payload: SetPasswordRequest): Promise<SetPasswordResponse> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SET_PASSWORD}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        hasAuth: false,
        payload: JSON.stringify(payload),
      },
    );
  }

  public validateUser({ hash }: ValidateUserRequest): Promise<UserDto> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.USER}${UserApiPath.VALIDATE  }`,
      {
        hasAuth: false,
        queryString: { hash },
      },
    );
  }

  public resendValidation(hash: ResendValidateRequest): Promise<void> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.MAIL}${MailApiPath.RESEND_VALIDATION}`,
      {
        hasAuth: false,
        queryString: hash,
      },
    );
  }
}

export { AuthApi };
