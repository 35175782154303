import { AppTranslationKey, Pages, PersonalRoute } from 'common/enums/enums';
import { WithCarServicesTabs } from 'common/types/types';
import { usePermissionToastContext } from 'components/common/common';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts';
import { withCarServicesTabs } from 'hoc/hoc';
import { useEffect, useNavigate } from 'hooks/hooks';
import { t } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CarServiceDetails } from './car-service-details/car-service-details';

const CarServicesProfiles: FC<WithCarServicesTabs> = ( { activeCarService } ) => {
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const navigate = useNavigate();
  const { onPermissionAction } = usePermissionToastContext();
  const { i18n } = useTranslation();

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.CAR_SERVICE}:addCarServiceCaptionButton`,
      action: onPermissionAction(
        () => navigate(PersonalRoute.ADD_CAR_SERVICE.split('/').pop() ?? 'add'),
        Tariffs.NO_TARIFF,
      ),
    });

    return () => {
      removeExtraButton();
    };
  }, [i18n.language]);

  return (<CarServiceDetails carService={activeCarService}/>);
};

export const CarServicesProfilesWithCarServicesTabs = withCarServicesTabs(
  CarServicesProfiles,
  false,
  Pages.CAR_SERVICE,
  () => t('mainMenu:carServicesNavItem') ?? '',
);
