import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { worksToSelectedWorks } from 'common/map/maps';
import { ClientSpareParts, OrderDto, SelectedWorks, StatusTask } from 'common/types/types';
import { isNull } from 'lodash';

import {
  addTask,
  addWorkInTask,
  getOrderById,
  getStatuses,
  removeClientSpareParts,
  removeWork,
  updateOrder,
} from './actions';

type State = {
  dataStatusStatuses: DataStatus,
  dataStatusAddEditTask: DataStatus,
  dataStatusModifyWorkInTask: DataStatus,
  dataStatusOrder: DataStatus,
  statuses: StatusTask[],
  order: OrderDto | null,
  selectedWorks: SelectedWorks[],
  stashClientSpareParts: ClientSpareParts[],
};

type ChangeSelectedWorkPayload = {
  value: string,
  workId: number,
  rowId: number | null,
};

const initialState: State = {
  dataStatusStatuses: DataStatus.IDLE,
  dataStatusAddEditTask: DataStatus.IDLE,
  dataStatusOrder: DataStatus.IDLE,
  dataStatusModifyWorkInTask: DataStatus.IDLE,
  statuses: [],
  selectedWorks: [],
  order: null,
  stashClientSpareParts: [],
};

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    stashClientSpareParts: (state, action: PayloadAction<ClientSpareParts[]>) => {
      state.stashClientSpareParts = action.payload;
    },
    removeStashClientSP: (state, action: PayloadAction<ClientSpareParts>) => {
      state.stashClientSpareParts = state.stashClientSpareParts.filter((it) => action.payload.id !== it.id);
    },
    clearOrder: (state) => {
      state.order = null;
      state.stashClientSpareParts = [];
    },
    removeSelectedWorkByName: (state, action: PayloadAction<string>) => {
      state.selectedWorks = state.selectedWorks.filter((item) => item.name !== action.payload);

      if (state.order ) {
        state.order = {
          ...state.order,
          works: state.order?.works.filter((item) => item.serviceWorkName !== action.payload) ?? [],
        };
      }
    },
    removeSelectedWorkByRowId: (state, action: PayloadAction<number>) => {
      state.selectedWorks = state.selectedWorks.filter((item) => item.rowId !== action.payload);

      if (state.order ) {
        state.order = {
          ...state.order,
          works: state.order?.works.filter((item) => item.rowId !== action.payload) ?? [],
        };
      }
    },
    addSelectedWorkLocally: (state, action: PayloadAction<SelectedWorks>) => {
      const { payload } = action;
      state.selectedWorks.push(payload);
      state?.order?.works.push({
        orderId: payload.workOrder,
        rowId: payload.rowId,
        priceOne: payload.price,
        requestId: state.order.id,
        serviceWorkId: payload.workId,
        priceTotal: payload.sum,
        serviceWorkName: payload.name,
        workCount: payload.qty,
      });
    },
    changePriceSelectedWork: (state, { payload }: PayloadAction<ChangeSelectedWorkPayload>) => {
      const { value, workId, rowId } = payload;

      const floatPrice = parseFloat(value);
      state.selectedWorks = state.selectedWorks.map((item) => {

        if ((item.rowId && item.rowId === rowId) || (isNull(item.rowId) && item.workId === workId)) {
          const sum = isNaN(floatPrice) ? '0' : (floatPrice * parseFloat(item.qty)).toFixed(4) ?? '0';

          return { ...item, price: value, sum: sum };
        }

        return item;
      });

      if (state.order) {

        state.order.works = state.order.works.map((item) => {
          if ((item.rowId && item.rowId === rowId) || (isNull(item.rowId) && item.serviceWorkId === workId)) {
            const sum = isNaN(floatPrice) ? '0' : (floatPrice * parseFloat(item.workCount as string)).toFixed(4) ?? '0';

            return { ...item, priceOne: value, priceTotal: sum };
          }

          return item;
        });

      }
    },
    changeQtySelectedWork: (state, { payload }: PayloadAction<ChangeSelectedWorkPayload>) => {
      const { value, workId, rowId } = payload;
      const floatQty = parseFloat(value);

      state.selectedWorks = state.selectedWorks.map((item) => {
        if ((item.rowId && item.rowId === rowId) || (isNull(item.rowId) && item.workId === workId)) {
          const sum = isNaN(floatQty) ? '0' : (floatQty * parseFloat(item.price)).toFixed(4) ?? '0';

          return { ...item, qty: value, sum: sum };
        }

        return item;
      });

      if(state.order) {
        state.order.works = state.order.works.map((item) => {
          if ((item.rowId && item.rowId === rowId) || (isNull(item.rowId) && item.serviceWorkId === workId)) {
            const sum = isNaN(floatQty) ? '0' : (floatQty * parseFloat(item.priceOne as string)).toFixed(4) ?? '0';

            return { ...item, workCount: value, priceTotal: sum };
          }

          return item;
        });
      }
    },
    clearSelectedWorks: (state) => {
      state.selectedWorks = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatuses.pending, (state) => {
        state.dataStatusStatuses = DataStatus.PENDING;
      })
      .addCase(getStatuses.rejected, (state) => {
        state.dataStatusStatuses = DataStatus.REJECTED;
        state.statuses = [];
      })
      .addCase(getStatuses.fulfilled, (state, { payload }) => {
        state.dataStatusStatuses = DataStatus.FULFILLED;
        state.statuses = payload;
      })

      // add order
      .addCase(addTask.pending, (state) => {
        state.dataStatusAddEditTask = DataStatus.PENDING;
      })
      .addCase(addTask.rejected, (state) => {
        state.dataStatusAddEditTask = DataStatus.REJECTED;
      })
      .addCase(addTask.fulfilled, (state) => {
        state.dataStatusAddEditTask = DataStatus.FULFILLED;
      })

    //get order by ID
      .addCase(getOrderById.pending, (state) => {
        state.dataStatusOrder = DataStatus.PENDING;
      })
      .addCase(getOrderById.rejected, (state) => {
        state.dataStatusOrder = DataStatus.REJECTED;
        state.order = null;
      })
      .addCase(getOrderById.fulfilled, (state, { payload }) => {
        state.dataStatusOrder = DataStatus.FULFILLED;
        state.order = payload;
        state.selectedWorks = payload.works.map((item) => worksToSelectedWorks(item));
      })

    //update order
      .addCase(updateOrder.pending, (state) => {
        state.dataStatusAddEditTask = DataStatus.PENDING;
      })
      .addCase(updateOrder.rejected, (state) => {
        state.dataStatusAddEditTask = DataStatus.REJECTED;
      })
      .addCase(updateOrder.fulfilled, (state) => {
        state.dataStatusAddEditTask = DataStatus.FULFILLED;
      })
      //add work in task
      .addCase(addWorkInTask.pending, (state) => {
        state.dataStatusModifyWorkInTask = DataStatus.PENDING;
      })
      .addCase(addWorkInTask.rejected, (state) => {
        state.dataStatusModifyWorkInTask = DataStatus.REJECTED;
      })
      .addCase(addWorkInTask.fulfilled, (state) => {
        state.dataStatusModifyWorkInTask = DataStatus.FULFILLED;
      })
      //remove work in task
      .addCase(removeWork.pending, (state) => {
        state.dataStatusModifyWorkInTask = DataStatus.PENDING;
      })
      .addCase(removeWork.rejected, (state) => {
        state.dataStatusModifyWorkInTask = DataStatus.REJECTED;
      })
      .addCase(removeWork.fulfilled, (state) => {
        state.dataStatusModifyWorkInTask = DataStatus.FULFILLED;
      })
      .addCase(removeClientSpareParts.fulfilled, (state, { payload }) => {
        if(state.order) {
          state.order.sparePartsFromClient =
            state.order?.sparePartsFromClient
              .filter((it) => it.sparePartId && !payload.sparePartIdList.includes(it.sparePartId));
        }
      });
  },
});

const taskReducer = taskSlice.reducer;
const {
  clearOrder,
  clearSelectedWorks,
  removeSelectedWorkByName,
  removeSelectedWorkByRowId,
  addSelectedWorkLocally,
  changePriceSelectedWork,
  changeQtySelectedWork,
  stashClientSpareParts,
  removeStashClientSP,
} = taskSlice.actions;

export {
  addSelectedWorkLocally,
  changePriceSelectedWork,
  changeQtySelectedWork,
  clearOrder,
  clearSelectedWorks,
  removeSelectedWorkByName,
  removeSelectedWorkByRowId,
  removeStashClientSP,
  stashClientSpareParts,
  taskReducer,
};
