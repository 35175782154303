import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AsyncThunkConfig,
  CarWorkFav,
  CarWorkGetAllResponse,
  CarWorkGetByCarServiceId,
  CarWorkUpdateFavRequest, CarWorkUpdFavListRequest,
} from 'common/types/types';
import { carWorkActions } from 'store/actions';

import { ActionType } from './common';

const getFavoritesCarWork = createAsyncThunk<
  CarWorkFav[],
  CarWorkGetByCarServiceId,
  AsyncThunkConfig
>(ActionType.GET_FAVORITES, async (payload, { extra })=> {
  const { carWorkApi } = extra;

  return carWorkApi.getFavoritesCarWorkByCarServiceId(payload);
});

const updateFavoritesCarWork = createAsyncThunk<
  CarWorkFav,
  CarWorkUpdateFavRequest,
  AsyncThunkConfig
>(ActionType.UPDATE_FAVORITES, async (payload, {  extra }) => {
  const { carWorkApi } = extra;

  return carWorkApi.updateFavoritesCarWork(payload);
});

const getAllCarWorkByCarService = createAsyncThunk<
  CarWorkGetAllResponse[],
  CarWorkGetByCarServiceId,
  AsyncThunkConfig
>(ActionType.GET_ALL_CAR_WORK_BY_CAR_SERVICE, async (payload, { extra }) => {
  const { carWorkApi } = extra;

  return carWorkApi.getAllCarWorkByCarService(payload);
});

const updFavListByCarServiceId = createAsyncThunk<
  void,
  CarWorkUpdFavListRequest,
  AsyncThunkConfig
>(ActionType.UPD_FAV_LIST_FOR_CAR_SERVICE, async (payload, { extra, dispatch }) => {
  const  { carWorkApi } = extra;
  const { carServiceId, workIds } = payload;
  await carWorkApi.updFavListForCarService(workIds, carServiceId);
  await dispatch(carWorkActions.getFavoritesCarWork({ carServiceId }));
});

export { getAllCarWorkByCarService, getFavoritesCarWork, updateFavoritesCarWork, updFavListByCarServiceId };
