import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig, IDocumentGetByOrderId, IDocumentGetByRequestId } from 'common/types/types';

import { ActionType } from './common';

const getCertificateOfCompletedWork = createAsyncThunk<
  { url: string },
  { requestId: number },
  AsyncThunkConfig
>(ActionType.GET_CERTIFICATE_OF_COMPLETED_WORK, async (payload, { extra }) => {
  const { documentationApi } = extra;

  return documentationApi.getCertificateOfCompletedWork(payload);
});

const getDocById= createAsyncThunk<
  { url: string },
  IDocumentGetByRequestId | IDocumentGetByOrderId,
  AsyncThunkConfig
>(ActionType.GET_DOC_BY_ID, async (payload, { extra }) => {
  const { documentationApi } = extra;

  return documentationApi.getDocById(payload);
});

export { getCertificateOfCompletedWork, getDocById };
