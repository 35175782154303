import { CFormSwitch, CSmartTable } from '@coreui/react-pro';
import { AppTranslationKey, CarWorkTranslationKey } from 'common/enums/enums';
import { CarWorkFav, SelectedWorks } from 'common/types/types';
import { NoData } from 'components/common/common';
import { Spinner } from 'components/common/spinner/spinner';
import { getItemPerPageOptions } from 'helpers/helpers';
import { useMemo } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getCarWorkTableColumn } from './heplpers/helpers';

type Props = {
  carWork: CarWorkFav[],
  isLoading: boolean,
  onSelectCarWork: (item: CarWorkFav) => void,
  selectedWorks: SelectedWorks[],
  isDisabled: (item?: CarWorkFav) => boolean,
  isModifyWorkInTask?: boolean,
};

const FavCarWorkTable: FC<Props> = ({
  carWork,
  isLoading,
  selectedWorks,
  onSelectCarWork,
  isDisabled,
  isModifyWorkInTask = false,
}) => {

  const favCarWorkModalTableColumn = useMemo(() => getCarWorkTableColumn(), []);
  const { t } = useTranslation(AppTranslationKey.CAR_WORK);
  const itemsPerPage: number[] = useMemo( () => getItemPerPageOptions(carWork.length), [ carWork ] );

  if (isLoading) {
    return <Spinner isOverflow containerHeight={120}/>;
  }

  if (carWork.length === 0) {
    return <NoData
      title={t([CarWorkTranslationKey.NO_CAR_WORK_TITLE, AppTranslationKey.COMMON])}
      message={''}
    />;
  }

  return (
    <CSmartTable
      columns={favCarWorkModalTableColumn}
      columnSorter
      columnFilter
      loading={isModifyWorkInTask}
      sorterValue={{ column: 'serviceWorkName', state: 'asc' }}
      items={carWork}
      itemsPerPageSelect
      noItemsLabel={
        <NoData
          title={t('noItemsFoundTitle', { ns: AppTranslationKey.COMMON })}
          message={t('noItemsFoundMsg', { ns: AppTranslationKey.COMMON })}
        />
      }
      itemsPerPageOptions={itemsPerPage}
      itemsPerPageLabel={t(CarWorkTranslationKey.ITEMS_PER_PAGE_LABEL) ?? 'Елементів на сторінці'}
      pagination
      scopedColumns={{
        isSelect: (item: CarWorkFav) => (
          <td>
            <CFormSwitch
              style={{ cursor: 'pointer' }}
              type="checkbox"
              checked={
                selectedWorks.some((works) => works.name === item.serviceWorkName)
              }
              disabled={isDisabled(item)}
              onChange={(): void => {
                onSelectCarWork({
                  ...item,
                  rowId: selectedWorks.find((it) => it.name === item.serviceWorkName)?.rowId ?? null,
                });
              }}
            />
          </td>
        ),
      }}
      tableHeadProps={{
        color: 'light',
      }}
      tableProps={{
        striped: true,
        hover: true,
      }}
    />
  );
};

export  { FavCarWorkTable };
