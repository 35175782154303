import { AllCarWorkModalTranslationKey, AppTranslationKey, CarWorkTranslationKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getCarWorkTableColumn = (): TableColumn[] => {

  return [
    {
      key: 'isFavorite',
      label: '',
      filter: false,
      sorter: false,
      _style: { width: '10%' },
    },
    {
      key: 'serviceWorkName',
      label: t(
        `${
          AppTranslationKey.CAR_WORK
        }:${
          CarWorkTranslationKey.ALL_CAR_WORK_MODAL
        }:${
          AllCarWorkModalTranslationKey.CAR_WORK_MODAL_WORK_NAME
        }`,
      ),
      _style: { width: '55%' },
    },
    {
      key: 'serviceWorkTypeName',
      label: t(`${
        AppTranslationKey.CAR_WORK
      }:${
        CarWorkTranslationKey.ALL_CAR_WORK_MODAL
      }:${
        AllCarWorkModalTranslationKey.CAR_WORK_MODAL_WORK_TYPE
      }`),
      _style: { width: '35%' },
    },
  ];
};

export { getCarWorkTableColumn };
