import { DangerAlertModalMode, DangerAlertModalTranslationKey } from 'common/enums/enums';

export const config = {
  [DangerAlertModalMode.CANCEL_WORK_ORDER]: {
    text: DangerAlertModalTranslationKey.CANCEL_WORK_ORDER_TEXT,
    cancelButtonText: DangerAlertModalTranslationKey.CANCEL_BUTTON_TEXT,
    applyButtonText: DangerAlertModalTranslationKey.CANCEL_WORK_ORDER_BUTTON_TEXT,
  },
  [DangerAlertModalMode.DELETE_USER_PHONE]: {
    text: DangerAlertModalTranslationKey.DELETE_PHONE_TEXT,
    cancelButtonText: DangerAlertModalTranslationKey.CANCEL_BUTTON_TEXT,
    applyButtonText: DangerAlertModalTranslationKey.DELETE_PHONE_BUTTON_TEXT,
  },
  [DangerAlertModalMode.CANCEL_ORDER]: {
    text: DangerAlertModalTranslationKey.CANCEL_ORDER_TEXT,
    cancelButtonText: DangerAlertModalTranslationKey.CANCEL_BUTTON_TEXT,
    applyButtonText: DangerAlertModalTranslationKey.CANCEL_ORDER_BUTTON_TEXT,
  },
};
