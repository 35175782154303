import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppTranslationKey } from 'common/enums/enums';
import {
  AsyncThunkConfig,
  SupplierAddRequestDto,
  SupplierDto,
  SupplierEditRequestDto,
} from 'common/types/types';
import { i18next } from 'i18n';

import { ActionType } from './common';

const createSupplier = createAsyncThunk<
  SupplierDto,
  SupplierAddRequestDto,
  AsyncThunkConfig
>(ActionType.CREATE, async(payload, { extra }) => {
  const { supplierApi, notification } = extra;
  const response = await supplierApi.create(payload);
  const successMessage = i18next.t(`${AppTranslationKey.SUPPLIERS}:createSupplierSuccessMessage`);

  notification.success(successMessage);

  return response;
});

const getListSuppliers = createAsyncThunk<
  SupplierDto[],
  { userId: number },
  AsyncThunkConfig
>(ActionType.GET_LIST, (payload, { extra }) => {
  const { supplierApi } = extra;

  return supplierApi.getList(payload);
});

const getSupplierById = createAsyncThunk<
  SupplierDto,
  { supplierId: number },
  AsyncThunkConfig
>(ActionType.GET_BY_ID, (payload, { extra }) => {
  const { supplierApi } = extra;

  return supplierApi.getById(payload);
});

const editSupplier = createAsyncThunk<
  SupplierDto,
  SupplierEditRequestDto,
  AsyncThunkConfig
>(ActionType.EDIT, async(payload, { extra }) => {
  const { supplierApi, notification } = extra;
  const response = await supplierApi.edit(payload);
  const successMessage = i18next.t(`${AppTranslationKey.SUPPLIERS}:editSupplierSuccessMessage`);

  notification.success(successMessage);

  return response;
});

export { createSupplier, editSupplier, getListSuppliers, getSupplierById };
