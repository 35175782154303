import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { FeedBackSendRequestType, FeedbackThemeResponse } from 'common/types/types';

import { getFeedBackThemes,sendFeedBack } from './actions';

type State = {
  data: FeedBackSendRequestType,
  dataStatusSendFeedBack: DataStatus,
  themes: FeedbackThemeResponse[],
  dataStatusGetThemes: DataStatus,
};

const initialState: State = {
  data: {
    userId: 0,
    title: '',
    body: '',
    filesBase64: [],
  },
  dataStatusSendFeedBack: DataStatus.IDLE,
  themes: [],
  dataStatusGetThemes: DataStatus.IDLE,
};

const feedBackSlice = createSlice({
  name: 'feedBack',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      // send feedback
      .addCase(sendFeedBack.pending, (state) => {
        state.dataStatusSendFeedBack = DataStatus.PENDING;
      })
      .addCase(sendFeedBack.rejected, (state) => {
        state.dataStatusSendFeedBack = DataStatus.REJECTED;
      })
      .addCase(sendFeedBack.fulfilled, (state) => {
        state.dataStatusSendFeedBack = DataStatus.FULFILLED;
      })
      // get themes
      .addCase(getFeedBackThemes.pending, (state) => {
        state.dataStatusGetThemes = DataStatus.PENDING;
      })
      .addCase(getFeedBackThemes.rejected, (state) => {
        state.dataStatusGetThemes = DataStatus.REJECTED;
      })
      .addCase(getFeedBackThemes.fulfilled, (state, { payload }) => {
        state.dataStatusGetThemes = DataStatus.FULFILLED;
        state.themes = payload;
      });
  },
});

const feedBackReducer = feedBackSlice.reducer;

export { feedBackReducer };
