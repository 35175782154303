import { CButton, CListGroupItem } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { EmployeeModal } from 'components/modals/modals';
import { useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

type Props = {
  carServiceId: number,
  employeeId: number,
};

const ItemButtons: FC<Props> = ({ carServiceId, employeeId }) => {
  const { t } = useTranslation(AppTranslationKey.COMMON);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isDisableModal, setIsDisableModal] = useState<boolean>(false);

  return (
    <CListGroupItem color="info" className={ styles.itemButtonContainer }>
      <CButton
        variant="outline"
        onClick={ (): void => { setIsOpenModal(true); setIsDisableModal(true); } }
      >
        { t('viewButton') }
      </CButton>

      <CButton
        variant="outline"
        color="danger"
        onClick={ (): void => { setIsOpenModal(true); setIsDisableModal(false); } }
      >
        { t('editButton') }
      </CButton>

      {
        isOpenModal && (
          <EmployeeModal
            isOpen={ true }
            carServiceId={ carServiceId }
            employeeId={ employeeId }
            isDisable={ isDisableModal }
            onEnable={ (): void => setIsDisableModal(false) }
            onClose={ (): void => setIsOpenModal(false) }
          />
        )
      }
    </CListGroupItem>
  );
};

export { ItemButtons };
