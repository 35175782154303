import { CFormLabel } from '@coreui/react-pro';
import React, { FC } from 'react';

type Props = {
  label: string,
};
const ModalLabel: FC<Props> = ({ label }) => {

  return (
    <CFormLabel className="col-sm-2 col-form-label">
      {label}
    </CFormLabel>
  );
};

export { ModalLabel };
