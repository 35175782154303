import { CButton } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { ChangePasswordModal, useChangePasswordModalContext } from 'components/modals/modals';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const ChangePasswordButton: FC = () => {
  const { t } = useTranslation(AppTranslationKey.PROFILE);
  const { onOpen } = useChangePasswordModalContext();

  return (
    <>
      <CButton color="link" shape="rounded-0" className={ styles.linkButton } onClick={ onOpen }>
        { t('changePasswordButton') }
      </CButton>
      <ChangePasswordModal />
    </>
  );
};

export { ChangePasswordButton };
