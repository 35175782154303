import { AppRoute, EmployeesRoute, HelpRoute, PersonalRoute, ServicesRoute } from 'common/enums/enums';

export const routes = [
  {
    path: '/personal/dashboards',
    names: [{ name: 'dashboard' }],
  },
  {
    path: '/personal/profile',
    names: [{ name: 'profile' }],
  },
  {
    path: '/personal/car-services-profiles',
    names: [{ name: 'myAutoServices' }, { name: 'carServicesProfiles' }],
  },
  {
    path: '/personal/car-services-profiles/edit',
    names: [{ name: 'edit' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.ADD_CAR_SERVICE}`,
    names: [{ name: 'add' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.ADD_STORAGE}`,
    names: [{ name: 'add' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.CAR_SERVICES_PROFILES}`,
    names: [{ name: 'myAutoServices' }, { name: 'carServicesProfiles' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.CAR_SERVICES_CUSTOMERS}`,
    names: [{ name: 'carServicesCustomers' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.CAR_SERVICES_AUTO}`,
    names: [{ name: 'myAutoServices' }, { name: 'carServicesAuto' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.CAR_SERVICES_ORDERS}`,
    names: [{ name: 'myAutoServices' }, { name: 'carServicesOrders' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.CAR_SERVICES_ORDERS_CERTIFICATE}`,
    names: [{ name: 'orderCertificate', params: ['orderId'] }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.SUPPLIERS}`,
    names: [{ name: 'storage' }, { name: 'suppliers' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.STORAGE_PROFILES}`,
    names: [{ name: 'storage' }, { name: 'storagesProfiles' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.STORAGE_PROFILES}/edit`,
    names: [{ name: 'edit' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.ADD_SUPPLIER}`,
    names: [{ name: 'add' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.EDIT_SUPPLIER}`,
    names: [{ name: 'edit' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.WORK}`,
    names: [{ name: 'myAutoServices' }, { name: 'work' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.SPARE_PARTS}`,
    names: [{ name: 'storage' }, { name: 'spareParts' }],
  },
  {
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.WORK_ORDERS}`,
    names: [{ name: 'myAutoServices' }, { name: 'workOrders' }],
  },
  {
    path: `${AppRoute.HELP}${HelpRoute.ROOT}${HelpRoute.VIDEO_REVIEW}`,
    names: [{ name: 'help' }, { name: 'videoReview' }],
  },
  {
    path: `${AppRoute.HELP}${HelpRoute.ROOT}${HelpRoute.TARIFFS}`,
    names: [{ name: 'help' }, { name: 'tariffs' }],
  },
  {
    path: `${AppRoute.SERVICES}${ServicesRoute.ROOT}${ServicesRoute.INFO_VIN}`,
    names: [{ name: 'services' }, { name: 'infoVin' }],
  },
  {
    path: `${AppRoute.HELP}${HelpRoute.ROOT}${HelpRoute.ABOUT_REQUEST}`,
    names: [{ name: 'help' }, { name: 'allAboutRequest' }],
  },
  {
    path: `${AppRoute.EMPLOYEES}${EmployeesRoute.ROOT}${EmployeesRoute.PROFILES}`,
    names: [{ name: 'employees' }, { name: 'employeesProfiles' }],
  },
  {
    path: `${AppRoute.EMPLOYEES}${EmployeesRoute.ROOT}${EmployeesRoute.SALARY}`,
    names: [{ name: 'employees' }, { name: 'salary' }],
  },
];
