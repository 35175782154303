import { CCol, CListGroupItem, CRow } from '@coreui/react-pro';
import { FC } from 'react';

type Props = {
  text: string,
  caption: string,
};

const ProfileDetailRow: FC<Props> = ({ text, caption }) => {

  return (
    <CListGroupItem>
      <CRow className="justify-content-start">
        <CCol style={{ minWidth: 100 }} xs={12} sm={1} md={1} >{caption}</CCol>
        <CCol xs={12} sm={9} md={9}>{text}</CCol>
      </CRow>
    </CListGroupItem>
  );
};

export { ProfileDetailRow };
