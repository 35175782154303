import { AppTranslationKey, SelectOrderModalTranslationKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getTableColumns = (): TableColumn[] => [
  {
    key: 'orderId',
    label: '#',
    _style: { width: '70px' },
  },
  {
    key: 'carInfo',
    label: t(`${AppTranslationKey.SELECT_ORDER_MODAL}:${SelectOrderModalTranslationKey.AUTO_TABLE_FIELD}`),
    _style: { width: '300px' },
  },
  {
    key: 'dateTime',
    label: t(`${AppTranslationKey.SELECT_ORDER_MODAL}:${SelectOrderModalTranslationKey.DATETIME_TABLE_FIELD}`),
    _style: { width: '160px' },
  },
  {
    key: 'comment',
    label: t(`${AppTranslationKey.SELECT_ORDER_MODAL}:${SelectOrderModalTranslationKey.COMMENT_TABLE_FIELD}`),
    sorter: false,
  },
  {
    key: 'statusName',
    label: t(`${AppTranslationKey.SELECT_ORDER_MODAL}:${SelectOrderModalTranslationKey.STATUS_TABLE_FIELD}`),
    filter: false,
    _style: { width: '150px' },
  },
];

export { getTableColumns };
