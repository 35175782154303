import { ContentType, FeedBackApiPath,HttpMethod } from 'common/enums/enums';
import {
  FeedBackSendRequestType, FeedbackThemeResponse,
} from 'common/types/types';
import { Http } from 'services/http/http.service';

import { FeedBackSendResponseType } from '../../common/types/feed-back/feed-back-send-response.type';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class FeedBackApiService {
  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public create(payload: FeedBackSendRequestType): Promise<FeedBackSendResponseType> {
    // eslint-disable-next-line no-console
    console.log('FeedBackApiService | FeedBackSendRequestType | title=' + payload.title +
      '; | body=' + payload.body +
      '; | userId=' + payload.userId +
      '; | attachments=' + payload.filesBase64,
    );

    return this.#http.load(
      `${this.#apiPrefix}${FeedBackApiPath.SEND}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public getThemes(): Promise<FeedbackThemeResponse[]> {
    return this.#http.load(
      `${this.#apiPrefix}${FeedBackApiPath.GET_THEMES}`,
    );
  }

}

export { FeedBackApiService };
