import { CButton, CCard, CCardBody, CListGroup, CListGroupItem } from '@coreui/react-pro';
import { AppTranslationKey, ClientSparePartsTranslationKey, WorkOrderTranslationKey } from 'common/enums/enums';
import { ClientSpareParts, SparePartsBindToWorkOrder, WorkDto } from 'common/types/types';
import { TableField, usePermissionToastContext } from 'components/common/common';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getClientSparePartsRowTableColumns,
  getSparePartsTableColumns,
  getWorkFieldTableColumns,
} from '../helpers';
import styles from './style.module.scss';

type Props = {
  work: WorkDto[],
  spareParts: SparePartsBindToWorkOrder[],
  clientSpareParts: ClientSpareParts[],
  onViewEditWorkOrderClick: (readOnly: boolean) => void;
};

const WorkOrderDetails: FC<Props> = (
  {
    work,
    spareParts,
    clientSpareParts,
    onViewEditWorkOrderClick,
  }) => {
  const { t } = useTranslation(
    [AppTranslationKey.CAR_SERVICE_AUTO, AppTranslationKey.WORK_ORDER, AppTranslationKey.CLIENT_SPARE_PARTS],
  );
  const workColumns = getWorkFieldTableColumns();
  const sparePartsColumns = getSparePartsTableColumns();
  const clientSparePartsColumns = getClientSparePartsRowTableColumns();
  const { onPermissionAction } = usePermissionToastContext();

  return (
    <CCard color="light">
      <CCardBody>
        <CListGroup>
          <CListGroupItem>
            {work.length > 0 &&
              <p>
                { t(WorkOrderTranslationKey.WORK_ORDER_DETAILS_WORK_TITLE, { ns: AppTranslationKey.WORK_ORDER }) }
              </p> }
            <TableField
              isDisable={ true }
              onRemoveClick={ (): void => {
                //do nothing
              } }
              columns={ workColumns }
              data={ work }
              noDataMsg={
                t(WorkOrderTranslationKey.WORK_ORDER_NO_WORK, { ns: AppTranslationKey.WORK_ORDER })
                ?? 'The order has not works'
              }
            />
          </CListGroupItem>
          <CListGroupItem>
            {spareParts.length > 0 &&
              <p>
                {
                  t(WorkOrderTranslationKey.WORK_ORDER_DETAILS_SPARE_PARTS_TITLE, { ns: AppTranslationKey.WORK_ORDER })
                }
              </p> }
            <TableField
              isDisable={ true }
              onRemoveClick={ (): void => {
                //do nothing
              } }
              columns={ sparePartsColumns }
              data={ spareParts }
              noDataMsg={
                t(WorkOrderTranslationKey.WORK_ORDER_NO_SPARE_PARTS, { ns: AppTranslationKey.WORK_ORDER })
                ?? 'The order has not works'
              }
            />
          </CListGroupItem>
          <CListGroupItem>
            {clientSpareParts.length > 0 &&
              <p>
                {
                  t(ClientSparePartsTranslationKey.TITLE, { ns: AppTranslationKey.CLIENT_SPARE_PARTS })
                }
              </p> }
            <TableField
              isDisable={ true }
              onRemoveClick={ (): void => {
                //do nothing
              } }
              columns={ clientSparePartsColumns }
              data={ clientSpareParts }
              noDataMsg={
                t(ClientSparePartsTranslationKey.NO_SPARE_PARTS, { ns: AppTranslationKey.CLIENT_SPARE_PARTS })
                ?? 'The order has not works'
              }
            />
          </CListGroupItem>
          <CListGroupItem color="info" className="d-flex justify-content-end align-items-center">
            <CButton
              variant="outline"
              className={ styles.viewCarButton }
              onClick={ (): void => onViewEditWorkOrderClick(true) }
            >
              { t('carViewButton') }
            </CButton>
            <CButton
              variant="outline"
              color="danger"
              className={ styles.editCarButton }
              onClick={ onPermissionAction((): void => onViewEditWorkOrderClick(false)) }
            >
              { t('carEditButton') }
            </CButton>
          </CListGroupItem>
        </CListGroup>
      </CCardBody>
    </CCard>
  );
};

export { WorkOrderDetails };
