import { CToast, CToastBody, CToastHeader } from '@coreui/react-pro';
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  visible: boolean,
  onClose: () => void,
};

const PermissionToast: FC<Props> = ({ visible, onClose }) => {
  const { t } = useTranslation('common' );

  return createPortal(
    <CToast autohide={true} visible={visible} className={ styles.permissionToast } delay={6000} onClose={ onClose } >
      <CToastHeader className={ styles.permissionToastHeader } closeButton onClick={ onClose }>
        <svg
          className="rounded me-2"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect width="100%" height="100%" fill="#007aff"></rect>
        </svg>
        <div className="fw-bold me-auto">UkrCarService</div>
      </CToastHeader>
      <CToastBody className={ styles.permissionToastBody }>
        { t('permissionMessage') }
      </CToastBody>
    </CToast>,
    document.body,
  );
};

export { PermissionToast };
