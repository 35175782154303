import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppTranslationKey, StorageTranslationKey } from 'common/enums/enums';
import {
  AsyncThunkConfig,
  StorageDto,
  StorageGetByIdRequest,
  StorageGetByUserIdRequest,
  StorageModifyRequest,
} from 'common/types/types';
import { i18next } from 'i18n';
import { userActions } from 'store/actions';

import { ActionType } from './common';

const getStocksByUserId = createAsyncThunk<
  StorageDto[],
  StorageGetByUserIdRequest,
  AsyncThunkConfig
>(ActionType.GET_BY_USER_ID, async (payload, { extra }) => {
  const { stockApi } = extra;

  return stockApi.getStocksByUserId(payload);
});

const createStorage = createAsyncThunk<
  StorageDto,
  StorageModifyRequest,
  AsyncThunkConfig
>(ActionType.CREATE, async (payload, { extra, dispatch, getState }) => {
  const { stockApi, notification } = extra;
  const { auth } = getState();
  const response: StorageDto = await stockApi.createStorage(payload);
  auth.currentUser?.id && dispatch(userActions.getUserById(auth.currentUser?.id));
  notification.success(i18next.t(`${AppTranslationKey.STOCK}:${StorageTranslationKey.ADD_STORAGE_SUCCESS}`) ?? '');

  return response;
});

const updateStorageById = createAsyncThunk<
  StorageDto,
  StorageModifyRequest,
  AsyncThunkConfig
>(ActionType.UPDATE_STORAGE_BY_ID, async (payload, { extra }) => {
  const { stockApi, notification } = extra;
  const response = await stockApi.updateStorageById(payload);
  notification.success(i18next.t(`${AppTranslationKey.STOCK}:${StorageTranslationKey.UPDATE_STORAGE_SUCCESS}`) ?? '');

  return response;
});

const getStorageById = createAsyncThunk<
  StorageDto,
  StorageGetByIdRequest,
  AsyncThunkConfig
>(ActionType.GET_STORAGE_BY_ID, async (payload, { extra }) => {
  const
    { stockApi } = extra;

  return stockApi.getStorageById(payload);
});

export { createStorage, getStocksByUserId, getStorageById, updateStorageById };
