import { cilAt } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro';
import ucsLogo from 'assets/img/ucs-logo.png';
import {
  AppRoute,
  AppTranslationKey,
  AuthTranslationKey,
  DataStatus,
  ENV,
} from 'common/enums/enums';
import { UserSignInRequestDto } from 'common/types/types';
import { AppToast, Spinner } from 'components/common/common';
import { PasswordInput } from 'components/password-input/password-input';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { authActions } from 'store/actions';

import { ContactUs } from '../components';
import { DEFAULT_SIGN_IN_PAYLOAD } from './common';
import styles from './styles.module.scss';

type Props = {
  onSubmit: (payload: UserSignInRequestDto) => void;
};

const SignInForm: FC<Props> = ({ onSubmit }) => {

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserSignInRequestDto>({
    defaultValues: DEFAULT_SIGN_IN_PAYLOAD,
  });

  const {
    dataStatus,
    resendValidationDataStatus,
    userHash,
    toastMsgType,
    errorSendEmail,
  } = useAppSelector((state) => state.auth);
  const { t } = useTranslation(AppTranslationKey.AUTH);
  const isLoading = dataStatus === DataStatus.PENDING;
  const isResendPending = resendValidationDataStatus === DataStatus.PENDING;
  const dispatch = useAppDispatch();

  const resendEmail = (): void => {
    dispatch(authActions.resendValidation({ hash: userHash ?? '' } ));
  };

  const mes = t(toastMsgType).split('|');

  return (
    <>
      {errorSendEmail && <AppToast
        visible={true}
        type="warn"
      >
        <div style={{ textAlign: 'center' }}>
          {errorSendEmail.message}
        </div>
      </AppToast>

      }
      { userHash && <AppToast
        visible={ true }
      >
        <div className={styles.actionText}>
          {mes[0]}
          <span onClick={resendEmail}>
            {t(AuthTranslationKey.RESEND_ACTION_NAME) ?? 'Resend email'}
          </span>
          {mes[1]}
          <a href={`mailto:${ENV.SUPPORT_EMAIL}`}
            title="Відправити листа">{ENV.SUPPORT_EMAIL}</a>
          {mes[2]}
          {isResendPending && <Spinner/>}
        </div>
      </AppToast>}
      <div className="min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={ 8 }>
              <CCardGroup>
                <CCard className="p-3">
                  <CCardBody>
                    <CForm onSubmit={ handleSubmit(onSubmit) }>
                      <h1 style={ { textAlign: 'center', paddingBottom: 20 } }>{ t('titleSignIn') }</h1>
                      <p style={{ fontSize: 16, paddingBottom: 5 }}>{ t('subtitleSignIn') }</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={ cilAt }/>
                        </CInputGroupText>
                        <CFormInput
                          { ...register('username',
                            {
                              required: t('loginErrorText') ?? 'Please enter a valid email address',
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t('loginErrorText') ?? 'Please enter a valid email address',
                              },
                            }) }
                          name="username"
                          type="text"
                          placeholder={ t('login') ?? 'E-mail' }
                          invalid={ Boolean(errors.username) }
                          feedbackInvalid={ errors.username?.message }
                        />
                      </CInputGroup>
                      <PasswordInput
                        { ...register('password', { required: t('passwordErrorText') ?? 'Error' }) }
                        placeholder={ t('password') ?? 'Password' }
                        invalid={ Boolean(errors.password) }
                        feedbackInvalid={ errors.password?.message }
                        withShowPassword={ false }
                      />
                      <CRow className="mt-4">
                        <CCol xs={ 6 } className="text-start">
                          <CButton
                            color="primary"
                            className="px-4"
                            type="submit"
                            disabled={ isLoading }
                          >
                            { isLoading && (<Spinner color="light"/>) }
                            { t('loginButtonText') }
                          </CButton>
                        </CCol>
                        <CCol xs={ 6 } className="text-end">
                          <Link to={ AppRoute.RECOVERY_PASSWORD }>
                            <CButton color="link" className="px-0">
                              { t('forgotPassword') }
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard className="text-white bg-primary p-3" style={ { width: '100%' } }>
                  <CCardBody className="text-center">
                    <div style={{ fontSize: 16 }}>
                      <h2>{t('welcomeText')}</h2>
                      <img className={styles.ucsLogo} src={ucsLogo} alt={'UCS Logo'}/>
                      <p style={{ borderBottom: 1, borderBottomStyle: 'solid', paddingBottom: 10 }}>
                        {t('secondSubtitleSignIn')}
                      </p>
                      <p>
                        {t('dontHaveAccount')}
                      </p>
                      <Link to={AppRoute.SIGN_UP}>
                        <CButton color="primary" className="mt-3" active tabIndex={-1}>
                          {t('registerNowButtonText')}
                        </CButton>
                      </Link>
                      <div className="pt-3">
                        <p>{t('anyQuestion')}</p>
                        <span>{t('callMe')}</span>
                      </div>
                      <ContactUs />
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  );
};

export { SignInForm };
