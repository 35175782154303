enum SelectSparePartsTableTranslationKey {
  NO_SPARE_PARTS_WORK_TITLE = 'no-spare-parts-work-title',
  SPARE_PARTS_NAME = 'sparePertsName',
  SPARE_PARTS_STOCK_NAME = 'sparePartsStockName',
  SPARE_PARTS_QTY = 'sparePartsQty',
  SPARE_PARTS_PRICE = 'sparePartsPrice',
  SPARE_PARTS_SUM = 'sparePartsSum',
  SPARE_PARTS_COUNTERPARTY_NAME = 'sparePartsCounterpartyName',
  TOTAL_SPARE_PARTS_SUM = 'total-spare-parts-sum',
}

export { SelectSparePartsTableTranslationKey };
