import { CBadge, CNav, CNavItem, CNavLink } from '@coreui/react-pro';
import { CarServiceDto } from 'common/types/types';
import { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  carServices: CarServiceDto[],
  activeTabId: number | undefined,
  handleTabClick: (id: number) => void,
  notCompletedRequests?: Record<number, number>,
};
/**
 * @deprecated Use NavTabs instead
 */
const CarServicesNavTabs: FC<Props> = ({
  carServices,
  activeTabId,
  handleTabClick,
  notCompletedRequests = null,
}) => {
  const CAR_SERVICE_NAME_MAX_LENGTH = 20;

  return (
    <CNav variant="tabs" className="card-header-tabs">
      {
        carServices.map((carService) =>
          <CNavItem key={carService.id} className={styles.carServiceTab}>
            <CNavLink
              className={activeTabId !== carService.id ? styles.tab : styles.activeTab}
              href="#"
              active={activeTabId === carService.id}
              onClick={(): void => handleTabClick(carService.id)}
            >
              { carService.name.length > CAR_SERVICE_NAME_MAX_LENGTH ?
                carService.name.slice(0, CAR_SERVICE_NAME_MAX_LENGTH - 3) + '...' :
                carService.name }
              <CBadge
                className={styles.badge}
                color="primary"
                position="top-end"
                shape="rounded"
              >
                {notCompletedRequests?.[carService.id] ?? null}
              </CBadge>
            </CNavLink>
          </CNavItem>,
        )
      }
    </CNav>
  );
};

export { CarServicesNavTabs };
