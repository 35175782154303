enum AppTranslationKey {
  CAR_SERVICE_AUTO = 'carServiceAuto',
  CAR_SERVICE = 'carService',
  CAR_SERVICE_CUSTOMER = 'carServiceCustomer',
  CAR_SERVICE_ORDER = 'order',
  CAR_MODAL = 'carModal',
  RECOVERY_PASSWORD= 'recoveryPassword',
  SUPPLIERS = 'suppliers',
  STOCK = 'stock',
  STOCK_DETAILS = 'stockDetails',
  CAR_WORK = 'carWork',
  SPARE_PARTS = 'spare-parts',
  GDRP_MODAL = 'gdrp_modal',
  AUTH = 'auth',
  WORK_ORDER = 'workOrder',
  ORDER_MODAL = 'orderModal',
  TARIFFS = 'tariffs',
  PROFILE = 'profile',
  SELECT_ORDER_MODAL = 'selectOrderModal',
  SELECT_WORK_MODAL = 'selectWorkModal',
  HELP = 'help',
  SELECT_SPARE_PARTS_MODAL = 'selectSparePartsModal',
  SELECT_SPARE_PARTS_TABLE = 'select-spare-parts-table',
  COMMON = 'common',
  DANGER_ALERT_MODAL = 'dangerAlertModal',
  INFO_VIN = 'infoVin',
  ERROR_PAGE = 'errorPage',
  NOT_FOUND_PAGE = 'notFoundPage',
  ORDER_CERTIFICATE = 'orderCertificate',
  CLIENT_SPARE_PARTS = 'clientSpareParts',
  FEED_BACK_MODAL = 'feedBackModal',
  HELP_ABOUT_REQUEST = 'helpAboutRequest',
  EMPLOYEES_PROFILES = 'employeesProfiles',
  SELECT_COMPONENT = 'selectComponent',
  SALARY = 'salary',
}

export { AppTranslationKey };
