import { CPopover, CSmartTable } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { BindOrder, OrderDto } from 'common/types/types';
import { CarStateNumber, NoData, StatusBadge } from 'components/common/common';
import { useMemo } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getTableColumns, getTableData } from './helpers/helpers';
import styles from './style.module.scss';

type Props = {
  orders: OrderDto[],
  onSelectOrder: (item: BindOrder[]) => void,
  selectedOrder: BindOrder[] | null,
};

const SelectOrdersTable: FC<Props> = ({ orders, onSelectOrder, selectedOrder }) => {

  const columns = useMemo(() => getTableColumns(),[]);
  const data = useMemo(() => getTableData(orders), [orders]);
  const { t } = useTranslation([AppTranslationKey.COMMON]);

  return (
    <CSmartTable
      columns={ columns }
      items={ data }
      loading={ false }
      itemsPerPageSelect
      itemsPerPageLabel={t('itemPerPageLabel') ?? 'Елементів на сторінці'}
      noItemsLabel={
        <NoData
          title={t('noItemsFoundTitle')}
          message={t('noItemsFoundMsg')}
        />
      }
      selectable
      selectAll={ false }
      onSelectedItemsChange={ (items):void => onSelectOrder(items as BindOrder[]) }
      selected={ selectedOrder ?? [] }
      columnFilter
      columnSorter
      sorterValue={{ column: 'dateTime', state: 'desc' }}
      pagination
      scopedColumns={{
        comment: (item: BindOrder) => (
          <td style={{ maxWidth: 0 }}>
            <CPopover
              content={item.comment}
              placement="right"
              trigger="hover"
            >
              <div className={styles.commentColumn}>
                {item.comment}
              </div>
            </CPopover>
          </td>
        ),
        carInfo: (item: BindOrder) => (
          <td>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems:'center' }}>
              <div style={{ fontWeight: 'bold' }}>
                {item.carInfo?.slice(0,2)}
              </div>
              <CarStateNumber carStateNum={item?.carInfo?.[2]} />
            </div>
          </td>
        ),
        statusName: (item: BindOrder) => (
          <td>
            <StatusBadge
              color={item.statusColor}
              title={item.statusName}
              textColor={item.statusTextColor}
            />
          </td>
        ),
      }}
    />
  );
};

export { SelectOrdersTable };
