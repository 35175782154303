import { CarWorkFavModalFormData } from 'common/types/types';

const DEFAULT_CAR_WORK_FAV_MODAL_PAYLOAD: CarWorkFavModalFormData = {
  carServiceId: 0,
  serviceWorkId: 0,
  serviceWorkTypeId: 0,
  serviceWorkName: '',
  serviceWorkTypeName: '',
  requiredTimeMinutes: 0,
  serviceWorkCost: 0,
  serviceWorkInfo: '',
};

export { DEFAULT_CAR_WORK_FAV_MODAL_PAYLOAD };
