import CIcon from '@coreui/icons-react';
import { CCard, CCardBody, CCol, CRow, CWidgetStatsF } from '@coreui/react-pro';
import { WithCarServicesTabs } from 'common/types/car-service/with-car-services-tabs';
import { Spinner } from 'components/common/common';
import { withCarServicesTabs } from 'hoc/hoc';
import { useAppDispatch, useAppSelector, useEffect } from 'hooks/hooks';
import { t as tt } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { widgetActions } from 'store/actions';
import { clearWidgetData } from 'store/widget/reducer';

import { getDashboardConfig } from './helpers/helpers';

const Dashboard: FC<WithCarServicesTabs> = ({ activeCarService }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('widgets');

  const { userId, widgetsData } = useAppSelector(({ widget, auth }) => ({
    widgetsData: widget.widgetsData,
    userId: auth.currentUser?.id ?? 0,
  }));

  useEffect(() => {
    if(activeCarService?.id) {
      dispatch(clearWidgetData());

      if (activeCarService?.id === -1) {
        dispatch(widgetActions.getWidgetsData({ userId }));
      } else {
        dispatch(widgetActions.getAllRequestsByCarServiceId(activeCarService?.id));
        dispatch(widgetActions.getAllCarsByCarServiceId(activeCarService.id));
        dispatch(widgetActions.getNotCompletedTasksByCarServiceId(activeCarService.id));
      }
    }
  }, [dispatch, userId, activeCarService]);

  return (
    <CCard>
      <CCardBody >
        <>
          {
            getDashboardConfig.map((row, index) => (
              <CRow key={index}>
                {
                  row.map((item) => (
                    <CCol md={4} key={item.titleKey}>
                      <CWidgetStatsF
                        className="mb-3 border"
                        color="primary"
                        icon={<CIcon icon={item.icon} height={24} />}
                        title={t(item.titleKey)}
                        value={widgetsData[item.fieldData] ?? <Spinner />}
                      />
                    </CCol>
                  ))
                }
              </CRow>
            ))
          }
        </>
      </CCardBody>
    </CCard>
  );
};

export const DashboardWithCarServiceTabs = withCarServicesTabs(
  Dashboard,
  false,
  undefined,
  () => tt('widgets:title'),
  true,
);
