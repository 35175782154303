import { CCol, CContainer, CRow } from '@coreui/react-pro';
import { AppTranslationKey, NotFoundPageTranslationKey } from 'common/enums/enums';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Page404: FC = () => {
  const { t } = useTranslation(AppTranslationKey.NOT_FOUND_PAGE);

  return (
    <CContainer style={{ height: 500, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <CRow className="justify-content-center">
        <CCol md={4}>
          <div className="clearfix">
            <h1 className="float-start display-3 me-4">404</h1>
            <h4 className="pt-3">{t(NotFoundPageTranslationKey.TITLE)}</h4>
            <p className="text-medium-emphasis float-start">
              {t(NotFoundPageTranslationKey.MSG)}
            </p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export { Page404 };
