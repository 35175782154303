enum EmployeesProfilesKey {
  TITLE = 'title',
  ADD_BUTTON_TITLE = 'addButtonTitle',
  NO_UNIT_TITLE = 'noUnitTitle',
  NO_UNIT_TEXT = 'noUnitText',
  MODAL_TITLE = 'modalTitle',
  FIELDS = 'fields',
  FIRST_NAME = 'firstName',
  SECOND_NAME = 'secondName',
  PATRONYMIC_NAME = 'patronymicName',
  POSITION = 'position',
  REGISTRATION_DATE = 'registrationDate',
  STATUS = 'status',
  DISMISSAL_DATE = 'dismissalDate',
  METHOD_OF_SALARY = 'methodSfSalary',
  BIRTHDATE = 'birthdate',
  PHONE = 'phone',
  VIBER = 'viber',
  TELEGRAM = 'telegram',
  EMAIL = 'email',
  ADD_INFO = 'addInfo',
  PERCENT_WORKS = 'percentWorks',
  PERCENT_SPARES = 'percentSpares',
  HOURLY_RATE = 'hourlyRate',
  FIXED_RATE = 'fixedRate',
  PERCENT_WORKS_V2 = 'percentWorksV2',
  PERCENT_SPARES_V2 = 'percentSparesV2',
  HOURLY_RATE_V2 = 'hourlyRateV2',
  FIXED_RATE_V2 = 'fixedRateV2',
  CONTACTS = 'contacts',
  WORK_PERIOD = 'workPeriod',
  REQUIRED_FORM_FIELD = 'requiredFormField',
  INVALID_PHONE_NUMBER = 'invalidPhoneNumber',
  EMAIL_ERROR_TEXT = 'emailErrorText',
  COLUMNS = 'columns',
  FULL_NAME = 'fullName',
  SUCCESS_UPDATE_MESSAGE = 'successUpdateMessage',
}

export { EmployeesProfilesKey };
