enum LocalStorageKey {
  TOKEN = 'token',
  REFRESH_TOKEN = 'refreshToken',
  USER_ID = 'userId',
  USER = 'user',
  OPEN_LEFT_MENU = 'openLeftMenu',
  UNFOLDABLE_LEFT_MENU = 'UNFOLDABLE_LEFT_MENU',
  CAR_SERVICE_STATE = 'car-service-state',
  PERSIST_PAGINATION_STATE = 'persist-pagination-state',
  ORDER_FILTERS = 'orderFilters',
  WORK_ORDER_FILTERS = 'workOrderFilters',
  DICTIONARIES_UK = 'dictionariesUk',
  DICTIONARIES_ENG = 'dictionariesEng',
  DICTIONARIES_RU = 'dictionariesRu',
  ACTIVE_CAR_SERVICE_TAB = 'activeCarServiceTab',
  ACTIVE_STORAGE_TAB = 'activeStorageTab',
}

export { LocalStorageKey };
