import {
  CButton,
  CCardBody,
  CCardTitle,
  CFormInput,
  CFormSelect,
} from '@coreui/react-pro';
import { AppTranslationKey, NotificationType } from 'common/enums/enums';
import { CarServiceProfileFormData } from 'common/types/types';
import { PassLogo } from 'components/car-services-profiles/common/type/type';
import { Icon } from 'components/common/common';
import { TextArea } from 'components/textarea/text-area';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { appActions } from 'store/actions';

import styles from './styles.module.scss';

type Props = {
  register: UseFormRegister<CarServiceProfileFormData>;
  disabled?: boolean;
  photoUrl: string | null;
  value: string;
  errors: FieldErrors<CarServiceProfileFormData>;
  setLogo: (logo: PassLogo) => void;
};

const InfoCard: FC<Props> = ({ register, disabled = false, value, photoUrl, errors, setLogo }) => {

  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE);
  const dispatch = useAppDispatch();

  const {
    carServiceStatuses,
  } = useAppSelector(({ dictionary }) =>
    ({
      carServiceStatuses: dictionary.data?.carServiceStatuses || [],
    }),
  );

  const handleAttachLogo = (): void => {
    document.getElementById('fileInput')?.click();
  };

  const handleRemoveLogo = (): void => {

    setLogo({
      name: '',
      base64: '',
    });
  };

  const handleChange = (): void => {
    const fileInput = document.getElementById('fileInput');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const file = (fileInput as HTMLInputElement).files[0];

    if(file.size / 1024 / 1024 > 25) {
      dispatch(appActions.notify({
        message: t('fileOversizeError'),
        type: NotificationType.ERROR,
      }));
    }

    const reader = new FileReader();
    reader.onload = function(event): void {
      const result = event.target?.result;

      if (typeof result === 'string') {
        setLogo({
          name: file.name,
          base64: result,
        });
      } else {
        // eslint-disable-next-line no-console
        console.error('Очікувався рядок, але отримано інший формат.');
      }
    };

    reader.readAsDataURL(file);

  };

  return (
    <div className={styles.cardContainer}>
      <CCardTitle className={styles.cardTitle}>{t('infoCardTitle')}</CCardTitle>
      <CCardBody>
        <div style={{ display: 'flex' }}>
          <div>
            <div style={{ paddingBottom: 8 }}>{t('logo')}</div>
            <div className={styles.photoContainer}>
              {photoUrl ? (
                <img className={styles.icon} src={photoUrl ?? ''} alt="" />
              ) : (
                <Icon name={'photo'} className={styles.icon} />
              )}
            </div>
            <div
              style={{
                display: disabled ? 'none' : 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <input
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                id="fileInput"
                onChange={handleChange}
              />
              <CButton
                className="mt-2 w-100"
                color="secondary"
                size="sm"
                onClick={handleAttachLogo}
              >
                {t('addLogoButton')}
              </CButton>
              {
                photoUrl ? <CButton
                  className="mt-2 w-100"
                  color="danger"
                  variant="ghost"
                  size="sm"
                  onClick={handleRemoveLogo}
                >
                  {t('removeLogoButton')}
                </CButton> : ''
              }
            </div>
          </div>
          <div style={{ flexGrow: 2, paddingLeft: 16 }}>
            <div>
              <CFormInput
                {...register('name', {
                  required:
                    t('carServiceFormFieldNameErrorRequired') ?? 'Required',
                })}
                disabled={disabled}
                type="text"
                label={t('carServiceFormFieldName')}
                name="name"
                id="carServiceId"
                invalid={Boolean(errors.name)}
                feedbackInvalid={errors.name?.message}
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <CFormSelect
                {...register('statusId', {
                  required:
                    t('carServiceFormFieldNameErrorRequired') ??
                    'This field is required',
                })}
                disabled={disabled}
                name="statusId"
                label={t('carServiceFormFieldStatus')}
                invalid={Boolean(errors.statusId)}
                feedbackInvalid={errors.statusId?.message}
              >
                {carServiceStatuses.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                  </option>
                ))}
              </CFormSelect>
            </div>
            <div style={{ marginTop: 15 }}>
              <CFormInput
                {...register('address', {
                  required:
                    t('carServiceFormFieldAddressErrorRequired') ?? 'Required',
                })}
                disabled={disabled}
                type="text"
                label={t('carServiceFormFieldAddress')}
                invalid={Boolean(errors.address)}
                feedbackInvalid={errors.address?.message}
                name="address"
                id="carServiceAddress"
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <TextArea<CarServiceProfileFormData>
                label={t('carServiceFormFieldInfo')}
                id="carServiceInfo"
                register={register}
                limitSymbols={1024}
                rows={5}
                nameFiled="info"
                value={value}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </CCardBody>
    </div>
  );
};

export { InfoCard };
