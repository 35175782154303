enum ActionType {
  GET_MODELS = 'car/get-models',
  GET_BODY_TYPES = 'car/get-body-types',
  ADD_CAR = 'car/add-car',
  GET_CARS = 'car/get-cars',
  GET_CAR_BY_ID = 'car/get-car-by-id',
  UPDATE_CAR_BY_ID = 'car/update-car-by-id',
  SEARCH = 'car/search',
}

export { ActionType };
