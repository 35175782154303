import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';
import {
  AppRoute,
  AppTranslationKey,
  DataStatus,
  LocalStorageKey,
  NotificationType,
  Pages,
  PersonalRoute,
} from 'common/enums/enums';
import { CarServiceDto, WithCarServicesTabs } from 'common/types/types';
import {
  FilterPanel,
  FilterPanelContextProvider,
  NavTabs,
  NoDataWithAction,
  Spinner,
  usePermissionToastContext,
} from 'components/common/common';
import { Page404 } from 'components/page-404/page-404';
import { Tariffs } from 'constants/tariffs';
import { extraButtonTitle, useExtraButtonContext } from 'contexts';
import { useAppDispatch, useAppSelector, useCarServices, useEffect, useNavigate, useSearchParams } from 'hooks/hooks';
import { toNumber } from 'lodash';
import React, { ComponentType, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { appActions } from 'store/actions';

import { getValidClasses } from '../helpers/helpers';
import { storage } from '../services/services';
import styles from './styles.module.scss';

export const withCarServicesTabs = (
  WrappedComponent: ComponentType<WithCarServicesTabs>,
  isFilter = false,
  typePage?: Pages,
  getTitle = (): string => '',
  showAllTab = false,
) => {

  return (): ReactElement => {
    const dispatch = useAppDispatch();
    const [ tabId ] = useSearchParams();
    const { t } = useTranslation(AppTranslationKey.CAR_SERVICE);
    const {
      userId,
      carServices,
      dataStatus,
      openRequests,
      openWorkOrders,
    } = useAppSelector(({ auth, carServices, widget }) => (
      {
        userId: auth.currentUser?.id ?? 0,
        carServices: carServices.userCarServices,
        dataStatus: carServices.dataStatus,
        openRequests: widget.openRequests,
        openWorkOrders: widget.openWorkOrders,
      }
    ));
    const navigate = useNavigate();
    const { activeCarService, handleActiveCarServiceChange, getActiveCarServiceById } = useCarServices({ userId });
    const isLoading = dataStatus === DataStatus.PENDING;
    const { onPermissionAction } = usePermissionToastContext();
    const { setExtraButton, removeExtraButton } = useExtraButtonContext();
    const getExtendedUserCarService = (carServices: CarServiceDto[]): CarServiceDto[] => {
      if (carServices.length > 1) {
        return [...[{
          id: -1,
          name: t('allCarServiceText'),
          info: '',
          address:'',
          notCompletedRequests: 0,
          profilePhoto: '',
          contacts: null,
          requisites: null,

        }], ...carServices];
      }

      return carServices;
    };
    const userCarServices = showAllTab ? getExtendedUserCarService(carServices) : carServices;

    useEffect(() => {
      if (userCarServices.length === 0 && typePage && extraButtonTitle[typePage]) {
        setExtraButton({
          isHide: false,
          translateTitleKey: extraButtonTitle[typePage],
          action: () => {
            if (typePage === Pages.CAR_SERVICE) {
              onPermissionAction(
                () => navigate(PersonalRoute.ADD_CAR_SERVICE.split('/').pop() ?? 'add'),
                Tariffs.NO_TARIFF,
              )();
            } else {
              dispatch(appActions.notify({ type: NotificationType.ERROR, message: t('errorMessageNoCarService') }));
            }
          },
        });
      }

      return () => {
        removeExtraButton();
      };
    }, [carServices.length, typePage]);

    useEffect(() => {
      const storageCarServiceId = storage.getItem(LocalStorageKey.ACTIVE_CAR_SERVICE_TAB);
      const carServiceTabId: string | null =
        userCarServices.some((it) => it.id === toNumber(storageCarServiceId)) ?
          storageCarServiceId : '-1';

      if (carServiceTabId === '-1'){
        handleActiveCarServiceChange(userCarServices[0]);

      } else {

        if (tabId.get('tabId') !== null) {
          const carService = getActiveCarServiceById(userCarServices, parseInt(tabId.get('tabId') ?? '-1'));
          handleActiveCarServiceChange(carService);
        } else {
          carServiceTabId ?
            handleActiveCarServiceChange(getActiveCarServiceById(userCarServices, +carServiceTabId)) :
            handleActiveCarServiceChange(userCarServices[0]);
        }
      }
    }, [ getActiveCarServiceById, handleActiveCarServiceChange, tabId, carServices ]);

    const handleTabClick = (id: number): void => {
      const carService = getActiveCarServiceById(userCarServices, id);
      handleActiveCarServiceChange(carService);
    };

    const badgeText = typePage === Pages.ORDERS ? openRequests :
      typePage === Pages.WORK_ORDERS ? openWorkOrders : {};

    const handleAddCarService = (): void => {
      navigate(`${AppRoute.PERSONAL}/${PersonalRoute.ADD_CAR_SERVICE}`);
    };

    if (isLoading) {
      return <Spinner isOverflow containerHeight={250}/>;
    }

    if (activeCarService === null) {
      return <Page404 />;
    }

    if (userCarServices.length === 0) {
      return <NoDataWithAction
        title={t('noCarServiceTitle')}
        message={t('noCarServiceText')}
        actionName={t('addCarServiceCaptionButton')}
        callback={onPermissionAction(handleAddCarService, Tariffs.NO_TARIFF)}
      />;
    }

    const page = (
      <CCard>
        <CCardHeader className="d-flex flex-column px-lg-0">
          <h5 className={getValidClasses(styles.headerTitle, 'text-dark')}>{ getTitle() }</h5>
          <div style={{ paddingLeft: 16 }}>
            <NavTabs
              tabs={ userCarServices }
              activeTabId={ activeCarService?.id }
              handleTabClick={ handleTabClick }
              badgeText={ badgeText }
            />
          </div>
        </CCardHeader>
        { isFilter && typePage && <FilterPanel /> }
        <CCardBody style={ { overflowX: 'auto', minHeight: 150 }}>
          <WrappedComponent activeCarService={activeCarService}/>
        </CCardBody>
      </CCard>
    );

    if (isFilter && (typePage === Pages.WORK_ORDERS || typePage === Pages.ORDERS)) {
      return (
        <FilterPanelContextProvider activeCarServiceId={ activeCarService?.id } page={ typePage } >
          { page }
        </FilterPanelContextProvider>
      );
    }

    return page;
  };
};
