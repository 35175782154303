import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

const portalContainer = document.getElementById('portal-container') || document.body;

type Props = {
  children: ReactNode,
};

const PortalWrapper: FC<Props> = ({ children }) => createPortal(
  children,
  portalContainer,
);

export { PortalWrapper };
