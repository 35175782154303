import { CCardBody, CCardTitle, CFormInput } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { CarServiceProfileFormData } from 'common/types/types';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  register: UseFormRegister<CarServiceProfileFormData>,
  disabled?: boolean,
};

const ContactCard: FC<Props> = ({ register, disabled }) => {

  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE);

  return (
    <div className={styles.cardContainer}>
      <CCardTitle className={styles.cardTitle}>{t('contactsCardTitle')}</CCardTitle>
      <CCardBody>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <CFormInput
                {...register('mainContactPhone.contactData')}
                disabled={disabled}
                type="text"
                label={t('mainPhone')}
                name="mainContactPhone.contactData"
              />
            </div>
            <div style={{ width: '45%' }}>
              <CFormInput
                {...register('contactPhone.contactData')}
                disabled={disabled}
                type="text"
                label={t('phone')}
                name="contactPhone.contactData"
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 15,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '45%' }}>
              <CFormInput
                {...register('contactViber.contactData')}
                disabled={disabled}
                type="text"
                label={t('viber')}
                name="contactViber.contactData"
              />
            </div>
            <div style={{ width: '45%' }}>
              <CFormInput
                {...register('contactTelegram.contactData')}
                disabled={disabled}
                type="text"
                label={t('telegram')}
                name="contactTelegram.contactData"
              />
            </div>
          </div>
          <div style={{ marginTop: 18 }}>
            <CFormInput
              {...register('contactEmail.contactData')}
              disabled={disabled}
              type="text"
              label={t('email')}
              name="contactEmail.contactData"
            />
          </div>
        </div>
      </CCardBody>
    </div>
  );
};

export { ContactCard };
