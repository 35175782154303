import {
  AppTranslationKey,
  WorkOrderModalTranslationKey,
  WorkOrderTranslationKey,
} from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getSparePartsRowTableColumns = (): TableColumn[] => [
  {
    key: 'sparePartName',
    label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.WORK_ORDER_MODAL}:${WorkOrderModalTranslationKey.SPARE_PART_NAME_TABLE_FIELD}`),
    _style: { width: '58%' },
  },
  {
    key: 'count',
    label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.WORK_ORDER_MODAL}:${WorkOrderModalTranslationKey.QTY_TABLE_FIELD}`),
    _style: { width: '17%' },
  },
  {
    key: 'priceOne',
    label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.WORK_ORDER_MODAL}:${WorkOrderModalTranslationKey.COST_TABLE_FIELD}`),
    _style: { width: '10%' },
  },
  {
    key: 'priceTotal',
    label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.WORK_ORDER_MODAL}:${WorkOrderModalTranslationKey.SUM_TABLE_FIELD}`),
    _style: { width: '10%' },
  },
  {
    key: 'remove',
    label: '',
    _style: { width: '5%' },
  },
];

export { getSparePartsRowTableColumns };
