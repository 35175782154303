import { cilEnvelopeClosed, cilPhone } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { ENV } from 'common/enums/enums';
import { getValidClasses } from 'helpers/helpers';
import { FC } from 'react';

import styles from './styles.module.scss';

const ContactUs: FC = () => {
  return (
    <div className={getValidClasses(styles.contact, 'pt-3 d-flex flex-wrap justify-content-between')}>
      <div>
        <CIcon className="mx-1" icon={cilPhone} size="sm"/>
        <a href={`tel:${ENV.SUPPORT_PHONE.replace(/\s+/g, '')}`}>{ENV.SUPPORT_PHONE}</a>
      </div>
      <div>
        <CIcon className="mx-1 mb-0" icon={cilEnvelopeClosed} size="sm"/>
        <a href={`mailto:${ENV.SUPPORT_EMAIL}`}
          title="Відправити листа">{ENV.SUPPORT_EMAIL}</a>
      </div>
    </div>
  );
};

export { ContactUs };
