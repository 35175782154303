import { useState } from 'hooks/hooks';
import React, { FC } from 'react';
import PhoneInputComponent, { CountryData } from 'react-phone-input-2';

const DEFAULT_COUNTRY = 'ua';
const DEFAULT_COUNTRY_CODE = '380';

interface IPhoneInput {
  id: string,
  name: string,
  value: string,
  containerClass?: string,
  inputClass?: string,
  disabled?: boolean,
  onChange: (value: string) => void,
}

const PhoneInput: FC<IPhoneInput> = ({
  id,
  name,
  value,
  containerClass,
  inputClass,
  disabled = false,
  onChange,
}) => {
  const [dialCode, setDialCode] = useState(DEFAULT_COUNTRY_CODE);

  const onChangePhone = (value: string, data: object | CountryData): void => {
    const dialCodeStr = 'dialCode' in data ? data.dialCode : '';
    setDialCode(dialCode);
    onChange(dialCodeStr === value ? '' : value);
  };

  return (
    <PhoneInputComponent
      inputProps={ { id, name } }
      onChange={ onChangePhone }
      value={ value || dialCode }
      country={ DEFAULT_COUNTRY }
      placeholder=""
      countryCodeEditable={false}
      enableSearch
      disableSearchIcon
      containerClass={ containerClass }
      inputClass={ inputClass }
      disabled={ disabled }
    />
  );
};

export { PhoneInput };
