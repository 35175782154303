enum ActionType {
  SIGN_UP = 'auth/sign-up',
  SIGN_IN = 'auth/sign-in',
  LOG_OUT = 'auth/log-out',
  GET_CURRENT_USER = 'auth/get-current-user',
  REFRESH_TOKEN = 'auth/refresh-token',
  RECOVERY_PASSWORD = 'auth/recovery-password',
  SET_PASSWORD = 'auth/set-password',
  VALIDATE_USER = 'auth/validate-user',
  RESEND_VALIDATION = 'auth/resend-validation',
}

export { ActionType };
