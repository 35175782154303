import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react-pro';
import ucsLogo from 'assets/img/ucs-logo.png';
import { AppTranslationKey, DataStatus, NotificationType, RecoveryPasswordTranslationKey } from 'common/enums/enums';
import { RecoveryPasswordRequest } from 'common/types/types';
import { MessageStatus } from 'components/auth/common/enums/message-status.enum';
import { Message } from 'components/auth/components/message/message';
import { useAppDispatch, useAppSelector, useState } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { appActions, authActions } from 'store/actions';

import { ContactUs } from '../components';
import { RecoveryPasswordForm } from './components/components';
import styles from './style.module.scss';

const RecoveryPassword: FC = () => {
  const [ isSendRequest, setIsSendRequest ] = useState(false);

  const { recoveryPassRequestStatus } = useAppSelector((state) => state.auth);

  const { t } = useTranslation(AppTranslationKey.RECOVERY_PASSWORD);

  const isLoading = recoveryPassRequestStatus === DataStatus.PENDING;

  const dispatch = useAppDispatch();
  const handleRecoveryPassSubmit = ( payload: RecoveryPasswordRequest ): void => {
    dispatch(authActions.recoveryPasswordSendEmail(payload))
      .unwrap()
      .then(() => setIsSendRequest(true))
      .catch( () =>
        dispatch(appActions.notify({
          type: NotificationType.ERROR,
          message: t(RecoveryPasswordTranslationKey.REQUEST_ERROR_MSG),
        })),
      );

  };

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-3">
                <CCardBody className="px-4 py-2">
                  <h3 style={{ textAlign: 'center', fontSize: 30, paddingBottom: 20 }}>
                    {t(RecoveryPasswordTranslationKey.TITLE_RECOVERY_PASSWORD)}
                  </h3>
                  {isSendRequest ?
                    <Message
                      message={t(RecoveryPasswordTranslationKey.CONFIRM_MSG)}
                      msgType={MessageStatus.DEFAULT} /> :
                    <RecoveryPasswordForm onSubmit={handleRecoveryPassSubmit} isLoading={isLoading}/>}
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary p-3" style={{ width: '100%' }}>
                <CCardBody className="text-center">
                  <div style={{ fontSize: 16 }}>
                    <img className={styles.ucsLogo} src={ucsLogo} alt={'UCS Logo'}/>
                    <p style={{ fontSize: 22 }}>{t(RecoveryPasswordTranslationKey.WELCOME_TEXT)}</p>
                    <p style={{ borderBottom: 1, borderBottomStyle: 'solid', paddingBottom: 10 }}>
                      {t(RecoveryPasswordTranslationKey.RECOVERY_PASS_TEXT)}
                    </p>
                    <div className="pt-3">
                      <p>{t(RecoveryPasswordTranslationKey.ANY_DIFFICULTY)}</p>
                      <span>{t(RecoveryPasswordTranslationKey.CALL_ME)}</span>
                    </div>
                    <ContactUs />
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export { RecoveryPassword };
