import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { EmployeeItem } from 'common/types/types';

import { getEmployee, getEmployees } from './actions';

type State = {
  employees: EmployeeItem[],
  currentEmployee?: EmployeeItem,
  dataStatusEmployees: DataStatus,
  dataStatusCurrentEmployee: DataStatus,
};

const initialState: State = {
  employees: [],
  currentEmployee: undefined,
  dataStatusEmployees: DataStatus.IDLE,
  dataStatusCurrentEmployee: DataStatus.IDLE,
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    resetCurrentEmployee: (state) => {
      state.currentEmployee = undefined;
      state.dataStatusCurrentEmployee = DataStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // get employees
      .addCase(getEmployees.pending, (state) => {
        state.dataStatusEmployees = DataStatus.PENDING;
      })
      .addCase(getEmployees.rejected, (state) => {
        state.dataStatusEmployees = DataStatus.REJECTED;
        state.employees = [];
      })
      .addCase(getEmployees.fulfilled, (state, { payload }) => {
        state.dataStatusEmployees = DataStatus.FULFILLED;
        state.employees = payload;
      })
    // get employee by id
      .addCase(getEmployee.pending, (state) => {
        state.dataStatusCurrentEmployee = DataStatus.PENDING;
      })
      .addCase(getEmployee.rejected, (state) => {
        state.dataStatusCurrentEmployee = DataStatus.REJECTED;
        state.currentEmployee = undefined;
      })
      .addCase(getEmployee.fulfilled, (state, { payload }) => {
        state.dataStatusCurrentEmployee = DataStatus.FULFILLED;
        state.currentEmployee = payload;
      });
  },
});

const employeeReducer = employeesSlice.reducer;
const { resetCurrentEmployee } = employeesSlice.actions;

export { employeeReducer, resetCurrentEmployee };
