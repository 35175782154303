import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CSmartTable } from '@coreui/react-pro';
import { Column, Item, ScopedColumns } from '@coreui/react-pro/dist/components/smart-table/types';
import cn from 'classnames';
import { NoData } from 'components/common/common';
import { getValidClasses } from 'helpers/helpers';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  columns: Column[]
  data: Item[],// <=== you can add a canRemove field to control the visibility of the remove button
  onSelectClick?: () => void,
  onRemoveClick: (item: Item) => void,
  scopedColumns?: ScopedColumns,
  noDataMsg?: string,
  isDisable?: boolean,
  totalLabel?: React.ReactElement,
};

const TableField: FC<Props> = ({
  columns,
  data,
  onSelectClick,
  onRemoveClick,
  scopedColumns,
  noDataMsg = 'No data',
  isDisable = false,
  totalLabel,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={ styles.fieldWrapper }>
      {data.length > 0 ?
        <div className={ styles.tableWrapper }>
          <CSmartTable
            columns={ columns }
            items={ data }
            itemsPerPage={data.length}
            tableProps={ { className: styles.table } }
            scopedColumns={{
              remove: (item: Item) => <td style={{ textAlign: 'right' }}>
                { isDisable || ((item?.canRemove ?? true) && <CIcon
                  className={ getValidClasses(isDisable || styles.removeButton, 'text-danger') }
                  size="xl"
                  icon={ cilTrash }
                  onClick={ (): void => onRemoveClick(item) }
                />) }</td>,
              ...scopedColumns,
            }}
          />
        </div> :
        <NoData title={noDataMsg} message="" />
      }
      <div className={ cn(styles.tableFooter, { [styles.alignRight]: onSelectClick && !totalLabel }) }>
        { totalLabel }
        {
          onSelectClick && <CButton
            disabled={isDisable}
            color={isDisable ? 'dark' : 'primary'}
            variant="outline"
            className={styles.selectButton}
            onClick={onSelectClick}
          >
            { t('selectBtn') }
          </CButton>
        }
      </div>
    </div>
  );
};

export { TableField };
