import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCard,
  CCardBody,
  CCardHeader,
} from '@coreui/react-pro';
import { AppTranslationKey, HelpAboutRequestKey } from 'common/enums/enums';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

const AboutRequest: FC = () => {
  const { t } = useTranslation([AppTranslationKey.HELP_ABOUT_REQUEST, 'mainMenu']);

  return (
    <CCard>
      <CCardHeader>
        <h5 className="text-dark">{ t('allAboutRequest', { ns: 'mainMenu' }) }</h5>
      </CCardHeader>
      <CCardBody className={ styles.bodyContainer }>
        <CAccordion>
          <CAccordionItem itemKey={ 1 }>
            <CAccordionHeader>{ t(HelpAboutRequestKey.TITLE) }</CAccordionHeader>
            <CAccordionBody className={ styles.accordionBodyContainer }>
              <div>{ t(HelpAboutRequestKey.DESCRIPTION) }</div>
              <div className={ styles.listsBlockContainer }>
                <div className={ styles.listBlock }>
                  <div>{ t(HelpAboutRequestKey.ACTIVE_STATUSES_TITLE) }</div>
                  <ul>
                    <li>{ t(HelpAboutRequestKey.A_IN_WORK) }</li>
                    <li>{ t(HelpAboutRequestKey.A_WAIT_SPARE_PARTS) }</li>
                    <li>{ t(HelpAboutRequestKey.A_IN_WORK_CONTRACTOR) }</li>
                    <li>{ t(HelpAboutRequestKey.A_WORKS_FINISHED) }</li>
                    <li>{ t(HelpAboutRequestKey.A_WAIT_PAY) }</li>
                    <li>{ t(HelpAboutRequestKey.A_WAIT_GIVE_CAR) }</li>
                  </ul>
                </div>
                <div className={ styles.listBlock }>
                  <div>{ t(HelpAboutRequestKey.NO_ACTIVE_STATUSES_TITLE) }</div>
                  <ul>
                    <li>{ t(HelpAboutRequestKey.NA_NEW) }</li>
                    <li>{ t(HelpAboutRequestKey.NA_WAIT_AGREEMENT) }</li>
                    <li>{ t(HelpAboutRequestKey.NA_AGREEMENT) }</li>
                    <li>{ t(HelpAboutRequestKey.NA_WAIT_RECEIVE_CAR) }</li>
                    <li>{ t(HelpAboutRequestKey.NA_WAIT_WORK) }</li>
                    <li>{ t(HelpAboutRequestKey.NA_CAR_GAVE) }</li>
                    <li>{ t(HelpAboutRequestKey.NA_CLOSE) }</li>
                    <li>{ t(HelpAboutRequestKey.NA_CANCEL) }</li>
                  </ul>
                </div>
              </div>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CCardBody>
    </CCard>
  );
};

export { AboutRequest };
