import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AsyncThunkConfig, CountResponse, GetOpenWorkOrdersRequest,
  NotCompletedTasksByUserIdRequest,
  NotCompletedTasksByUserIdResponse,
  OpenRequest, OpenWorkOrder,
  WidgetsData,
} from 'common/types/types';

import { ActionType } from './common';

const getWidgetsData = createAsyncThunk<
  WidgetsData,
  { userId: number },
  AsyncThunkConfig
>(ActionType.GET_WIDGETS_DATA, async (payload, { extra }) => {
  const { widgetApi } = extra;

  return widgetApi.getWidgetsData(payload);
});

const getNotCompletedTasksByUserId = createAsyncThunk<
  NotCompletedTasksByUserIdResponse[],
  NotCompletedTasksByUserIdRequest,
  AsyncThunkConfig
>( ActionType.GET_NOT_COMPLETED_TASKS_BY_USER_ID, async  (payload, { extra }) => {
  const { widgetApi } = extra;

  return widgetApi.getNotCompletedTasksByUserId(payload);
});

const getOpenRequests = createAsyncThunk<
  OpenRequest[],
  NotCompletedTasksByUserIdRequest,
  AsyncThunkConfig
>(ActionType.GET_OPEN_REQUESTS, async (payload, { extra }) => {
  const { widgetApi } = extra;

  return widgetApi.getOpenRequests(payload);
});

const getOpenWorkOrders = createAsyncThunk<
  OpenWorkOrder[],
  GetOpenWorkOrdersRequest,
  AsyncThunkConfig
>(ActionType.GET_OPEN_WORK_ORDERS, async (payload, { extra }) => {
  const { widgetApi } = extra;

  return widgetApi.getOpenWorkOrders(payload);
});

const getAllCarsByCarServiceId = createAsyncThunk<
  CountResponse,
  number,
  AsyncThunkConfig
>(ActionType.GET_ALL_CARS_BY_CAR_SERVICE_ID, async (payload, { extra }) => {
  const { widgetApi } = extra;

  return widgetApi.getAllCarsByCarServiceId(payload);

});

const getAllRequestsByCarServiceId = createAsyncThunk<
  CountResponse,
  number,
  AsyncThunkConfig
>(ActionType.GET_ALL_REQUESTS_BY_CAR_SERVICE_ID, async (payload, { extra }) => {
  const { widgetApi } = extra;

  return widgetApi.getAllRequestsByCarServiceId(payload);

});

const getNotCompletedTasksByCarServiceId = createAsyncThunk<
  CountResponse,
  number,
  AsyncThunkConfig
>(ActionType.GET_NOT_COMPLETED_TASKS_BY_CAR_SERVICE_ID, async (payload, { extra }) => {
  const { widgetApi } = extra;

  return widgetApi.getNotCompletedTasksByCarServiceId(payload);

});

export {
  getAllCarsByCarServiceId,
  getAllRequestsByCarServiceId,
  getNotCompletedTasksByCarServiceId,
  getNotCompletedTasksByUserId,
  getOpenRequests,
  getOpenWorkOrders,
  getWidgetsData,
};
