import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { useAppSelector, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { TariffPanel } from './tariff-panel';
import { getTariffsConfig } from './tariffs-config';

const Tariffs: FC = () => {
  const { t } = useTranslation(AppTranslationKey.TARIFFS);
  const [isDisable, setIsDisable] = useState(false);

  const {
    tariffs,
    currentTariff,
    nextTariff,
    tariffsData,
  } = useAppSelector(({ dictionary, user }) => ({
    tariffs: dictionary.data?.tariffs || [],
    currentTariff: user.user?.tariff,
    nextTariff: user.user?.tariffNext,
    tariffsData: user.user?.tariffsData || [],
  }));

  return (
    <CCard>
      <CCardHeader>
        <h5 className="text-dark">{ t('title') }</h5>
      </CCardHeader>
      <CCardBody className={ styles.tariffsContainer }>
        {
          getTariffsConfig(tariffs, tariffsData).map((tariff) => {
            return tariff.isRender
              ? (
                <TariffPanel
                  key={ `tariff_${ tariff.id }` }
                  disabled={ isDisable }
                  setIsDisable={ setIsDisable }
                  currentTariff={ currentTariff }
                  nextTariff={ nextTariff }
                  { ...tariff }
                />
              )
              : null;
          })
        }
      </CCardBody>
    </CCard>
  );
};

export { Tariffs };
