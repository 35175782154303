import { CCol, CFormFeedback, CFormLabel, CRow } from '@coreui/react-pro';
import cn from 'classnames';
import { SelectComponentKey } from 'common/enums/enums';
import { AppTranslationKey } from 'common/enums/translation-key/app-translation-key.enum';
import { Brand, CarModalFormData, Model, ModelForm } from 'common/types/types';
import { SelectWithLocalSearch } from 'components/common/select';
import { useEffect, useState } from 'hooks/hooks';
import React, { FC, ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control<CarModalFormData>,
  models: Model[],
  brand: Brand | null,
  disabled? :boolean,
};

const ModelField: FC<Props> = ({
  disabled = false, control, models, brand,
}) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);
  const { t: selectT } = useTranslation(AppTranslationKey.SELECT_COMPONENT);
  const [isShowErrorBrand, setIsShowErrorBrand] = useState<boolean>(false);

  const onFocus = (): void => {
    setIsShowErrorBrand(!brand);
  };

  useEffect(() => {
    if (isShowErrorBrand && !!brand) {
      setIsShowErrorBrand(!brand);
    }
  }, [isShowErrorBrand, brand]);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="model" className="col-sm-3 col-form-label">
        {t('model')}
      </CFormLabel>
      <CCol sm={9}>
        <Controller
          name="model"
          render={({ field, formState: { errors } }): ReactElement => (
            <div>
              <SelectWithLocalSearch<ModelForm>
                id="model"
                data={ models }
                value={ field.value }
                onChange={ (value): void => field.onChange(value) }
                getOptionLabel={ (option): string => option.name }
                getOptionValue={ (option): string => option.id + '' }
                otherOption={ { id: -1, name: selectT(SelectComponentKey.OTHER_OPTION_NAME) } }
                className={ cn( { 'is-invalid': Boolean(errors.model) || isShowErrorBrand }) }
                isError={ Boolean(errors.model) || isShowErrorBrand }
                isDisabled={ disabled }
                menuIsOpen={ isShowErrorBrand ? false : undefined }
                onFocus={ onFocus }
              />
              <CFormFeedback invalid={ Boolean(errors.model) || isShowErrorBrand }>
                { isShowErrorBrand ? t('errorSelectBrandMessage') : (errors.model?.message || '')}
              </CFormFeedback>
            </div>
          )}
          control={control}
          // rules={ {
          //   required: t('requiredFormField') ?? 'This field is required',
          // } }
        />
      </CCol>
    </CRow>
  );
};

export { ModelField };
