import { EmployeeTableRow } from 'common/types/types';
import { PortalWrapper } from 'components/common/common';
import { FC } from 'react';
import { Tooltip } from 'react-tooltip';

import styles from './style.module.scss';

const InfoCell: FC<EmployeeTableRow> = ({ id, info }) => {
  const tooltipId = `${id}_info`;

  if (!info) {
    return <td>-</td>;
  }

  return (
    <td className={ styles.infoCell }>
      <div className={styles.content} data-tooltip-id={tooltipId}>
        {info}
      </div>
      <PortalWrapper>
        <Tooltip
          id={tooltipId}
          content={info}
          place="right"
          className={ styles.tooltip }
        />
      </PortalWrapper>
    </td>
  );
};

export { InfoCell };
