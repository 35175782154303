import { OrderModalFormData } from 'common/types/types';
import { currentDateTimeWithZeroMinutes } from 'helpers/helpers';
import { i18next } from 'i18n';

const DEFAULT_ORDER_MODAL_PAYLOAD: OrderModalFormData = {
  autoId: -1,
  autoName: i18next.t('orderModal:noSelectCar'),
  carReceiveDate: currentDateTimeWithZeroMinutes(),
  carReceiveTime: currentDateTimeWithZeroMinutes(),
  problemDescription: 'def problem',
  requestStatusId: 1,
  comment: '',
  employeeId: 0,
  duties: '',
};

export { DEFAULT_ORDER_MODAL_PAYLOAD };
