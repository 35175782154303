import { CarServiceContact, CarServiceProfileFormData } from 'common/types/types';

import { CONTACT_TYPE } from './contact-type.enum';

const getListContactsFromFormData = (data: CarServiceProfileFormData): CarServiceContact[] => {

  const contacts: CarServiceContact[] = [];
  data.contactEmail?.contactData && contacts.push({
    id: data.contactEmail.id ?? null,
    carServiceId: data.id,
    contactData: data.contactEmail.contactData,
    flMain: false,
    contactTypeId: CONTACT_TYPE.E_MAIL,
  });

  data.mainContactPhone?.contactData && contacts.push({
    id: data.mainContactPhone.id ?? null,
    carServiceId: data.id,
    contactData: data.mainContactPhone.contactData,
    flMain: true,
    contactTypeId: CONTACT_TYPE.PHONE,
  });

  data.contactPhone?.contactData && contacts.push({
    id: data.contactPhone.id ?? null,
    carServiceId: data.id,
    contactData: data.contactPhone.contactData,
    flMain: false,
    contactTypeId: CONTACT_TYPE.PHONE,
  });

  data.contactViber?.contactData && contacts.push({
    id: data.contactViber.id ?? null,
    carServiceId: data.id,
    contactData: data.contactViber.contactData,
    flMain: false,
    contactTypeId: CONTACT_TYPE.VIBER,
  });

  data.contactTelegram?.contactData && contacts.push({
    id: data.contactTelegram.id ?? null,
    carServiceId: data.id,
    contactData: data.contactTelegram.contactData,
    flMain: false,
    contactTypeId: CONTACT_TYPE.TELEGRAM,
  });

  return contacts;
};

export { getListContactsFromFormData };
