import {
  ApiPath,
  AuthApiPath,
  ContentType,
  ENV,
  ExceptionMessage,
  HttpCode,
  HttpHeader,
  HttpMethod,
  LocalStorageKey,
} from 'common/enums/enums';
import { HttpOptions, RefreshTokenResponseDto } from 'common/types/types';
import { HttpError } from 'exceptions/exceptions';
import { toBase64 } from 'helpers/helpers';
import { i18next } from 'i18n';
import { Storage } from 'services/storage/storage.service';

type Constructor = {
  storage: Storage;
  apiRefreshTokenPrefix: string;
};

type RequestConfig = {
  method: string,
  headers: Headers,
  body: BodyInit | null
};

type GetHeadersArgs = {
  contentType?: ContentType,
  hasAuth?: boolean,
  basicAuth?: boolean
};

class Http {
  #storage: Storage;

  readonly #apiRefreshTokenPrefix: string;

  readonly #unauthorizedExceptionMessageCode = 101;

  public constructor({ storage, apiRefreshTokenPrefix }: Constructor) {
    this.#storage = storage;
    this.#apiRefreshTokenPrefix = apiRefreshTokenPrefix;
  }

  public load<T = unknown>(
    url: string,
    options: Partial<HttpOptions> = {},
  ): Promise<T> {
    const {
      method = HttpMethod.GET,
      payload = null,
      contentType = ContentType.JSON,
      hasAuth = true,
      basicAuth = false,
      queryString,
    } = options;
    const headers = this.getHeaders({ contentType, hasAuth, basicAuth });

    return this.makeRequest(this.getUrlWithQueryString(url, queryString), {
      method,
      headers,
      body: payload,
    });
  }

  private getHeaders({ contentType, hasAuth, basicAuth }: GetHeadersArgs): Headers {
    const headers = new Headers();
    headers.append(
      HttpHeader.ACCEPT_LANGUAGE,
      Object.keys(i18next.options.resources ?? []).includes(i18next.language.slice(0, 2)) ? i18next.language : 'uk',
    );

    if (contentType) {
      headers.append(HttpHeader.CONTENT_TYPE, contentType);
    }

    if (hasAuth) {
      const token = this.#storage.getItem(LocalStorageKey.TOKEN);
      headers.append(HttpHeader.AUTHORIZATION, `Bearer ${token}`);
    }

    if (basicAuth) {
      const basicAuth = toBase64(`${ENV.BASIC_AUTH_USERNAME}:${ENV.BASIC_AUTH_PASSWORD}`);
      headers.append(HttpHeader.AUTHORIZATION, `Basic ${basicAuth}`);
    }

    return headers;
  }

  private async makeRequest<T = unknown>(
    url: string,
    config: RequestConfig,
  ): Promise<T> {

    let response = await fetch(url, config);
    const statusCode = response.status;

    if (statusCode === HttpCode.UNAUTHORIZED) {
      const parsedException = await response.json();

      if (parsedException.code !== this.#unauthorizedExceptionMessageCode) {
        const checkTokenUrl = `${ENV.API_AUTH_PATH}${ApiPath.AUTH}${AuthApiPath.CHECK_TOKEN}`;
        await this.updateToken();

        if (url.includes(checkTokenUrl)) {
          response = await fetch(this.getUrlWithQueryString(checkTokenUrl, {
            token: this.#storage.getItem(LocalStorageKey.TOKEN) ?? '',
          }), config);
        } else {
          response = await fetch(url, this.getRefreshedAuthReqConfig(config));
        }
      } else {
        throw new HttpError({
          status: response.status,
          message: parsedException.statusText ?? parsedException.message,
        });
      }
    }
    await this.checkStatus(response);

    return this.parseJSON<T>(response);
  }

  private getRefreshedAuthReqConfig(config: RequestConfig): RequestConfig {
    const token = this.#storage.getItem(LocalStorageKey.TOKEN) ?? '';
    config.headers.set(HttpHeader.AUTHORIZATION, `Bearer ${token}`);

    return config;
  }

  private getUrlWithQueryString(
    url: string,
    queryString?: Record<string, unknown>,
  ): string {
    if (!queryString) {
      return url;
    }
    const query = new URLSearchParams(
      queryString as Record<string, string>,
    ).toString();

    return `${url}?${query}`;
  }

  private async checkStatus(response: Response): Promise<void> {
    if (!response.ok) {
      const parsedException = await response.json().catch(() => ({
        message: response.statusText ?? ExceptionMessage.UNKNOWN_ERROR,
      }));

      throw new HttpError({
        status: response.status,
        message: parsedException.statusText ?? parsedException.message,
      });
    }
  }

  private async parseJSON<T>(response: Response): Promise<T> {
    return response.json();
  }

  private async updateToken(): Promise<void> {
    const refreshToken = this.#storage.getItem(LocalStorageKey.REFRESH_TOKEN);

    const response = await fetch(
      `${this.#apiRefreshTokenPrefix}${ApiPath.AUTH}${AuthApiPath.REFRESH_TOKEN}`,
      {
        method: HttpMethod.POST,
        body: JSON.stringify({ refresh_token: refreshToken }),
        headers: this.getHeaders({
          contentType: ContentType.JSON,
          hasAuth: false,
          basicAuth: true,
        }),
      },
    );

    if (!response.ok) {
      this.#storage.removeItem(LocalStorageKey.REFRESH_TOKEN);
      this.#storage.removeItem(LocalStorageKey.TOKEN);
      window.location.reload();
    } else {

      const { access_token, refresh_token }: RefreshTokenResponseDto = await response.json();
      this.#storage.setItem(LocalStorageKey.TOKEN, access_token);
      this.#storage.setItem(LocalStorageKey.REFRESH_TOKEN, refresh_token);
    }
  }
}

export { Http };
