import { CCol, CFormSelect, CRow } from '@coreui/react-pro';
import { EmployeeItem,WorkOrderFormData  } from 'common/types/types';
import { ModalLabel } from 'components/modals/components/components';
import { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

type Props = {
  label: string,
  requiredMsg: string,
  isDisable: boolean,
  register: UseFormRegister<WorkOrderFormData>,
  errors: FieldErrors<WorkOrderFormData>,
  employees: EmployeeItem[],
};

const WorkOrderEmployeesRow: FC<Props> = ({
  label,
  requiredMsg,
  isDisable,
  register,
  errors,
  employees,
}) => {
  return (
    <CRow className="mb-3">
      <ModalLabel label={`${label}*`} />
      <CCol sm={10}>
        <CFormSelect
          { ...register('employeeId', { required: requiredMsg }) }
          disabled={ isDisable }
          invalid={Boolean(errors.employeeId)}
          feedbackInvalid={errors.employeeId?.message}
        >
          {
            employees.map(({ employeeId, firstName, secondName, patronymicName }) => (
              <option key={ employeeId } value={ employeeId }>
                { `${ secondName || '' } ${ firstName || '' } ${ patronymicName || '' }`.replace(/^\s+|\s+$/g, '') }
              </option>
            ))
          }
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export { WorkOrderEmployeesRow };
