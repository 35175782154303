import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { GetConfidentialTextResponse } from 'common/types/types';

import { getConfidentialText } from './actions';

type State = {
  confidentialText: GetConfidentialTextResponse,
  confidentialTextDataStatus: DataStatus,
};

const initialState: State = {
  confidentialText: {
    title: '',
    text: '',
    textId: 0,
  },
  confidentialTextDataStatus: DataStatus.IDLE,
};

const confidentialTextSlice = createSlice({
  name: 'confidentialText',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get confidential text
      .addCase(getConfidentialText.pending, (state) => {
        state.confidentialTextDataStatus = DataStatus.PENDING;
      })
      .addCase(getConfidentialText.rejected, (state) => {
        state.confidentialTextDataStatus = DataStatus.REJECTED;
      })
      .addCase(getConfidentialText.fulfilled, (state, { payload }) => {
        state.confidentialTextDataStatus = DataStatus.FULFILLED;
        state.confidentialText = payload;
      });
  },
});

const confidentialTextReducer = confidentialTextSlice.reducer;

export { confidentialTextReducer };
