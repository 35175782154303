import { cilAt } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro';
import { AppTranslationKey, RecoveryPasswordTranslationKey } from 'common/enums/enums';
import { RecoveryPasswordRequest } from 'common/types/types';
import { DEFAULT_REQUEST_RECOVERY_FORM_PAYLOAD } from 'components/auth/common/common';
import { useNavigate } from 'hooks/hooks';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit: (payload: RecoveryPasswordRequest) => void;
  isLoading: boolean;
};
const RecoveryPasswordForm: FC<Props> = ({ onSubmit, isLoading }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  }
    = useForm<RecoveryPasswordRequest>({
      defaultValues: DEFAULT_REQUEST_RECOVERY_FORM_PAYLOAD,
    });
  const navigate = useNavigate();
  const { t } = useTranslation(AppTranslationKey.RECOVERY_PASSWORD);

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <p style={{ fontSize: 16, paddingBottom: 5 }}>
        {t(RecoveryPasswordTranslationKey.SUBTITLE_RECOVERY_PASSWORD)}
      </p>
      <CInputGroup className="mb-3">
        <CInputGroupText>
          <CIcon icon={cilAt}/>
        </CInputGroupText>
        <CFormInput
          {...register('email',
            {
              required:
                t(RecoveryPasswordTranslationKey.EMAIL_ERROR_TEXT) ??
                'Please enter a valid email address',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message:
                  t(RecoveryPasswordTranslationKey.EMAIL_ERROR_TEXT) ??
                  'Please enter a valid email address',
              },
            })}
          name="email"
          type="text"
          placeholder={t(RecoveryPasswordTranslationKey.PLACEHOLDER_RECOVERY_PASSWORD) ?? 'E-mail'}
          invalid={Boolean(errors.email)}
          feedbackInvalid={errors.email?.message}
        />
      </CInputGroup>
      <CRow>
        <CCol className="text-center">
          <CLoadingButton
            color="primary"
            className="px-4 mt-3"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            {t(RecoveryPasswordTranslationKey.RECOVERY_PASS_BUTTON_TEXT)}
          </CLoadingButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol className="text-center">
          <CButton
            color="link"
            className="px-4 mt-3"
            onClick={(): void => navigate(-1)}
          >
            {t(RecoveryPasswordTranslationKey.BACK_BUTTON_TEXT)}
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

export { RecoveryPasswordForm };
