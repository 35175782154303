import { ListCarsModalForm } from 'components/modals/modals';
import { useCarModal, useState } from 'hooks/hooks';
import React, { ReactElement } from 'react';

type UseListCarsModal = {
  getListCarsModal: (payload: Props) => ReactElement,
  toggleListCarsModal: (isVisible: boolean) => void,
};

type Props = {
  activeCarServiceId: number,
};

const useListCarsModal = (): UseListCarsModal => {
  const [ isListCarsModalOpen, setIsListCarsModalOpen ] = useState<boolean>(false);
  const { getCarModal, toggleCarModal } = useCarModal();

  const toggleListCarsModal = ( isVisible: boolean ): void => {
    setIsListCarsModalOpen(isVisible);
  };

  const getListCarsModal = ({ activeCarServiceId }: Props): ReactElement => {

    return <><ListCarsModalForm
      isOpen={ isListCarsModalOpen }
      onClose={ (): void => toggleListCarsModal(false) }
      carServiceId={ activeCarServiceId }
      onOpenAddCarModal={ (): void => toggleCarModal(true) }
    />
    { getCarModal({ activeCarServiceId: activeCarServiceId ?? 0 }) }
    </>;
  };

  return { getListCarsModal, toggleListCarsModal };
};

export { useListCarsModal };
