import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeFormData } from 'common/types/types';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<EmployeeFormData>,
  isDisable: boolean,
};

const PatronymicField: FC<Props> = ({ register, isDisable }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="patronymic" className="col-sm-3 col-form-label">
        { `${ t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.PATRONYMIC_NAME }`) }` }
      </CFormLabel>
      <CCol sm={ 9 }>
        <CFormInput { ...register('patronymicName') } id="patronymic" disabled={ isDisable } />
      </CCol>
    </CRow>
  );
};

export { PatronymicField };
