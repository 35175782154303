import 'simplebar/dist/simplebar.min.css';

import { CImage, CNavLink, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react-pro';
import { LocalStorageKey } from 'common/enums/enums';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { storage } from 'services/services';
import SimpleBar from 'simplebar-react';
import LogoMini from 'static/logo.svg';
import Logo from 'static/logo-nav-big.jpg';
import { IRootState } from 'store/root-reducer';

import { config } from './navigation-config';
import { SidebarNav } from './sidebar-nav';

const Sidebar: FC = () => {
  const isOpenLeftMenu = useSelector<IRootState, boolean>((state) => state.app.isOpenLeftMenu);
  const [unfoldable, setUnfoldable] = useState(storage.getBoolean(LocalStorageKey.UNFOLDABLE_LEFT_MENU, false));
  const onUnfoldable = ():void => {
    const newValue = !unfoldable;

    setUnfoldable(newValue);
    storage.setItem(LocalStorageKey.UNFOLDABLE_LEFT_MENU, newValue ? 'true' : 'false');
  };

  return (
    <CSidebar
      position="fixed"
      visible={isOpenLeftMenu}
      unfoldable={unfoldable}
    >

      <CSidebarBrand className="d-none d-md-flex">
        <CNavLink href="https://ukrcarservice.com" target="_blank">
          <CImage className="sidebar-brand-full" src={Logo} width={256} height={62} />
          <CImage className="sidebar-brand-narrow" src={LogoMini} height={35} />
          {/*<span className="sidebar-brand-full ml10">UkrCarService</span>*/}
        </CNavLink>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar style={{ overflow: 'hidden' }}>
          <SidebarNav items={config} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={ onUnfoldable }
      />
    </CSidebar>
  );
};

export { Sidebar };
