import { SetPasswordResponseCode } from 'common/enums/enums';
import { MessageStatus } from 'components/auth/common/enums/enums';

const codeToMessageStatus: Record<SetPasswordResponseCode, MessageStatus> = {
  [SetPasswordResponseCode.SUCCESS]: MessageStatus.SUCCESS,
  [SetPasswordResponseCode.FAIL]: MessageStatus.ERROR,
  [SetPasswordResponseCode.ERROR]: MessageStatus.ERROR,
};

export { codeToMessageStatus };
