import { CButton } from '@coreui/react-pro';
import { useExtraButtonContext } from 'contexts';
import { t as tt } from 'i18next';
import React, { FC } from 'react';

const ExtraButton: FC = () => {
  const { extraButton: { isHide, translateTitleKey, action } } = useExtraButtonContext();

  if (isHide) {
    return null;
  }

  return (
    <CButton
      type="button"
      color="primary"
      onClick={ ():void => action() }
    >
      { tt(translateTitleKey) }
    </CButton>
  );
};

export { ExtraButton };
