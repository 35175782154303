import {
  CCard,
  CCardBody,
  CCardHeader,
} from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { useAppDispatch,useAppSelector, useEffect } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserById } from '../../store/user/actions';
import styles from './styles.module.scss';
import { UserInfoBlock } from './user-info-block';
import { UserTariffBlock } from './user-tariff-block';

const UserCard: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.PROFILE);
  const user = useAppSelector((state) => state.auth.currentUser);
  const {
    id = 0,
    name = '',
    username = '',
    registrationDateTime,
    tariff,
    tariffNext,
    phone = '',
  } = (user || {});

  useEffect(() => {
    dispatch(getUserById(id));
  }, [dispatch, id]);

  return (
    <CCard className="mb-4">
      <CCardHeader>{ t('title') }</CCardHeader>
      <CCardBody>
        <div className={ styles.profileContainer }>
          <UserInfoBlock
            name={ name }
            username={ username }
            userId={ id }
            phone={ phone }
            registrationDateTime={ registrationDateTime }
          />
          <UserTariffBlock tariff={ tariff } tariffNext={ tariffNext } />
        </div>
      </CCardBody>
    </CCard>
  );
};

export { UserCard };
