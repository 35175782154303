import { TableColumn } from 'common/types/types';
import { i18next } from 'i18n';

const columns: TableColumn[] = [
  {
    key: 'numberTourOfDuty',
    label: i18next.t('orderModal:numberTourOfDuty'),
    _style: { width: '20%' },
    filter: false,
    sorter: false,
  },
  {
    key: 'performer',
    label: i18next.t('orderModal:performer'),
    _style: { width: '40%' },
    filter: false,
    sorter: false,
  },
  {
    key: 'status',
    label: i18next.t('orderModal:status'),
    _style: { width: '20%' },
    filter: false,
    sorter: false,
  },
  {
    key: 'action',
    label: '',
    _style: { width: '10%' },
    filter: false,
    sorter: false,
  },
];

export { columns };
