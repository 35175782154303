import { Middleware } from '@reduxjs/toolkit';
import { ExceptionMessage, NotificationType } from 'common/enums/enums';
import { AppDispatch } from 'common/types/types';
import { appActions } from 'store/actions';

type HandleErrorParams = {
  dispatch: AppDispatch;
};

const handleError: Middleware =
  ({ dispatch }: HandleErrorParams) =>
    (next) =>
      (action): void => {
        if (action.error) {
          const { message } = action.error;

          if(
            message !== ExceptionMessage.INVALID_TOKEN &&
            message !== ExceptionMessage.DEFAULT_SERVER_ERROR &&
            message !== ExceptionMessage.INVALID_REFRESH_TOKEN
          ) {
            dispatch(appActions.notify({ type: NotificationType.ERROR, message }));
          }
        }

        return next(action);
      };

export { handleError };
