import { ApiPath, ContentType, HttpMethod, OrdersApiPath } from 'common/enums/enums';
import {
  AddWorkInOrderResponse,
  EditWorkInOrderDto,
  FiltersPayload,
  OrderDataRequestDto,
  OrderDto,
  OrderGetByIdRequestDto,
  OrdersGetByCarServiceIdRequestDto,
  RemoveWorkFromOrderDto,
  WorkDto,
} from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class OrderApi {
  #http: Http;

  readonly #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;

    this.#apiPrefix = apiPrefix;
  }

  public getOrdersByCarServiceId( { carServiceId }: OrdersGetByCarServiceIdRequestDto ): Promise<OrderDto[]> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.ORDERS}${OrdersApiPath.GET_ORDERS_BY_CAR_SERVICE_ID}/${carServiceId}`,
    );
  }

  public getOrdersByFilters( payload: FiltersPayload ): Promise<OrderDto[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.REQUESTS}${OrdersApiPath.GET_ORDERS_BY_BY_FILTERS}`,
      {
        method: HttpMethod.POST,
        payload: JSON.stringify(payload),
      },
    );
  }

  public getOrderById( { orderId, locale }:OrderGetByIdRequestDto ): Promise<OrderDto> {
    const  url = `${this.#apiPrefix}${ApiPath.ORDERS}${OrdersApiPath.ROOT}${orderId}`;

    return this.#http.load(
      locale ? url.concat(`?locale=${locale}`) : url,
    );
  }

  public updateOrder(payload: OrderDataRequestDto): Promise<OrderDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.REQUESTS}`,
      {
        method: HttpMethod.PUT,
        payload: JSON.stringify(payload),
      },
    );
  }

  public addTask(payload:OrderDataRequestDto):Promise<OrderDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.REQUESTS}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public addWorkInOrder( payload: WorkDto ): Promise<AddWorkInOrderResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.REQUESTS}${OrdersApiPath.ADD_WORK}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public removeWorkFromOrder (payload: RemoveWorkFromOrderDto): Promise<RemoveWorkFromOrderDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.REQUESTS}${OrdersApiPath.REMOVE_WORK}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public editWorkInOrder (payload: EditWorkInOrderDto): Promise<EditWorkInOrderDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.REQUESTS}${OrdersApiPath.EDIT_WORK}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

}

export { OrderApi };
